import React, { useEffect, useRef } from 'react';

import {
  InputComponent,
  InputWrapper,
  InputContainer,
  Label,
  ToolTipText,
  IconWrapper,
  CalendarWrapper
} from './components';

import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg';
import { useLocalizationContext } from 'common';

const Input = ({
  value,
  label,
  error,
  disabled,
  toolTipText,
  isCalendarInput,
  flatBottom,
  hideKeyboardOnFocus,
  isNumeric,
  isWeightInput,
  isChipNumber,
  maxLength,
  isForVet,
  ...props
}) => {
  const ref = useRef();
  const { rtl: isRtl } = useLocalizationContext();

  const blur = () => {
    if (ref.current) {
      ref.current.blur();
    }
  };

  useEffect(() => {
    if (ref.current && hideKeyboardOnFocus) {
      ref.current.addEventListener('focus', blur);
    }
    return () => ref.current?.removeEventListener('focus', blur);
  }, []);

  return <div></div>;
};

export default Input;
