import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import apolloClient from './common/apollo';
import {ApolloProvider} from '@apollo/react-hooks';
import * as serviceWorker from './serviceWorker';
import {setDefaultLanguage, setDefaultTranslations} from 'react-multi-lang';
import en from './translations/en.json';
import Modal from 'react-modal';
import './styles/styles.css';

import moment from 'moment-timezone';

setDefaultTranslations({en});
setDefaultLanguage('en');

const root = document.getElementById('DASHBOARD');

if (root) {
  Modal.setAppElement(root);
}


ReactDOM.render(
    <ApolloProvider client={apolloClient}>
      <App />
    </ApolloProvider>,
    root,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
