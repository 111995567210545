import React, { useEffect, useState } from 'react';
import { Input, Select, Radio, Upload, Image, DatePicker, Form } from 'antd';
import { PlusOutlined, DeleteTwoTone } from '@ant-design/icons';
import moment from 'moment';

import PhoneInput from 'components/phone-input/PhoneInput';
import useUpload from 'components/useUpload';

import { messages } from 'service/utils';
import { VetStatus, VetCommuteMethod } from 'constants/enums';

import './styles.css';
import LanguageSelectInput from '../language-select-input/LanguageSelectInput';
import TimezoneSelectInput from '../timezone-select-input/TimezoneSelectInput';
import RoleBasedUi from '../../role-based-ui/RoleBasedUi.js';
import { Permissions } from '../../../constants/permissions.js';

const { Option } = Select;
const { TextArea } = Input;

const VetPersonalData = ({ formik }) => {
  const { handleChange, values, setFieldValue } = formik;
  const { uploadFile } = useUpload();
  const [imageUrl, setImageUrl] = useState('');
  const { errors, touched } = formik;

  useEffect(() => {
    setImageUrl(formik.values.avatarUrl);
  }, [formik.values.avatarUrl]);

  const removePhoto = () => {
    setImageUrl('');
    setFieldValue('avatarUrl', '');
  };

  const handleDateChange = (date, dateString) => {
    setFieldValue('birthDate', dateString);
  };

  return (
    <>
      <div className="vet-data">
        <div>
          <div className="upload">
            {imageUrl ? (
              <div className="image-wrapper">
                <img src={imageUrl} alt="avatar" className="uploaded-image" />
                <DeleteTwoTone className="delete-icon" onClick={removePhoto} />
              </div>
            ) : (
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={false}
                maxCount={0}
                action={(file) => {
                  uploadFile(file).then((res) => {
                    setFieldValue('avatarUrl', res);
                    setImageUrl(res);
                  });
                }}>
                {imageUrl ? (
                  <img src={imageUrl} alt="avatar" className="avatar-image" />
                ) : (
                  <div>
                    <PlusOutlined /> <div className="upload-text">{messages.t('vet.upload')}</div>{' '}
                  </div>
                )}
              </Upload>
            )}
          </div>

          <div className="radio-wrapper">
            <p>{messages.t('vet.status')}</p>
            <Radio.Group
              onChange={handleChange}
              value={values.status}
              id="status"
              name="status"
              className="status">
              <Radio value={VetStatus.ACTIVE}>{messages.t('vet.active')}</Radio>
              <Radio value={VetStatus.INACTIVE}>{messages.t('vet.inactive')}</Radio>
              <Radio value={VetStatus.OCCUPIED}>{messages.t('vet.occupied')}</Radio>
              <RoleBasedUi permission={Permissions.SET_VET_STATUS_LIMIT_REACHED}>
                <Radio value={VetStatus.LIMIT_REACHED}>{messages.t('vet.limit_reached')}</Radio>
              </RoleBasedUi>
            </Radio.Group>
          </div>
        </div>
        <div className="inputs">
          <div className="input__fields">
            <Form.Item label={messages.t('vet.first_name')} labelCol={{ span: 24 }}>
              <Input
                placeholder={messages.t('vet.first_name')}
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                className={errors?.firstName && touched?.firstName && 'error-input'}
              />
            </Form.Item>

            <Form.Item label={messages.t('vet.email')} labelCol={{ span: 24 }}>
              <Input
                className={errors?.email && touched?.email && 'error-input'}
                placeholder={messages.t('vet.email')}
                name="email"
                value={values.email}
                onChange={handleChange}
              />
            </Form.Item>

            <Form.Item label={messages.t('vet.gender')} labelCol={{ span: 24 }}>
              <div className={errors?.gender && touched?.gender && 'error-input'}>
                <Select
                  placeholder={messages.t('vet.gender')}
                  onChange={(value) => {
                    setFieldValue('gender', value);
                  }}
                  value={values.gender}
                  id="gender">
                  <Option value="male">{messages.t('general.male')}</Option>
                  <Option value="female">{messages.t('general.female')}</Option>
                </Select>
              </div>
            </Form.Item>
            <Form.Item label={messages.t('vet.social_number')} labelCol={{ span: 24 }}>
              <Input
                placeholder={messages.t('vet.social_number')}
                name="socialNumber"
                value={values.socialNumber}
                onChange={handleChange}
                className={errors?.socialNumber && touched?.socialNumber && 'error-input'}
              />
            </Form.Item>
            <LanguageSelectInput
              values={formik.values}
              setFieldValue={formik.setFieldValue}
              isError={errors?.languageIds && touched?.languageIds}
            />
            <TimezoneSelectInput
              isError={errors?.timeZoneId && touched?.timeZoneId}
              value={formik.values?.timeZone?.uid}
              setFieldValue={formik.setFieldValue}
            />
          </div>
          <div className="input__fields">
            <Form.Item label={messages.t('vet.last_name')} labelCol={{ span: 24 }}>
              <Input
                placeholder={messages.t('vet.last_name')}
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
                className={errors?.lastName && touched?.lastName && 'error-input'}
              />
            </Form.Item>

            <Form.Item label="Phone Number:" labelCol={{ span: 24 }}>
              <PhoneInput
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                phonePrefix={values.phonePrefix}
                phoneNumber={values.phoneNumber}
                isError={errors?.phoneNumber && touched?.phoneNumber}
              />
            </Form.Item>

            <Form.Item label={messages.t('general.birth_date')} labelCol={{ span: 24 }}>
              <DatePicker
                placeholder={messages.t('general.birth_date')}
                picker="date"
                style={{ width: '100%' }}
                value={formik.values.birthDate ? moment(formik.values.birthDate) : null}
                onChange={handleDateChange}
                format={'YYYY-MM-DD'}
                className={errors?.birthDate && touched?.birthDate && 'error-input'}
              />
            </Form.Item>

            <Form.Item label={messages.t('vet.license_number')} labelCol={{ span: 24 }}>
              <Input
                placeholder={messages.t('vet.license_number')}
                name="licenseNumber"
                value={values.licenseNumber}
                onChange={handleChange}
                className={errors?.licenseNumber && touched?.licenseNumber && 'error-input'}
              />
            </Form.Item>

            <Form.Item label={messages.t('vet.main_commute_method')} labelCol={{ span: 24 }}>
              <Select
                placeholder={messages.t('vet.main_commute_method')}
                onChange={(value) => {
                  setFieldValue('mainCommuteMethod', value);
                }}
                value={values.mainCommuteMethod}
                id="mainCommuteMethod"
                name="mainCommuteMethod"
                className={
                  errors?.mainCommuteMethod && touched?.mainCommuteMethod && 'error-input'
                }>
                <Option value={VetCommuteMethod.DRIVING}>
                  {messages.t(VetCommuteMethod.DRIVING)}
                </Option>
                <Option value={VetCommuteMethod.BICYCLING}>
                  {messages.t(VetCommuteMethod.BICYCLING)}
                </Option>
                <Option value={VetCommuteMethod.WALKING}>
                  {messages.t(VetCommuteMethod.WALKING)}
                </Option>
              </Select>
            </Form.Item>

            <Form.Item label={messages.t('vet.experience')} labelCol={{ span: 24 }}>
              <Select
                placeholder={messages.t('vet.experience')}
                onChange={(value) => {
                  setFieldValue('experience', value);
                }}
                value={values.experience}
                id="experience">
                <Option value="1">1</Option>
                <Option value="2">2</Option>
                <Option value="3">3</Option>
                <Option value="4">4</Option>
                <Option value="5">5</Option>
                <Option value="6">6</Option>
                <Option value="7+">7+</Option>
              </Select>
            </Form.Item>
          </div>
        </div>
      </div>
      <div className="short-bio">
        <Form.Item label={messages.t('financials.short_biography')} labelCol={{ span: 24 }}>
          <TextArea
            placeholder={messages.t('financials.short_biography')}
            maxLength={2000}
            value={values.bio}
            autoSize={{ minRows: 5, maxRows: 5 }}
            onChange={(e) => {
              setFieldValue('bio', e.target.value);
            }}
          />
        </Form.Item>
      </div>
    </>
  );
};

export default VetPersonalData;
