import React from 'react';
import DP from 'react-datepicker';
import startCase from 'lodash/startCase';
import Selector from '../selector/Selector';
import Input from '../input/Input';

import { ReactComponent as LeftArrow } from '../../assets/icons/arrow-left-blue.svg';
import { range, months, useDateFormat } from '../../utils/libraryHelpers.js';
import { useLocalizationContext } from 'common';
import css from './Calendar.module.css';
import cls from 'classnames';

export const Calendar = ({
  value,
  label,
  theme,
  error,
  hideHeader,
  setIsCalendarOpen,
  startYear = 1900,
  endYear = 0,
  ...props
}) => {
  const years = range(startYear, new Date().getFullYear() + endYear, 'desc');
  const { dateFormat } = useDateFormat();
  const { rtl: isRtl } = useLocalizationContext();

  return (
    <div className={cls(css.wrapper, { [css.themeLight]: theme === 'light' })}>
      <DP
        {...props}
        calendarClassName={css.calendar}
        wrapperClassName={css.section}
        onCalendarOpen={() => {
          setIsCalendarOpen(true);
        }}
        onCalendarClose={() => {
          setIsCalendarOpen(false);
        }}
        customInput={<Input label={label} error={error} isCalendarInput hideKeyboardOnFocus />}
        useWeekdaysShort
        renderCustomHeader={({
          date,
          changeYear,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled
        }) => {
          return (
            <div className={css.dp_header}>
              {!hideHeader && (
                <div className={css.calendar_header_wrapper}>
                  <div className={css.calendar}>Calendar</div>
                  <div className={css.selectedDate}>{dateFormat(date)}</div>
                </div>
              )}
              <div className={css.headerBottomWrapper}>
                <div className={css.monthSelectorWrapper}>
                  <button
                    className={cls(css.arrow, { [css.arrowDisabled]: prevMonthButtonDisabled })}
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                  >
                    <LeftArrow />
                  </button>
                  <div className={css.month}>{startCase(months[new Date(date).getMonth()])}</div>
                  <button
                    className={cls(css.arrow, css.arrowRight, {
                      [css.arrowDisabled]: nextMonthButtonDisabled
                    })}
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                  >
                    <LeftArrow />
                  </button>
                </div>
                <div className={css.selectorWrapper}>
                  <Selector
                    value={{
                      label: new Date(date).getFullYear(),
                      value: new Date(date).getFullYear()
                    }}
                    options={years}
                    onChange={(item) => changeYear(item.value)}
                    hideLabel
                    noError
                    isForTimeSlot
                    isRtl={isRtl}
                  />
                </div>
              </div>
            </div>
          );
        }}
      />
    </div>
  );
};
