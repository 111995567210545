import React from 'react';
import {SearchOutlined} from '@ant-design/icons';
import {messages} from 'service/utils';
import {Steps, Input} from 'antd';

import Button from 'components/common/button/Button';
import {ReactComponent as PlusIcon} from 'assets/icons/plus-white-line.svg';
import {ReactComponent as CloseIcon} from 'assets/icons/close-gray.svg';


const ModalHeader = ({search, current, changeStep, setSearch, openNewClientModal, onRequestClose}) => {
  const {Step} = Steps;

  return (
    <>
      <div className="appointments-stepper-wrapper">
        <Steps current={current}>
          <Step
            title={messages.t('recommender.new_appointment.stepper_first_title')}
            description=''
            onClick={() => changeStep(0)}
          />
          <Step
            title={messages.t('recommender.new_appointment.stepper_second_title')}
            description=''
            onClick={() => changeStep(0)}
          />
          <Step
            title={messages.t('recommender.new_appointment.stepper_third_title')}
            subTitle=''
            description=''
            onClick={() => changeStep(1)}
          />
        </Steps>
        <CloseIcon onClick={onRequestClose}/>
      </div>
      <div className='appointments-header-wrapper'>
        {current === 0 && (
          <>
            <Input
              placeholder={messages.t('recommender.new_appointment.search_placeholder')}
              prefix={<SearchOutlined />}
              className='search-input'
              size='small'
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <Button
              label={messages.t('recommender.new_appointment.new_client')}
              icon={<PlusIcon />}
              className='appointment-btn'
              onClick={openNewClientModal}
            />
          </>
        )}

      </div>
    </>
  );
};

export default ModalHeader;
