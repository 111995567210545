import React, {useState} from 'react';
import {useHistory} from 'react-router';
import {useMutation} from '@apollo/react-hooks';
import {Popconfirm, Popover, Modal} from 'antd';
import {
  CalendarTwoTone,
  EditTwoTone,
  DeleteTwoTone,
  CopyTwoTone,
  CopyFilled,
  EyeTwoTone,
} from '@ant-design/icons';

import {ADD_VET, DASHBOARD, USERS} from 'constants/routeNames';
import {messages} from 'service/utils';

import {excludeTimeSlotMutation, createTimeSlotsMutation} from 'gql';

import VetsCalendar from './VetsCalendar';
import useRemoveVet from './useRemoveVet';

import './styles.css';
import RoleBasedUi from '../../../components/role-based-ui/RoleBasedUi.js';
import {Permissions} from '../../../constants/permissions.js';
import useActAsUser from '../../../components/customers/useActAsUser.js';
import RoleBasedCondition from '../../../components/role-based-ui/RoleBasedCondition.js';

const useColumns = (refetchVets) => {
  const history = useHistory();
  const {handleDeleteVet} = useRemoveVet(refetchVets);
  const [copiedId, setCopiedId] = useState(null);
  const [isCalendarModalOpen, setIsCalendarModalOpen] = useState(false);

  const [excludeTimeSlot] = useMutation(excludeTimeSlotMutation);
  const [createTimeSlot] = useMutation(createTimeSlotsMutation);

  const handleDelete = (uid) => {
    handleDeleteVet(uid);
  };

  const handleEdit = (uid) => {
    history.push(`${DASHBOARD}${USERS}${ADD_VET}/${uid}`);
  };

  const {handleActAsUser} = useActAsUser();

  const handleSaveTimeSlot = async (
      newTimeSlot,
      timeSlotId,
      selectedDate,
      vetId,
  ) => {
    try {
      await excludeTimeSlot({
        variables: {
          record: {
            timeSlot: newTimeSlot,
            date: selectedDate,
            timeSlotId,
            vetId,
          },
        },
      });
      refetchVets();
      setIsCalendarModalOpen(false);
    } catch (err) {
      console.error(err);
    }
  };

  const handleCreateNewTimeSlot = async (timeSlot, vetId) => {
    try {
      await createTimeSlot({
        variables: {
          record: {
            timeSlots: [timeSlot],
            vetId,
          },
        },
      });
      refetchVets();
      setIsCalendarModalOpen(false);
    } catch (e) {
      throw Error('Invalid credentials');
    }
  };

  const actAsHandler = (userId) => {
    handleActAsUser(userId);
  };

  return [
    {
      title: messages.t('users.id'),
      dataIndex: 'userID',
      key: 'userID',
      visible: RoleBasedCondition(Permissions.VIEW_USER_ID),
      render: (text, record) => (
        <div
          className="userID"
          onClick={() => {
            navigator.clipboard.writeText(record.userID);
            setCopiedId(record.userID);
          }}
        >
          {copiedId === record.userID ? <CopyTwoTone /> : <CopyFilled />}
        </div>
      ),
    },
    {
      title: messages.t('users.registration_date'),
      dataIndex: 'registrationDate',
      key: 'registrationDate',
    },
    {
      title: messages.t('users.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: messages.t('forms.email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: messages.t('users.phone'),
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: messages.t('users.country'),
      dataIndex: 'country',
      key: 'country',
    },
    {
      title: messages.t('users.customers'),
      dataIndex: 'activeCustomers',
      key: 'activeCustomers',
      align: 'center',
    },
    {
      title: messages.t('status'),
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: messages.t('general.actions'),
      key: 'action',
      dataIndex: 'action',
      render: (text, record) => (
        <div className="actions">
          <div className="actionButtons">
            <EyeTwoTone
              onClick={() => {
                actAsHandler(record.vetUserId);
              }}
            />
          </div>
          <RoleBasedUi permission={Permissions.EDIT_VET}>
            <div className="actionButtons">
              <EditTwoTone
                className="fontSize-17"
                onClick={() => {
                  handleEdit(record.userID);
                }}
              />
            </div>
          </RoleBasedUi>
          <RoleBasedUi permission={Permissions.DELETE_VET}>
            <div className="actionButtons">
              <Popconfirm
                title={messages.t('general.confirm_delete')}
                onConfirm={() => {
                  handleDelete(record.userID);
                }}
                okText={messages.t('general.yes')}
                cancelText={messages.t('general.no')}
                placement="topRight"
              >
                <DeleteTwoTone className="fontSize-17" />
              </Popconfirm>
            </div>
          </RoleBasedUi>
        </div>
      ),
    },
  ].filter((item) => item?.visible !== false);
};
export default useColumns;
