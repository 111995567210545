import { useQuery } from '@apollo/client';
import { getVetByUidQuery, getTimeSlotsByVetUidQuery } from 'gql';

const useGetVet = (uid) => {
  const { data, loading } = useQuery(getVetByUidQuery, {
    skip: !uid,
    fetchPolicy: 'no-cache',
    variables: {
      record: {
        uid: uid
      }
    }
  });
  const timeSlotsData = useQuery(getTimeSlotsByVetUidQuery, {
    skip: !uid,
    fetchPolicy: 'no-cache',
    variables: {
      record: {
        uid: uid
      }
    }
  });

  return {
    getVetByUidData: data?.getVetByUid,
    timeSlots: timeSlotsData?.data?.getTimeSlotsByVetUid,
    loading
  };
};

export default useGetVet;
