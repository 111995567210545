import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { LOGIN } from 'constants/routeNames';

const ProtectedRoute = ({ component: Component, auth, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return auth?.me && auth?.hasToken ? (
          <Component {...props} />
        ) : (
          <Redirect to={LOGIN} />
        );
      }}
    />
  );
};

export default ProtectedRoute;
