import React from 'react';
import { Btn } from './components';

const Button = ({
  label,
  children,
  submitHandler,
  disabled,
  isLaptop,
  isForModal,
  icon,
  ...props
}) => {
  return (
    <Btn
      onClick={submitHandler}
      aria-label={label || children}
      {...props}
      disabled={disabled}
      isLaptop={isLaptop}
      isForModal={isForModal}
    >
      {icon && icon}
      {label || children}
    </Btn>
  );
};

export default Button;
