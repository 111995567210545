import css from './Dropdown.module.css';
import React from 'react';
import cls from 'classnames';
import { Dropdown as DropdownAntd } from 'antd';

const Dropdown = ({ className, children, ...props }) => (
  <DropdownAntd className={cls(css.dropdown, className)} {...props}>
    {children}
  </DropdownAntd>
);

export default Dropdown;
