import { useQuery } from '@apollo/client';
import { getVetAdminsQuery } from 'gql';

const useVetAdmins = () => {
  const { data, loading } = useQuery(getVetAdminsQuery, {
    fetchPolicy: 'no-cache',
    variables: {
      search: ''
    }
  });

  const vetAdminsData = data?.vetAdmins.map((vet) => {
    return {
      id: vet.uid,
      name: `${vet.user.firstName} ${vet.user.lastName}`,
      userId: vet.user.uid
    };
  });

  return { vetAdminsData, vetAdmins: data?.vetAdmins || [], loading };
};

export default useVetAdmins;
