import React, { useEffect, useState } from 'react';
import { Modal, Button, Card, Descriptions, Collapse, Tag } from 'antd';
import './styles.css';
import { messages, parseTimeSlotHour} from 'service/utils';
import SingleAppointmentDetails from './SingleAppointmentDetails';



const { Panel } = Collapse;

const EventDetailsModal = ({
  isModalOpen,
  setIsModalOpen,
  event,
  forceCloseModal,
}) => {

  const [title, setTitle] = useState("Details");
  const [isTimeslotEventType, setIsTimeslotEventType] = useState(false);

  const handleOk = () => {
    setIsModalOpen();
  };


  useEffect(() => {
      setIsTimeslotEventType(()=> {
      if (event != false){
        return event?.eventType == "TimeslotEvent" ? true : false;
      }
    });
    // getTitle();
  }, [event])


  useEffect(() => {
    isTimeslotEventType ? setTitle(messages.t('schedule.schedule_modal.title_timeslot'))
                        : setTitle(messages.t('schedule.schedule_modal.title_timeslot'));

  },
  [isTimeslotEventType])

  // const getTitle = () => {
  //   isTimeslotEventType ? setTitle(messages.t('schedule.schedule_modal.title_timeslot'))
  //                       : setTitle(messages.t('schedule.schedule_modal.title_timeslot'));
  // }

  return (
    <Modal
      title={title}
      visible={isModalOpen}
      onOk={handleOk}
      onCancel={handleOk}
      destroyOnClose
      width={'45%'}
      footer={[
        <Button key="ok" onClick={handleOk} type="primary">
          OK
        </Button>
      ]}
    >
      {/* { isTimeslotEventType && */
      <div>
          <Descriptions
                  className='timeslot-details'
                  column={1}
                  labelStyle={{fontWeight:600}}>
                  { (event?.timeSlot?.isFull || event?.timeSlot?.isLocked) && <Descriptions.Item label=""> {event?.timeSlot?.isFull && <Tag color="red">Full</Tag>}   {event?.timeSlot?.isLocked &&<Tag color="yellow">Locked</Tag>}</Descriptions.Item>}
                  <Descriptions.Item label={messages.t('schedule.schedule_modal.timeslot_details.timeslot_name_lable')}>{event.timeSlot?.name}</Descriptions.Item>
                  <Descriptions.Item label={messages.t('schedule.schedule_modal.timeslot_details.location_lable')}>{(event?.timeSlot?.workingAreas[0]?.polygon?.name || "")}</Descriptions.Item>
                  <Descriptions.Item label={messages.t('schedule.schedule_modal.timeslot_details.date_lable')}>{(new Date(event.startTime)).toLocaleDateString()}</Descriptions.Item>
                  <Descriptions.Item label={messages.t('schedule.schedule_modal.timeslot_details.hour_lable')}>{`${parseTimeSlotHour(event?.timeSlot?.startTime)} - ${parseTimeSlotHour(event?.timeSlot?.endTime)}`}</Descriptions.Item>
                  <Descriptions.Item label={messages.t('schedule.schedule_modal.timeslot_details.caregiver_lable')}>{`${event.timeSlot?.vet?.user?.firstName} ${event.timeSlot?.vet?.user?.lastName}`}</Descriptions.Item>
          </Descriptions>

          {}
          <div className='appointments-title'>
          { isTimeslotEventType && <p><h3>{messages.t('schedule.schedule_modal.timeslot_details.title_appointments_list')} {(event?.appointments?.length > 0) && ` (${event?.appointments?.length} households)`}</h3></p>}
          { !isTimeslotEventType && <p><h3>{messages.t('schedule.schedule_modal.title_appointment')} {(event?.appointments?.length > 0)}</h3></p>}
          </div>
          {(event?.appointments?.length > 0) && event?.appointments?.map(appointment =>{ return (
          <SingleAppointmentDetails
            appointment={appointment}
            event={event}
            forceCloseModal={forceCloseModal}
            isTimeslotEventType={isTimeslotEventType}
            />)})}
          {(event?.appointments?.length == 0) &&
            <div className='no-appointments'>
                <p><h4>{messages.t('schedule.schedule_modal.timeslot_details.appointment_list.no_appointments')}</h4></p>
            </div>}

      </div> }
      {/* { !isTimeslotEventType &&
        <SingleAppointmentDetails
            appointment={event?.appointments ? event?.appointments[0] : []}
            event={event}
            isTimeslotEventType={isTimeslotEventType}
        />
      } */}
    </Modal>
  );
};

export default EventDetailsModal;


