import React from 'react';
import cls from 'classnames';
import css from './FormItem.module.css';
import text from '../../styles/text.module.css';
import Label from '../Label/Label';

const FormItem = ({ className, label, error, labelPosition, children, ...props }) => (
  <div className={cls(css.container, className)} {...props}>
    {label && !labelPosition && <Label className={css.topLeft}>{label}</Label>}
    {children}
    <span className={cls(css.bottom)}>
      {label && labelPosition === 'bottomRight' && (
        <span className={cls(text.caption, css.bottomRight)}>{label}</span>
      )}
      {error && <span className={cls(text.caption, css.error)}>{error}</span>}
    </span>
  </div>
);

export default FormItem;
