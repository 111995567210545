import React, {useCallback} from 'react';
import CalendarSteps from '../calendar/steps/CalendarSteps';
import MediaQueriesList from 'common/utils/mediaQueriesList';
import Modal from 'components/common/modal/Modal';
import {message} from 'antd';
import {messages} from 'service/utils';
import './style.scss';
import {useMediaQuery} from 'react-responsive';

const NewClientModal = ({onRequestClose, vet, allVets, sendRequestCode, checkExistingUser, selectedWorkingArea, goBack}) => {
  const {isMobile} = MediaQueriesList();

  const onFinishNewClient = useCallback((timeslotId, date) => {
    onRequestClose();
    message.success(messages.t('recommender.new_appointment.success_message'));
  }, []);
  const [view, onChangeView] = React.useState(true);
  const mxw699 = useMediaQuery({maxWidth: 699});
  const mnw700 = useMediaQuery({minWidth: 700});

  return (
    <>
      <Modal
        isOpen={true}
        autoHeight={isMobile}
        isLaptop={!isMobile}
        isMobile={isMobile}
        onRequestClose={onRequestClose}
        custom={view ? {
          maxWidth: mxw699 ? 380 : 'unset',
          width: mxw699 ? 'unset' : 640,
          minWidth: mnw700 ? 500 : 300,
        } : {
          width: mnw700 ? '90%' : 'unset',
          maxWidth: mxw699 ? 380 : 'unset',
        }}>
        <CalendarSteps
          vet={vet}
          allVets={allVets}
          isForVet={true}
          sendRequestCode={sendRequestCode}
          checkExistingUser={checkExistingUser}
          onFinishNewClient={onFinishNewClient}
          selectedWorkingArea={selectedWorkingArea}
          onRequestClose={onRequestClose}
          goBack={goBack}
          onChangeView={onChangeView}
        />
      </Modal>
    </>
  );
};

export default NewClientModal;
