import React, {memo, useState} from 'react';
import {Form} from 'antd';
import Modal from 'components/common/modal/Modal';
import EditClientAddressModal from '../edit-client-address-modal/EditClientAddressModal';
import mediaQueriesList from 'common/utils/mediaQueriesList';
import ModalContent from './components/ModalContent';
import ModalHeader from './components/ModalHeader';
import ModalFooter from './components/ModalFooter';
import useCalendarSteps from '../calendar/steps/useCalendarSteps';
import useNewAppointmentModal from './useNewAppointmentModal';
import {processAddress, serializeSignUpFlow} from './serializers';
import {PropertyType} from 'constants/enums';

import './style.scss';
import {serializeAddressDescription} from '../../../serializers/signUpFlow.serializer.js';
import PetSection from '../../_new/PetSection/PetSection';
import {RescheduleWrapper} from '../../_new/Reschedule/index.js';
import {useFilters} from '../../_new/Reschedule/useFilters.js';
import {useSelectedTimeslot} from '../../_new/Reschedule/useSelectedTimeslot.js';
import {useMediaQuery} from 'react-responsive';

const NewAppointmentModal = ({
  vet,
  allVets,
  currentStep,
  setCurrentStep,
  changeStep,
  onRequestClose,
  openNewClientModal,
  getAppointmentsByTimeslotUid,
}) => {
  const {isMobile} = mediaQueriesList();

  const [form] = Form.useForm();
  const [selectedClient, setSelectedClient] = useState(null);
  const {
    addressError,
    address,
    setAddress,
    selectedTimeslot,
    setSelectedTimeslot,
    propertyType,
  } = useCalendarSteps(vet, selectedClient, true, null, null, form, true);

  const {
    search,
    setSearch,
    clients,
    loading,
    refetchClients,
    clientToEdit,
    setClientToEdit,
    petList,
    setPetList,
    onClientSelect,
    onClientEdit,
    isNextButtonDisabled,
    onNextClick,
    handleSignUp,
    fetchPetOwners,
    total,
    isSubmitting,
    petsToCreate,
    setPetsToCreate,
  } = useNewAppointmentModal(
      currentStep,
      setCurrentStep,
      3,
      onRequestClose,
      setAddress,
      addressError,
      selectedTimeslot,
      selectedClient,
      setSelectedClient,
      propertyType === PropertyType.PrivateHome,
      form,
      getAppointmentsByTimeslotUid,
  );
  const [view, onChangeView] = React.useState(true);

  const [informClient, setInformClient] = useState(false);

  const filters = useFilters({vetId: vet?.uid});
  const timeslotActions = useSelectedTimeslot({handleTimeslot: setSelectedTimeslot});

  const steps = [
    {
      content: (
        <ModalContent
          total={total}
          search={search}
          clients={clients}
          loading={loading}
          onClientSelect={onClientSelect}
          fetchPetOwners={fetchPetOwners}
          selectedClient={selectedClient}
          onClientEdit={onClientEdit}
        />
      ),
    },
    {
      content: (
        <RescheduleWrapper
          onChangeView={onChangeView}
          theme="light"
          filters={filters}
          timeslotActions={timeslotActions}
          currentAddress={processAddress(selectedClient?.user?.address)}
          vetId={vet?.uid}
          displayFooter={false}
          displayHeader={false}
          close={() => {}}
        />
      ),
    },
    {
      content: (
        <Form
          form={form}
          layout='vertical'
          disabled={isSubmitting}
          initialValues={{
            pets: [],
          }}
          onFinish={(values) =>
            handleSignUp(serializeSignUpFlow(
                values,
                selectedClient?.user,
                address,
                petList,
                selectedTimeslot?.date,
                selectedTimeslot?.vet,
                selectedTimeslot,
                informClient,
                petsToCreate,
            ))}
        >
          <PetSection
            setPetsToCreate={setPetsToCreate}
            client={selectedClient?.user}
            petList={petList}
            setPetList={setPetList}
            vet={selectedTimeslot?.vet} // TODO: get vet from selected vet dropdown
            isVet
          />
        </Form>
      ),
    },
  ];

  const mxw699 = useMediaQuery({maxWidth: 699});
  const mnw700 = useMediaQuery({minWidth: 700});

  return (
    <>
      <Modal
        isOpen={true}
        autoHeight={isMobile}
        isLaptop={!isMobile}
        isMobile={isMobile}
        onRequestClose={onRequestClose}
        custom={view ? {
          maxWidth: mxw699 ? 380 : 'unset',
          width: mxw699 ? 'unset' : 640,
          minWidth: mnw700 ? 500 : 300,
        } : {
          width: mnw700 ? '90%' : 'unset',
          maxWidth: mxw699 ? 380 : 'unset',
        }}
      >
        <div className='new-appointment-wrapper vet-calendar-steps'>
          <ModalHeader
            search={search}
            setSearch={setSearch}
            current={currentStep}
            changeStep={changeStep}
            openNewClientModal={openNewClientModal}
            onRequestClose={onRequestClose}
          />
          {steps[currentStep].content}

          <ModalFooter
            informClient={informClient}
            setInformClient={setInformClient}
            isSubmitting={isSubmitting}
            onRequestClose={onRequestClose}
            isNextButtonDisabled={isNextButtonDisabled}
            onNextClick={onNextClick}
            changeStep={changeStep}
            current={currentStep}
            steps={steps}
          />
        </div>
      </Modal>
      {clientToEdit && (
        <EditClientAddressModal
          onRequestClose={() => setClientToEdit(null)}
          refetchClients={refetchClients}
          client={clientToEdit}
        />
      )}
    </>
  );
};

export default memo(NewAppointmentModal);
