import React from 'react';
import { useMutation } from '@apollo/client';
import { actAsUserMutation } from 'gql';

const useActAsUser = () => {
  const [actAsUser] = useMutation(actAsUserMutation);

  const handleActAsUser = async (userId) => {
    try {
      await actAsUser({
        variables: {
          record: {
            userId
          }
        }
      }).then((res) => {
        let newWindowParams = [
          'height=' + window.outerHeight,
          'width=' + window.outerWidth,
          'fullscreen=yes' // only works in IE, but here for completeness
        ].join(',');

        window.open(
          `${process.env.REACT_APP_PETS_WEB_ROOT_URL}/?userToken=${res?.data?.actAsUser}`,
          'actAs', //TODO: check how it works with userId (differnet popup or tab for each specific user)
          process.env.NODE_ENV === 'production' ? newWindowParams : null //in lowern envs open as tab
        );
      });
    } catch (e) {
      throw Error('Can not act as user');
    }
  };

  return { handleActAsUser };
};

export default useActAsUser;
