import { useEffect, useMemo, useState } from 'react';
import { Form, message } from 'antd';
import { importAppointmentsMutation } from 'gql';
import { useMutation } from '@apollo/client';
import Papa from 'papaparse';
import { serializeAppointment } from './serializers.js';
import validateAppointments from './validateAppointments.js';

import useUserConcernsByUserId from '../../components/customers/useUserConcernsByUserId.js';

const useUploadAppointments = (vets, vetAdmins, setLoading) => {
  const [form] = Form.useForm();
  const appointmentsFormValues = Form.useWatch('appointments', form);
  const integrationId = Form.useWatch('integrationId', form);

  const [selectedVetAdminId, setSelectedVetAdminId] = useState(null);
  const [selectedVetAdminUserId, setSelectedVetAdminUserId] = useState(null);

  const [errors, setErrors] = useState();
  const [appointmentsList, setAppointmentsList] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [, setOpenMenuKey] = useState('');
  const [createTimeslots] = useMutation(importAppointmentsMutation);

  const { concerns, loading: loadingConcerns } = useUserConcernsByUserId(
    selectedVetAdminUserId || ''
  );

  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    form.setFieldValue('integrationId', null);
  }, [selectedVetAdminId, vetAdmins]);

  useEffect(() => {
    const errors = validateAppointments(appointmentsFormValues || [], {
      hasExternalIds: !!integrationId
    });
    setErrors(errors);
  }, [appointmentsFormValues, integrationId]);

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const parseFile = async (file) => {
    setLoading(true);
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async (results) => {
        if (results?.data?.length) await parseUploadedFileData(results?.data);
        setLoading(false);
      }
    });
  };
  const parseUploadedFileData = async (results) => {
    const newAppointments = [];

    let i = 0;
    for (const data of results) {
      if (i === 50) {
        console.log('10 sec throttle (every 50)');
        await sleep(10000);
        i = 0;
      }

      const appointment = await serializeAppointment(
        data,
        vets,
        integrationId,
        form.getFieldValue('travelTimeIncluded'),
        form.getFieldValue('supportedServices')
      );

      newAppointments.push(appointment);
      i++;
    }
    setAppointmentsList(newAppointments);
    form.setFieldsValue({ appointments: newAppointments });
    return newAppointments;
  };
  const handleErrors = (errors) => {
    if (errors?.length) {
      const { appointments } = form.getFieldsValue(['appointments']);
      const updatedAppointments = appointments.map((appointment, index) => {
        const errorsForAppointment = errors.filter((error) => error?.index === index);
        if (errorsForAppointment.length > 0) {
          const errorMessages = errorsForAppointment.map((error) => error.message).join('; ');
          return { ...appointment, error: errorMessages };
        }
        return appointment;
      });

      const filteredAppointments = updatedAppointments.filter((_, index) =>
        errors.find((error) => error?.index === index)
      );

      form.setFieldsValue({ appointments: filteredAppointments });
      message.error('Please fix invalid data and try again');
    } else {
      message.success('Appointments are saved successfully');
      form.setFieldsValue({ appointments: [] });
      setAppointmentsList([]);
    }
  };
  const onSubmit = async (values) => {
    try {
      if (!selectedVetAdminId) {
        message.error('Please select a Clinic');
        return;
      }

      if (!values.integrationId) {
        message.error('Please select a PIM');
        return;
      }

      setUploading(true);

      const cleanedAppointments = values.appointments.map(
        ({ error, supportedServices, ...rest }) => rest
      );

      const travelTimeIncluded = form.getFieldValue('travelTimeIncluded') || false;
      const supportedServices = form.getFieldValue('supportedServices') || [];

      const { data } = await createTimeslots({
        variables: {
          record: {
            ...values,
            appointments: cleanedAppointments,
            travelTimeIncluded,
            supportedServices
          }
        }
      });
      handleErrors(data?.importAppointments?.errors);
    } catch (e) {
      message.error('serverError');
    } finally {
      setUploading(false);
    }
  };
  const actions = useMemo(
    () => ({
      editRow: (record) => setEditingKey(record),
      deleteRow: (index) => {
        const { appointments } = form.getFieldsValue(['appointments']);
        const newValues = appointments.filter((u, i) => i !== index);
        form.setFieldsValue({
          appointments: newValues
        });
        setAppointmentsList(newValues);
        setOpenMenuKey('');
        setEditingKey('');
      },
      cancelRow: () => {
        setEditingKey('');
        setOpenMenuKey('');
      },
      setFieldValue: form.setFieldValue
    }),
    []
  );

  return {
    integrationId,
    errors,
    editingKey,
    serializeAppointment,
    onSubmit,
    parseFile,
    form,
    appointmentsList,
    actions,
    selectedVetAdminId,
    setSelectedVetAdminId,
    uploading,
    concerns,
    setSelectedVetAdminUserId
  };
};

export default useUploadAppointments;
