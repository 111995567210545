const useTotalsListArray = ({ customerTotals, messages }) => {
  return [
    {
      uid: 1,
      title: messages.t('totals.number_of_customers'),
      value: customerTotals?.totalCustomers
    },
    // {
    //   uid: 3,
    //   title: messages.t('totals.canceled_subscriptions'),
    //   value: customerTotals?.inactiveSubscriptions
    // },
    // {
    //   uid: 4,
    //   title: messages.t('totals.total_spending'),
    //   value: customerTotals?.totalSpending
    // },
    // {
    //   uid: 5,
    //   title: messages.t('totals.active_subscriptions'),
    //   value: customerTotals?.activeSubscriptions
    // },
    // {
    //   uid: 6,
    //   title: messages.t('totals.one_time_subscriptions'),
    //   value: customerTotals?.oneTimeSubscriptions
    // },
    {
      uid: 7,
      title: messages.t('totals.number_pets'),
      value: customerTotals?.petsAmount
    }
  ];
};

export default useTotalsListArray;
