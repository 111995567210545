import css from './Tag.module.css';
import React from 'react';
import cls from 'classnames';
import { Tag as TagAntd } from 'antd';

const Tag = ({ className, children, ...props }) => (
  <TagAntd className={cls(css.tag, className)} {...props}>
    {children}
  </TagAntd>
);

export default Tag;
