import React, { useEffect } from 'react';

import { Input, Table, DatePicker, Select, Radio, Skeleton } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import useColumns from './useColumns';
import useVets from './useVets';
import useCustomersData from './useCustomersData';
import { SubscriptionStatus } from './subscriptionStatus';

import useTotalsListArray from 'pages/dashboard/customers/useTotalsListArray';

import { orderConverter, messages } from 'service/utils';

import './customers-styles.css';

const { ACTIVE, CANCELLED, DRAFT } = SubscriptionStatus;

const Customers = () => {
  const ONE_TIME = 'one_time';
  const { Search } = Input;
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const { vetsData } = useVets();

  const {
    customersData,
    setName,
    name,
    setSearch,
    setSortBy,
    setVet,
    vet,
    countries,
    setCountries,
    page,
    setPage,
    subscriptionStatus,
    setSubscriptionStatus,
    loading,
    totalData,
    registrationDate,
    setRegistrationDate,
    customerTotals,
    customerTotal,
    sortBy
  } = useCustomersData();
  const columns = useColumns(sortBy);

  const totalsListArray = useTotalsListArray({ customerTotals, messages });
  const onSearch = (value) => {
    setPage(1);
    setSearch(value);
  };

  const filterCustomerName = (e) => {
    setName(e.target.value);
  };
  const onSelectVetChange = (value) => {
    setVet(value);
  };
  const onCountryChange = (value) => {
    setCountries(value);
  };

  const onDateChange = (values) => {
    setRegistrationDate(
      values
        ? {
            from: dayjs(values[0]).format(),
            to: dayjs(values[1]).format()
          }
        : null
    );
  };

  const getPageNumber = (page, pageSize) => {
    setPage(page);
  };

  useEffect(() => {
    setPage(1);
  }, [registrationDate, subscriptionStatus, countries, vet, name]);

  const changeSort = (sorter) => {
    const sortObject = {};
    if (Array.isArray(sorter)) {
      for (let i = 0; i < sorter.length; i++) {
        sortObject[sorter[i].field] = orderConverter(sorter[i].order);
      }
      setSortBy((prev) => {
        return { ...prev, ...sortObject };
      });
    } else if (!!Object.keys(sorter).length) {
      const { field, order } = sorter;
      setSortBy((prev) => {
        return {
          registrationDate: null,
          fullName: null,
          country: null,
          cardExpiration: null,
          subscriptionDate: null,
          subscriptionDuration: null,
          spendingAmount: null,
          petAmount: null,
          status: null,
          [field]: orderConverter(order)
        };
      });
    }
  };

  return (
    <>
      <div>
        <div className="listWrapper">
          <ul className="totalsList">
            {totalsListArray[0]?.value ? (
              totalsListArray?.map((item) => (
                <Input
                  key={item?.uid}
                  className="totalsListItem"
                  readOnly
                  value={`${item.title} ${item.value}`}
                  size="middle"
                  style={{ width: '250px' }}
                />
              ))
            ) : (
              <Skeleton
                active
                block
                loading={!totalsListArray[0]?.value}
                title={false}
                paragraph={{ rows: 1 }}
              />
            )}
          </ul>
        </div>

        <Search
          placeholder="Search (name, email, phone...)"
          onSearch={onSearch}
          style={{ minWidth: '80%' }}
        />

        <div className="filters-wrapper">
          <div className="filter-top">
            <Input
              placeholder="Customer Name"
              prefix={<UserOutlined />}
              style={{ flex: '1 20rem' }}
              onChange={filterCustomerName}
            />
            <RangePicker
              placeholder={['Registered from', 'Registered until']}
              onChange={onDateChange}
            />
            <Select
              showSearch
              style={{ flex: '1 20rem' }}
              placeholder="Assigned Vet"
              optionFilterProp="children"
              mode="multiple"
              onChange={onSelectVetChange}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {vetsData?.map(({ name, id }) => {
                return (
                  <Option value={id} key={id}>
                    {name}
                  </Option>
                );
              })}
            </Select>
            {/*<Select*/}
            {/*  style={{ flex: '1 20rem' }}*/}
            {/*  onChange={onCountryChange}*/}
            {/*  placeholder="Country"*/}
            {/*  mode="multiple"*/}
            {/*>*/}
            {/*  {!!countriesList?.length &&*/}
            {/*    countriesList.map((c) => {*/}
            {/*      return <Option value={c.name}>{c.name}</Option>;*/}
            {/*    })}*/}
            {/*</Select>*/}
          </div>
          {/*<div className="filter-bottom">*/}
          {/*  <div className="status-wrapper" style={{ flex: 1 }}>*/}
          {/*    <Radio.Group*/}
          {/*      style={{ flex: 1 }}*/}
          {/*      onChange={(e) => setSubscriptionStatus(e.target.value)}*/}
          {/*      value={subscriptionStatus}*/}
          {/*    >*/}
          {/*      /!*<Radio value={null}>All</Radio>*!/*/}
          {/*      /!*<Radio value={ACTIVE}>Active</Radio>*!/*/}
          {/*      <Radio value={CANCELLED}>Canceled</Radio>*/}
          {/*      <Radio value={ONE_TIME}>One Time</Radio>*/}
          {/*      <Radio value={DRAFT}>Inactive</Radio>*/}
          {/*    </Radio.Group>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
        {customerTotal && (
          <Input
            className="totalsListItem mb-30"
            maxLength={100}
            readOnly
            value={'Records found ' + customerTotal}
            size="middle"
            style={{ width: '250px', marginBottom: '20px' }}
          />
        )}
      </div>
      <Table
        columns={columns}
        dataSource={customersData}
        rowKey="userId"
        loading={loading}
        onChange={(pagination, filters, sorter) => {
          changeSort(sorter);
        }}
        pagination={{
          pageSize: 10,
          onChange: getPageNumber,
          total: totalData,
          current: page
        }}
      />
    </>
  );
};

export default Customers;
