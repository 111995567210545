import * as Yup from 'yup';

export const MandatorySelectorFieldValidation = Yup.object()
  .shape({
    label: Yup.string().required('required'),
    value: Yup.string().required('required')
  });

export const NonMandatorySelectorFieldValidation = Yup.object().shape({
  label: Yup.string(),
  value: Yup.string()
});
