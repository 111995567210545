import { useMediaQuery } from 'react-responsive';
import theme from '../../styles/theme';

const MediaQueriesList = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.screenSizes.mobile}px)`,
  });

  const isTablet = useMediaQuery({
    query: `(max-width: ${theme.screenSizes.tabletL}px)`,
  });

  const isLaptop = useMediaQuery({
    query: `(max-width: ${theme.screenSizes.laptop}px)`,
  });

  return {
    isMobile,
    isTablet,
    isLaptop
  }

}

export default MediaQueriesList;