import React, { useState } from 'react';
import useVets from '../../components/customers/useVets.js';
import useVetAdmins from '../../components/customers/useVetAdmins.js';
import { useQuery } from '@apollo/react-hooks';
import { getPetTypesQuery } from '../../gql/index.js';
import useUploadAppointments from './useUploadAppointments.js';
import LoaderWrapper from '../../components/ui/LoaderWrapper/LoaderWrapper.js';
import UploadAppointments from './UploadAppointments.jsx';

const UploadAppointmentsContainer = () => {
  const [loading, setLoading] = useState(false);
  const { vetsData, vets, loading: loadingVets } = useVets();
  const { vetAdminsData, vetAdmins, loading: loadingVetAdmins } = useVetAdmins();
  const { data: petTypes, loading: loadingPetTypes } = useQuery(getPetTypesQuery);
  const isLoading = loading || loadingVets || loadingPetTypes || loadingVetAdmins;
  const {
    errors,
    editingKey,
    form,
    onSubmit,
    parseFile,
    appointmentsList,
    actions,
    selectedVetAdminId,
    setSelectedVetAdminId,
    uploading,
    concerns,
    setSelectedVetAdminUserId
  } = useUploadAppointments(vets, vetAdmins, setLoading);

  return (
    <LoaderWrapper isLoading={isLoading}>
      <UploadAppointments
        errors={errors}
        vetAdminsData={vetAdminsData}
        vetAdmins={vetAdmins}
        petTypes={petTypes?.petTypes || []}
        setLoading={setLoading}
        editingKey={editingKey}
        form={form}
        onSubmit={onSubmit}
        parseFile={parseFile}
        appointmentsList={appointmentsList}
        actions={actions}
        vets={vets}
        selectedVetAdminId={selectedVetAdminId}
        setSelectedVetAdminId={setSelectedVetAdminId}
        uploading={uploading}
        concerns={concerns}
        setSelectedVetAdminUserId={setSelectedVetAdminUserId}
      />
    </LoaderWrapper>
  );
};

export default UploadAppointmentsContainer;
