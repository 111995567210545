import React from 'react';
import {Card, Modal, Descriptions, Collapse} from 'antd';
import {UserOutlined, CalendarOutlined} from '@ant-design/icons';
import './styles.css';
import {ReactComponent as PawIcon} from 'common/assets/icons/black-paw.svg';
import {messages, parseTimeSlotHour, getAge} from 'service/utils';
import File from './File.jsx';
import {RescheduleWrapper} from '../../../components/_new/Reschedule';

const {Panel} = Collapse;

const SingleAppointmentDetails = ({
  appointment,
  event,
  isTimeslotEventType,
  forceCloseModal,
}) => {
  const [rescheduleModal, setRescheduleModal] = React.useState(false);
  const [view, onChangeView] = React.useState(true);
  return (
    <>
      <Collapse
        expandIconPosition={'end'}
        defaultActiveKey={['false']}>
        <Panel
          header={
            <div className="flex-row">
              <div className="flex-column">
                <div className='panel-header-client-name'>
                  <UserOutlined className='icon'/>
                  {`${appointment?.appointments[0]?.subscription?.user?.firstName} ${appointment?.appointments[0]?.subscription?.user?.lastName}`}
                </div>
                <div className='panel-header-pets'>
                  <PawIcon className='icon' />
                  { appointment?.appointments[0]?.pets.map((pet) => pet.pet.name ).join(' ,')}
                </div>
              </div>
              <div className="pl-20" onClick={(e) => e.stopPropagation()}>
                <div className="sad-reschedule_icon" title="Reschedule Appointment" onClick={() => setRescheduleModal(true)}>
                  <CalendarOutlined title="Reschedule Appointment" />
                </div>
              </div>
            </div>}
          className='panel-appointment'
          key={isTimeslotEventType ? event : false}>
          <Descriptions
            className='panel-descriptions'
            column={1}
            labelStyle={{fontWeight: 600, textDecoration: 'underline'}}
            contentStyle={{textTransform: 'capitalize'}}>
            <Descriptions.Item label={messages.t('schedule.schedule_modal.timeslot_details.appointment_list.lbl_address')}>{appointment?.address?.description}</Descriptions.Item>
            <Descriptions.Item label={messages.t('schedule.schedule_modal.timeslot_details.appointment_list.lbl_date')}>{(new Date(event.startTime)).toLocaleDateString()}</Descriptions.Item>
            { !isTimeslotEventType && <Descriptions.Item label={messages.t('schedule.schedule_modal.timeslot_details.appointment_list.lbl_hours')}>{new Date(event?.startTime).toLocaleString('en-US', {hour12: true, timeStyle: 'short'}) + ' - ' + new Date(event?.endTime).toLocaleString('en-US', {hour12: true, timeStyle: 'short'})}</Descriptions.Item>}
            { !isTimeslotEventType && <Descriptions.Item label={messages.t('schedule.schedule_modal.timeslot_details.appointment_list.lbl_eta')}>{(appointment?.etaStart && appointment?.etaEnd) ? parseTimeSlotHour(appointment?.etaStart).toLocaleString('en-US', {hour12: true, timeStyle: 'short'}) + ' - ' + parseTimeSlotHour(appointment?.etaEnd).toLocaleString('en-US', {hour12: true, timeStyle: 'short'}) : 'TBD'}</Descriptions.Item>}
            <Descriptions.Item label={messages.t('schedule.schedule_modal.timeslot_details.appointment_list.lbl_pets')}
              contentStyle={{display: 'flex', flexWrap: 'wrap'}}>
              {appointment?.appointments[0]?.pets.map((pet) =>{
                return (
                  <Card
                    size="small"
                    title={pet.pet.name}
                    className='panel-decriptions-pet'>
                    <Descriptions column={1} labelStyle={{fontWeight: 500}}>
                      <Descriptions.Item label={messages.t('schedule.schedule_modal.timeslot_details.appointment_list.lbl_age')}>{pet.pet?.birthDate ? getAge(new Date(pet.pet?.birthDate)) : '--'}</Descriptions.Item>
                      <Descriptions.Item label={messages.t('schedule.schedule_modal.timeslot_details.appointment_list.lbl_sex')}>{pet.pet?.gender ? pet.pet?.gender : '--'}</Descriptions.Item>
                      <Descriptions.Item label={messages.t('schedule.schedule_modal.timeslot_details.appointment_list.lbl_reason')}>{pet.userConcerns?.map((c) => c.itemValue || c?.concern?.name).join(' | ') || 'None'}</Descriptions.Item>
                      <Descriptions.Item label={messages.t('schedule.schedule_modal.timeslot_details.appointment_list.lbl_chief_comlaint')}>{pet?.comment || 'None'}</Descriptions.Item>
                      <Descriptions.Item label={messages.t('schedule.schedule_modal.timeslot_details.appointment_list.lbl_vet_comments')}>{pet?.privateComment || 'None'}</Descriptions.Item>
                      <Descriptions.Item label={messages.t('schedule.schedule_modal.timeslot_details.appointment_list.files')}>
                        <div className="files">
                          {pet.files?.length ? pet.files.map((f) => <File url={f} key={f}/>) : 'None'}
                        </div>
                      </Descriptions.Item>
                    </Descriptions>
                  </Card>
                );
              })}
            </Descriptions.Item>
          </Descriptions>
        </Panel>
      </Collapse>
      <Modal
        title="Reschedule Appointment"
        visible={rescheduleModal}
        onOk={() => setRescheduleModal(false)}
        onCancel={() => setRescheduleModal(false)}
        className={view ? 'customModal' : 'customModal customModalMap'}
        destroyOnClose
        footer={null}
        width={800}
      >
        <RescheduleWrapper
          close={() => {
            setRescheduleModal(false);
            forceCloseModal();
          }}
          onChangeView={onChangeView}
          vetId={event.vetId}
          currentAddress={appointment?.address}
          appointment={appointment?.appointments[0]}
          defaultTimeslot={event.timeSlot}
        />
      </Modal>
    </>
  );
};

export default SingleAppointmentDetails;
