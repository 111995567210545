import React, {useMemo, useCallback} from 'react';
import {messages} from 'service/utils';

import Button from 'components/common/button/Button';
import {Checkbox} from 'antd';

const ModalFooter = ({isNextButtonDisabled, onNextClick, current, steps, onRequestClose, changeStep, isSubmitting, informClient, setInformClient}) => {
  const getNextButtonLabel = useMemo(() => {
    if (current === steps.length - 1) {
      return messages.t('finish');
    }
    return messages.t('next');
  }, [current, steps]);

  const getBackButtonLabel = useMemo(() => {
    if (current != 0) {
      return messages.t('back');
    }
    return messages.t('cancel');
  }, [current, steps]);

  const handleSubmitBackBtn = useCallback(() => {
    if (current != 0) changeStep(current - 1);
    else onRequestClose();
  }, [current, steps]);

  return (
    <div className="appointments-footer-wrapper">
      {current === steps.length - 1
        ? <div style={{display: 'flex', alignItems: 'baseline', gap: 10, marginRight: 20, fontSize: 14}}>
          <Checkbox
            checked={informClient}
            onChange={(e) => {
              setInformClient(e.target.checked);
            }}
          >Inform Client by Email</Checkbox>
        </div>
        : null}
      <Button label={getBackButtonLabel} disabled={isSubmitting} className='cancel-btn' onClick={handleSubmitBackBtn}/>
      <Button
        label={getNextButtonLabel}
        color='primary'
        className={`next-btn ${isNextButtonDisabled ? 'disabled' : ''}`}
        disabled={isNextButtonDisabled || isSubmitting}
        onClick={onNextClick}
      />
    </div>
  );
};

export default ModalFooter;
