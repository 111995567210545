import styled from 'styled-components';

export const ButtonWithIcon = styled.div`
  width: ${({ size }) => (size === 'big' ? '145px' : size === 'medium' ? '124px' : '100px')};
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 9px;
  font-size: 14px;
  background: ${({ active, theme }) => (active ? theme.colors.darkGreen : theme.colors.white)};
  color: ${({ active, theme }) => (active ? theme.colors.white : 'rgba(0,0,0,.85)')};
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.043);
  border-radius: 2px;
  border: 1px solid transparent;
  border-color: ${({ active, theme }) => (active ? 'transparent' : theme.colors.lightGray)};
  cursor: pointer;
`;
