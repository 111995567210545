import React, {memo} from 'react';
import {messages} from 'service/utils';
import {Form, Input, Layout, Typography} from 'antd';

import PhoneInput from 'components/common/phone-input/PhoneInput';
import {PhoneInputWrapper} from 'components/common/phone-input/components';

import './style.scss';

const {Title} = Typography;
const {Content} = Layout;

const PersonalDetails = ({form, phoneNumberError, isForVet}) => {
  return (
    <Content className='patient-details-wrapper personal-details-wrapper'>
      <Title className='page-heading'>
        {isForVet
          ? messages.t('recommender.scheduling_stepper.vet_personal_step_title')
          : messages.t('recommender.personal_details.add.information.about.yourself')}
      </Title>
      <Form.Item
        name='firstName'
        rules={[{required: true, whitespace: true}, {max: 25, message: messages.t('too.much.symbols')}]}
        label={messages.t('recommender.personal_details.firstName')}
      >
        <Input placeholder='Maria' maxLength={25}/>
      </Form.Item>
      <Form.Item
        name='lastName'
        rules={[{required: true, whitespace: true}, {max: 25, message: messages.t('too.much.symbols')}]}
        label={messages.t('recommender.personal_details.lastName')}
      >
        <Input placeholder='Morrison' maxLength={25}/>
      </Form.Item>
      <Form.Item
        name='email'
        rules={[
          {required: true, type: 'email', whitespace: true},
          {max: 254, message: messages.t('too.much.symbols')},
        ]}
        label={messages.t('recommender.personal_details.email')}
      >
        <Input placeholder='Mariamorrison@gmail.com' maxLength={254}/>
      </Form.Item>
      <Form.Item
        name='phoneNumber'
        rules={[{required: true}, {pattern: /^[0-9]{8,15}$/, message: 'Invalid number'}]}
        label={messages.t('recommender.personal_details.phone_number')}
      >
        <PhoneInputWrapper>
          <PhoneInput
            hideLabel
            searchPlaceholder={messages.t('phone.input.search.placeholder')}
            value={`${form.getFieldValue('phonePrefix')}${form.getFieldValue('phoneNumber')}`}
            onChange={(prefix, number) => {
              form.setFieldsValue({phonePrefix: prefix, phoneNumber: number});
            }}
            isPublic
            error={phoneNumberError}
          />
        </PhoneInputWrapper>
      </Form.Item>
    </Content>
  );
};

export default memo(PersonalDetails);
