import React from 'react';
import dayjs from 'dayjs';
import {useHistory} from 'react-router';
import {Table, Input} from 'antd';

import {ADD_VET, USERS} from 'constants/routeNames';
import {dashboardLink} from 'service/utils';
import useVetList from './useVetList';
import useColumns from './useColumns';
import './styles.css';
import LoaderWrapper from "components/ui/LoaderWrapper/LoaderWrapper.js";

const {Search} = Input;

export const VetList = () => {
  const history = useHistory();
  const {vets, refetchVets, setSearch, loading} = useVetList();
  const columns = useColumns(refetchVets);

  const addVetButtonHandler = () => {
    history.push(dashboardLink(USERS + ADD_VET));
  };

  const dataSource = vets?.map((vet) => {
    return {
      key: vet?.uid,
      name: vet?.user?.firstName + ' ' + vet?.user?.lastName,
      userID: vet?.uid,
      registrationDate: dayjs(vet?.createdAt).format('DD/MM/YYYY'),
      email: vet?.user?.email,
      phone: vet?.user?.phonePrefix + vet?.user?.phoneNumber,
      country: vet?.user?.address?.country?.name,
      activeCustomers: vet?.activeCustomersCount,
      status: vet?.status,
      timeSlots: vet?.timeSlots,
      fullTimeSlots: vet?.fullTimeSlots,
      vetUserId: vet?.user?.uid
    };
  });

  return (

      <div className={'scrollable-space'}>
        <Search
          placeholder="Search"
          onSearch={setSearch}
          style={{maxWidth: "200px", width: '30%', marginBottom:"20px"}}
        />
        {/* <RoleBasedUi permission={Permissions.CREATE_VET}>
        <Button
          className="add-vet-btn"
          type="primary"
          onClick={addVetButtonHandler}
        >
          Add New Vet{' '}
        </Button>
      </RoleBasedUi> */}
        <Table dataSource={dataSource} columns={columns}  loading={loading}/>
      </div>

  );
};
