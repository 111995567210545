import React from 'react';

import useLogin from './useLogin';
import { messages } from 'service/utils';

import { Form, Input, Button, Col, Row, Card } from 'antd';
import { ReactComponent as Logo } from 'common/assets/icons/logo-blue.svg';

export const Login = () => {
  const { handleSubmit, values, handleChange } = useLogin();

  return (
    <>
      <Row type={'flex'} justify={'center'} align={'middle'} className="mt-20">
        <Col xs={24} md={10}>
          <Card bordered={false} style={{ display: 'flex', flexDirection: 'column' }}>
            <Logo style={{ alignSelf: 'center' }} width={300} align={'center'} />

            <Form
              name="loginForm"
              layout={'vertical'}
              data-test={'loginForm'}
              onFinish={handleSubmit}
            >
              <Form.Item label={messages.t('forms.email')}>
                <Input name="email" onChange={handleChange} value={values.email} />
              </Form.Item>
              <Form.Item label={messages.t('forms.password')}>
                <Input.Password
                  autoComplete="on"
                  name="password"
                  onChange={handleChange}
                  value={values.password}
                />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" data-test={'loginSubmit'}>
                  {messages.t('general.submit')}
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Login;
