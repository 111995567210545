export const getStartEndPoints = (timeSlot) => {
  if (!timeSlot) return undefined;

  const selectedRoute = timeSlot?.route?.route;
  const selectedTimeslot = timeSlot?.timeSlot;

  if (selectedRoute) {
    return {
      startPoint: timeSlot?.route?.route?.startPoint,
      endPoint: timeSlot?.route?.route?.endPoint,
    };
  } else if (selectedTimeslot) {
    // find the userSettings array within the vet's user object
    const userSettings = selectedTimeslot?.vet?.user?.userSettings;

    // find the userSetting objects with description equal to "DEFAULT_START_POINT" and "DEFAULT_END_POINT"
    const startPointSetting = userSettings.find((setting) => setting?.setting?.description === 'DEFAULT_START_POINT');
    const endPointSetting = userSettings.find((setting) => setting?.setting?.description === 'DEFAULT_END_POINT');

    // extract the itemValue properties from the startPointSetting and endPointSetting objects
    const startPoint = JSON.parse(startPointSetting?.itemValue);
    const endPoint = JSON.parse(endPointSetting?.itemValue);

    // create a new object with startPoint and endPoint properties
    return {startPoint, endPoint};
  } else {
    return undefined;
  }
};
