import React, {useState} from 'react';
import {AddressInput} from '../../../../_new/AddressInput/index.js';
import {isValidAddress} from '../../../../../utils/helpers.js';
import {RescheduleWrapper} from '../../../../_new/Reschedule/index.js';
import css from './AddressStepNew.module.css';
import Button from '../../../../../library/Button/Button';
import {messages} from '../../../../../service/utils.js';
import AdditionalInfo from './components/AdditionalInfo.jsx';

export const AddressStepNew = (props) => {
  const {
    filters,
    timeslotActions,
    address, addressError, isForVet, onSelect, onChange, propertyType, setPropertyType,
    vet, setSelectedTimeslot, onChangeView,
  } = props;
  const [showAddressAdditionalInfo, setShowAddressAdditionalInfo] = useState(false);

  return <div className={css.container}>
    <span className={css.address}>Client address</span>
    <AddressInput
      address={address}
      addressError={addressError}
      isForVet={isForVet}
      onSelect={onSelect}
      onChange={onChange}
    />
    <Button
      colorScheme="orange"
      type="link"
      onClick={() => setShowAddressAdditionalInfo((showAddressAdditionalInfo) => !showAddressAdditionalInfo)}
    >
      { messages.t(`recommender.address_details.${showAddressAdditionalInfo ? 'hide' : 'add'}`)} Additional Address Info
    </Button>

    {showAddressAdditionalInfo && (
      <AdditionalInfo
        propertyType={propertyType}
        setPropertyType={setPropertyType}
        isForVet={isForVet}
      />
    )}
    {isValidAddress(address)
      ? <RescheduleWrapper
        onChangeView={onChangeView}
        filters={filters}
        timeslotActions={timeslotActions}
        theme="light"
        currentAddress={address}
        vetId={vet?.uid}
        displayFooter={false}
        displayHeader={false}
        close={() => {}}
      /> : null}
  </div>;
};
