import React, { createContext, useContext } from "react";
import { useJsApiLoader } from "@react-google-maps/api";



const GoogleMapsContext = createContext({ isLoaded: false });

export const GoogleMapsProvider = ({
                                                               children,
                                                               ...loadScriptOptions
                                                             }) => {
  const { isLoaded, loadError } = useJsApiLoader(loadScriptOptions);


  const value = { isLoaded, loadError };

  return (
    <GoogleMapsContext.Provider value={value}>
      {children}
    </GoogleMapsContext.Provider>
  );
};

export const useGoogleMaps = () => useContext(GoogleMapsContext);
