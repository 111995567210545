import React from 'react';
import PInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { messages } from 'service/utils';
import './styles.css';

const PhoneInput = ({ setFieldValue, phonePrefix, phoneNumber, isError }) => {
  return (
    <>
      <PInput
        placeholder={messages.t('vet.mobile_number')}
        prefix={phonePrefix}
        value={phonePrefix + phoneNumber}
        onChange={(value, data) => {
          setFieldValue('phonePrefix', '+' + data?.dialCode);
          setFieldValue('phoneNumber', value.slice(data?.dialCode?.length));
        }}
        className={isError && 'error-input'}
      />
    </>
  );
};

export default PhoneInput;
