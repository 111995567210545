import React from 'react';
import { Input, Row, Col } from 'antd';
import RepeatMonthlyOn from './On';
import RepeatMonthlyOnThe from './OnThe';
import numericalFieldHandler from '../../../utils/numericalFieldHandler';
import translateLabel from '../../../utils/translateLabel';

const RepeatMonthly = ({
  id,
  monthly: { mode, interval, on, onThe, options },
  handleChange,
  translations,
  disabled,
}) => {
  const isTheOnlyOneMode = (option) => options.modes === option;
  const isOptionAvailable = (option) =>
    !options.modes || isTheOnlyOneMode(option);

  return (
    <div className="mt-20">
      <Row align="middle">
        <Col span="6">
          {translateLabel(translations, 'repeat.monthly.every')}
        </Col>
        <Col span="12">
          <Input
            className="full-width"
            type="number"
            id={`${id}-interval`}
            name="repeat.monthly.interval"
            aria-label="Repeat monthly interval"
            className="form-control"
            value={interval}
            onChange={numericalFieldHandler(handleChange)}
            disabled={disabled}
          />
        </Col>
        <Col span="6">
          <span className="ml-20">
            {translateLabel(translations, 'repeat.monthly.months')}
          </span>
        </Col>
      </Row>

      {isOptionAvailable('on') && (
        <div className="mt-20">
          <RepeatMonthlyOn
            id={`${id}-on`}
            mode={mode}
            on={on}
            hasMoreModes={!isTheOnlyOneMode('on')}
            handleChange={handleChange}
            translations={translations}
            disabled={disabled}
          />
        </div>
      )}
      {isOptionAvailable('on the') && (
        <div className="mt-20">
          <RepeatMonthlyOnThe
            id={`${id}-onThe`}
            mode={mode}
            onThe={onThe}
            hasMoreModes={!isTheOnlyOneMode('on the')}
            handleChange={handleChange}
            translations={translations}
            disabled={disabled}
          />
        </div>
      )}
    </div>
  );
};

export default RepeatMonthly;
