import React from 'react';

import { useQuery } from '@apollo/client';
import { getPetOwnerByUid } from 'gql';

const useGetPetOwner = (uid) => {
  const { data, refetch } = useQuery(getPetOwnerByUid, {
    fetchPolicy: 'no-cache',
    variables: {
      record: {
        uid: uid
      }
    }
  });

  const cardList = data?.getPetOwnerById?.user?.paymentCards;

  return {
    petOwnerData: data?.getPetOwnerById,
    cardList,
    subscriptions: data?.getPetOwnerById?.activeSubscriptions,
    refetchPetOwner: refetch
  };
};

export default useGetPetOwner;
