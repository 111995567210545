import React from 'react';
import { GoogleMap, withGoogleMap, withScriptjs } from 'react-google-maps';
import DrawingManager from 'react-google-maps/lib/components/drawing/DrawingManager';

const { google } = window;

const Map = withScriptjs(
  withGoogleMap((props) => {
    const { homeAddress } = props;

    let defaultCenter = { lat: 32.0879994, lng: 34.7622266 };

    if (homeAddress?.lat) {
      defaultCenter = { lat: homeAddress.lat, lng: homeAddress.lng };
    }

    return (
      <GoogleMap defaultZoom={9} defaultCenter={defaultCenter}>
        <DrawingManager
          onPolygonComplete={(polygon) => {
            const lnglatsArray = polygon.getPath().getArray();
            const coordinates = lnglatsArray.map((item) => {
              return { lat: item.lat(), lng: item.lng() };
            });
            props.setPolygons(coordinates);
          }}
          defaultDrawingMode={google.maps.drawing.OverlayType.POLYGON}
          defaultOptions={{
            drawingControl: true,
            drawingControlOptions: {
              position: google.maps.ControlPosition.TOP_CENTER,
              drawingModes: [
                google.maps.drawing.OverlayType.CIRCLE,
                google.maps.drawing.OverlayType.POLYGON,
                google.maps.drawing.OverlayType.POLYLINE,
                google.maps.drawing.OverlayType.RECTANGLE
              ]
            },
            circleOptions: {
              fillColor: `#ffff00`,
              fillOpacity: 1,
              strokeWeight: 5,
              clickable: false,
              editable: true,
              zIndex: 1
            }
          }}
        />
      </GoogleMap>
    );
  })
);

export default Map;
