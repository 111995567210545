import React, { useState } from 'react';
import Select, { components } from 'react-select';
import { useMediaQuery } from 'react-responsive';

import theme from 'styles/theme';

import { styles, SelectorWrapper, Dots } from './components';

const { ValueContainer, Placeholder } = components;

const Selector = ({
  value,
  placeholder,
  onChange,
  options,
  error,
  customStyles,
  getOptionValue,
  getOptionLabel,
  defaultValue,
  hideLabel,
  animateLabel = true,
  noError,
  isForTimeSlot,
  isSearchable = false,
  closeMenuOnSelect = true,
  isMulti = false,
  hideSelectedOptions = true,
  controlShouldRenderValue = true,
  formatOptionLabel,
  menuPortalTarget,
  valueContainer,
  customHeight,
  isFilter,
  CheckboxMobile,
  isClearable,
  isRtl,
  errorText
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.screenSizes.mobile}px)`
  });
  const isLaptop = useMediaQuery({
    query: `(max-width: ${theme.screenSizes.laptop}px)`
  });
  return (
    <SelectorWrapper>
      <Select
        placeholder={placeholder}
        styles={{
          ...styles(
            error,
            isFocused,
            hideLabel,
            isMobile,
            isForTimeSlot,
            isLaptop,
            animateLabel,
            customHeight,
            isFilter,
            isRtl
          ),
          ...customStyles
        }}
        components={{
          IndicatorSeparator: () => null,
          ValueContainer: CustomValueContainer,
          ...valueContainer
        }}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        onChange={onChange}
        options={options}
        value={value}
        defaultValue={defaultValue}
        isSearchable={isSearchable}
        isClearable={isClearable}
        closeMenuOnSelect={closeMenuOnSelect}
        isMulti={isMulti}
        hideSelectedOptions={hideSelectedOptions}
        controlShouldRenderValue={controlShouldRenderValue}
        menuPortalTarget={menuPortalTarget}
        formatOptionLabel={formatOptionLabel}
        isFilter={isFilter}
      />
      {!noError && <span>{errorText}</span>}
    </SelectorWrapper>
  );
};

const CustomValueContainer = ({ children, ...props }) => {
  const isLaptop = useMediaQuery({
    query: `(max-width: ${theme.screenSizes.laptopM}px)`
  });
  const screenCheck = isLaptop ? 2 : 3;
  const lengthCheckByScreen = isLaptop ? 3 : 4;
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child, i) =>
        i <= screenCheck && child && child.type !== Placeholder ? child : null
      )}
      {children?.[0]?.length > lengthCheckByScreen && <Dots>...</Dots>}
    </ValueContainer>
  );
};

export default Selector;
