const Roles = {
  ADMIN: 'admin',
  MODERATOR: 'moderator',
  PET_OWNER: 'pet_owner',
  VET: 'vet',
  VETS_ADMIN: 'vetsadmin'
};

const AdminPermissions = {
  VIEW_SETTINGS: 'VIEW_SETTINGS',
  VIEW_REPORTS: 'VIEW_REPORTS',
  VIEW_DASHBOARD: 'VIEW_DASHBOARD',
  VIEW_UPLOAD_USERS: 'VIEW_UPLOAD_USERS',
  VIEW_UPLOAD_APPOINTMENTS: 'VIEW_UPLOAD_APPOINTMENTS',
  SET_VET_STATUS_LIMIT_REACHED: 'SET_VET_STATUS_LIMIT_REACHED'
};

const MenuItemPermissions = {
  VIEW_MENU_ITEM_SCHEDULE: 'VIEW_MENU_ITEM_SCHEDULE'
};

const VetPermissions = {
  VIEW_VET: 'VIEW_VET',
  UPDATE_VET: 'UPDATE_VET',
  DELETE_VET: 'DELETE_VET',
  EDIT_VET: 'EDIT_VET',
  CREATE_VET: 'CREATE_VET'
};

const TablePermissions = {
  VIEW_USER_ID: 'VIEW_USER_ID'
};

const TimeSlotPermissions = {
  VIEW_TIMESLOTS: 'VIEW_TIMESLOTS',
  UPDATE_TIME_SLOTS: 'UPDATE_TIME_SLOTS',
  CREATE_TIMESLOTS: 'CREATE_TIMESLOTS'
};

const CustomerPermissions = {
  VIEW_CUSTOMER: 'VIEW_CUSTOMER',
  UPDATE_CUSTOMER: 'UPDATE_CUSTOMER',
  DELETE_CUSTOMER: 'DELETE_CUSTOMER',
  EDIT_CUSTOMER: 'EDIT_CUSTOMER'
};
export const Permissions = {
  ...VetPermissions,
  ...TimeSlotPermissions,
  ...CustomerPermissions,
  ...AdminPermissions,
  ...TablePermissions,
  ...MenuItemPermissions
};

const AdminExcludedPermissions = [Permissions.VIEW_MENU_ITEM_SCHEDULE];

export const Rules = {
  [Roles.ADMIN]: [...Object.keys(Permissions).map((key) => Permissions[key])].filter(
    (permissions) => !AdminExcludedPermissions.includes(permissions)
  ),
  [Roles.MODERATOR]: [Permissions.VIEW_VET, Permissions.VIEW_TIMESLOTS],
  [Roles.VETS_ADMIN]: [
    Permissions.VIEW_VET,
    Permissions.UPDATE_CUSTOMER,
    Permissions.VIEW_MENU_ITEM_SCHEDULE
  ]
};
