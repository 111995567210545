export const VetStatus = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  OCCUPIED: 'occupied',
  LIMIT_REACHED : 'limit_reached'
};

export const VetCommuteMethod = {
  DRIVING: 'driving',
  BICYCLING: 'bicycling',
  WALKING: 'walking'
};

export const BankAccountType = {
  SAVING: 'SAVING',
  CHECKING: 'CHECKING'
};

export const BankAccountClass = {
  PERSONAL: 'PERSONAL',
  CORPORATE: 'CORPORATE',
  INTERNATIONAL: 'INTERNATIONAL'
};

export const PropertyType  ={
  PrivateHome : 'private_home',
  Apartment : 'apartment',
}

export const Roles = {
  VET: 'Vet',
  PET_OWNER: 'PetOwner',
  ADMIN: 'Admin',
};

export const TimeslotsFilterSort = {
  SUGGESTED : 'suggested',
  DISTANCE_NEAREST : 'distance_nearest',
  DATE_EARLIEST : 'date_earliest',
 }
