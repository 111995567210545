import {useState} from 'react';
import {useQuery} from '@apollo/client';
import {getVetsQuery} from 'gql';

const useVetList = () => {
  const [search, setSearch] = useState('');
  const {data, refetch, loading} = useQuery(getVetsQuery, {
    fetchPolicy: 'no-cache',
    variables: {
      search,
    },
  });

  return {
    vets: data?.getVets,
    refetchVets: refetch,
    setSearch,
    loading,
  };
};

export default useVetList;
