import * as React from 'react';
import * as S from "./components";

const ButtonWithIcon = ({ icon , text , onClick , active, size }) => {
  return (
    <S.ButtonWithIcon size={size} active={active} onClick={onClick}>
      {icon} {text}
    </S.ButtonWithIcon>
  );
};

export default ButtonWithIcon;