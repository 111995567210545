import React from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from 'react-router-dom';
import { LOGIN, DASHBOARD } from 'constants/routeNames';
import ProtectedRoute from '../ProtectedRoute/ProtectedRoute';
import MainDashboard from './DashboardRouter';
import AuthRoute from '../AuthRoute/AuthRoute';
import Login from '../../../pages/login/Login';
import { messages } from 'service/utils';
import { useAuthContext } from 'common/service/auth/useAuthContext';

const MainRouter = () => {
  const auth = useAuthContext();
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          {auth?.me && auth?.hasToken ? (
            <Redirect to={DASHBOARD} />
          ) : (
            <Redirect to={LOGIN} />
          )}
        </Route>
        <ProtectedRoute
          path={DASHBOARD}
          component={MainDashboard}
          auth={auth}
        />
        <AuthRoute exact path={LOGIN} component={Login} />
        <Route
          render={() => <div>{messages.t('general.page_not_found')}</div>}
        />
      </Switch>
    </Router>
  );
};

export default MainRouter;
