import React from "react";
import { Spin, Skeleton, Layout } from 'antd';

const { Sider, Content } = Layout;

    const LoaderWrapperCustomSchedulePage = () => {
      return (<Layout>
                 <Sider style={{background: "white",padding: "20px"}}>
                    <Skeleton active block paragraph={{rows: 10}} />
                 </Sider>
                  <Content style={{background: "white",padding: "20px"}}>
                     <Skeleton active block paragraph={{rows: 10}} />
                 </Content> 
               </Layout>);
    }
  
    export default LoaderWrapperCustomSchedulePage
  