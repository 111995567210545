import React, {useState, useCallback, useEffect} from 'react';
import {messages} from 'service/utils';

import {serializeAddressDescription} from 'serializers/signUpFlow.serializer';
import {PropertyType} from 'utils/enums';
import {Form} from 'antd';

import AddressAutocomplete from 'components/address-autocomplete/AddressAutocomplete';
import AdditionalInfo from 'components/create-appointment/calendar/steps/address/components/AdditionalInfo';
import MediaQueriesList from 'common/utils/mediaQueriesList';
import Button from 'components/common/button/Button';
import Modal from 'components/common/modal/Modal';

import useUpdateUser from '../../edit-pet-owner/useUpdateUser';


import './style.scss';

const EditClientAddressModal = ({client, onRequestClose, refetchClients}) => {
  const {isMobile} = MediaQueriesList();
  const [address, setAddress] = useState({});
  const [propertyType, setPropertyType] = useState(PropertyType.PrivateHome);
  const [isSubmitting, setSubmitting] = useState(false);

  const {updateUser} = useUpdateUser(refetchClients, onRequestClose);

  const onChange = useCallback((value) => {
    setAddress({
      description: value,
    });
  }, []);

  const onSelect = useCallback((value) => {
    const description = serializeAddressDescription(value);
    setAddress({
      ...value,
      description,
    });
  }, []);

  const [form] = Form.useForm();

  useEffect(() => {
    if (client) {
      setAddress(client?.address);
      setPropertyType(PropertyType[client.address?.propertyType]);
    }
  }, []);

  const initialValues = {
    address: client?.address?.description,
    floor: String(client?.address?.floor),
    apartment: client?.address?.apartment,
    userComment: client?.address?.comment,
  };


  return (
    <Modal isOpen={true} autoHeight={isMobile} isLaptop={!isMobile} isMobile={isMobile} width={'450px'}>
      <Form
        form={form}
        layout='vertical'
        initialValues={initialValues}
        onFinish={(values) => {
          setSubmitting(true);
          const data = {
            email: client?.email,
            lastName: client?.lastName,
            firstName: client?.firstName,
            address: {
              ...values,
              ...address,
              propertyType,
              houseNumber: Number(address?.houseNumber),
            },
            floor: Number(values?.floor) || Number(client?.address?.floor),
            comment: values.userComment,
            apartment: values.apartment,
          };
          delete data.address.country;
          delete data.address.address;
          delete data.address.__typename;
          delete data.address.userComment;
          updateUser(data, client?.uid).finally(() => setSubmitting(false));
        }}
      >
        <div className='edit-clients-modal-wrapper address-step'>
          <h3>{messages.t('recommender.edit_address.title')}</h3>
          <Form.Item
            name='address'
            className='address-field'
            rules={[{required: true}, {max: 150}]}
          >
            <div className='address-field'>
              <h2>
                {messages.t('recommender.edit_address.lbl_address')}
              </h2>
              <AddressAutocomplete
                onChange={onChange}
                onSelect={onSelect}
                address={address}
                isForZipCode
                isForCalendar
                isForCompleteProfile={true}
                // error={address && !!Object.keys(address).length && addressError}
              />
            </div>
          </Form.Item>

          <AdditionalInfo
            propertyType={propertyType}
            setPropertyType={setPropertyType}
          />

          <div className='edit-clients-footer'>
            <Button disabled={isSubmitting} label={messages.t('back')} className='cancel-btn' onClick={onRequestClose}/>
            <Button disabled={isSubmitting} label={messages.t('save')} type='submit' color='primary' className='next-btn' />
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default EditClientAddressModal;
