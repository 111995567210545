import styled, { keyframes } from 'styled-components';
import Loader from 'assets/icons/loader.svg';

export const SpinnerWraper = styled.div`
  width: 100%;
  height: ${({ isTimeline }) => (isTimeline ? '40vh' : '100vh')};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const load8 = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  border-radius: 50%;
  width: 5.6em;
  height: 5.6em;
  background-image: url(${Loader});
  -webkit-animation: ${load8} 1.1s infinite linear;
  animation: ${load8} 1.1s infinite linear;
  font-size: x-small!important;
`;
