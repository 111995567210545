import React from 'react';
import css from './Vet.module.css';
import cls from 'classnames';
import text from '../../../../styles/text.module.css';

export const Vet = ({vet}) => <div className={css.vet}>
  <div className={css.icon}>
    {/* todo: create avatar component */}
    {vet.user.avatarUrl ? <img src={vet.avatar} alt="avatar"/> : <div>{`${vet.user.firstName[0]}${vet.user.lastName[0]}`}</div>}
  </div>
  <span className={cls(css.vetName, text.s14w6l18)}>
    {`${vet.user.firstName} ${vet.user.lastName}`}
  </span>
</div>;
