import set from 'lodash/set';

const validateUsers = (users, context = {}) => {
  const { hasExternalIds } = context;
  const errors = {};
  if (hasExternalIds) {
    users.forEach((user, userIndex) => {
      user.pets.forEach((pet, petIndex) => {
        if (!pet.externalId) {
          set(
            errors,
            ['users', userIndex, 'pets', petIndex, 'externalId'],
            'Pet without External Id will be ignored'
          );
        }
      });
    });
  }
  return errors;
};

export default validateUsers;
