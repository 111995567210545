import React, { useEffect } from 'react';
import { Popover } from 'antd';

import { ReactComponent as Info } from 'img/info.svg';
import { messages } from 'service/utils';

import { Dot, AddressCheckItem, AddressCheckList } from './components';

const AddressChecklist = ({ formikAddress, setIsAddressValid }) => {
  const error =
    (formikAddress?.countryCode === 'US' && !formikAddress?.zipCode?.length) ||
    !formikAddress?.countryCode?.length ||
    !formikAddress?.street?.length ||
    !formikAddress?.city?.length;

  useEffect(() => {
    setIsAddressValid(!error);
  }, [formikAddress]);

  return (
    <Popover
      content={
        <AddressCheckList>
          <AddressCheckItem>
            <Dot missing={!formikAddress?.countryCode} />
            {messages.t('vet.country')}
          </AddressCheckItem>
          <AddressCheckItem>
            <Dot missing={!formikAddress?.city} />
            {messages.t('vet.city')}
          </AddressCheckItem>
          <AddressCheckItem>
            <Dot missing={!formikAddress?.street} />
            {messages.t('vet.street')}
          </AddressCheckItem>
          <AddressCheckItem>
            <Dot
              isNotNeeded={formikAddress?.countryCode !== 'US'}
              missing={formikAddress?.countryCode === 'US' && !formikAddress?.zipCode}
            />
            {messages.t('vet.zipcode')}
          </AddressCheckItem>
        </AddressCheckList>
      }
    >
      <Info fill={error ? '#E44E61' : '#22A3C3'} />
    </Popover>
  );
};

export default AddressChecklist;
