import dayjs from 'dayjs';
import {prepareAppPets} from '../components/create-appointment/new-appointment-modal/serializers.js';

export const signUpFinalData = (
    formValues,
    address,
    me,
    auth,
    selectedTime,
    petList,
    propertyType,
    isForVet,
    selectedTimeslot,
    vet,
    petsToCreate,
) => {
  const data = {
    user: {
      address: {
        ...address,
        countryCode: address?.countryCode || address?.country?.code,
        description: formValues.address,
        floor: formValues.floor?.replace(/\D/g, '')?.length ? Number(formValues.floor?.replace(/\D/g, '')) : null,
        apartment: formValues.apartment,
        comment: formValues?.userComment,
        propertyType,
      },
      firstName: me?.firstName || formValues.firstName,
      lastName: me?.lastName || formValues.lastName,
      email: me?.email || formValues.email,
      phoneNumber: me?.phoneNumber || formValues.phoneNumber,
      phonePrefix: me?.phonePrefix || formValues.phonePrefix,
    },
    pets: serializePetList(petsToCreate),
    appointmentPets: prepareAppPets(petList, true),
    date: dayjs(formValues.date).format('YYYY-MM-DD'), //
    vetId: selectedTimeslot?.vet?.uid || vet.uid,
  };
  if (formValues?.isFirstTime) {
    data.isFirstTime = formValues?.isFirstTime;
    data.firstTimePetsCount = formValues?.firstTimePetsCount;
  }
  if (isForVet) {
    data.vetComment = formValues?.condition;
    data.timeSlotId = selectedTimeslot.uid;
  } else {
    data.appointmentComment = formValues?.condition;
    data.timeSlotId = selectedTime?.uid;
  }
  return data;
};

export const serializeAddressDescription = (value) => {
  const {houseNumber, street, zipCode, city, state, countryCode} = value;
  return [houseNumber, street, zipCode, city, state, countryCode].filter(Boolean).join(', ');
};

const statusMap = {
  unknown: null,
  neutered: true,
  no_neutered: false,
};
const serializePetList = (petList, pets) => {
  return petList?.map((pet, i) => ({
    uid: pet.uid,
    name: pet.name,
    type: pet.type?.uid || null,
    birthDate: pet.birthDay,
    isSterilized: statusMap[pet.status] || null,
    gender: pet.gender,
  }));
};

