import { loader } from 'graphql.macro';

// queries
export const getUserByPhoneQuery = loader('./queries/getUserByPhone.graphql');
export const meQuery = loader('./queries/me.graphql');
export const getPetsByOwnerQuery = loader('./queries/getPetsByOwner.graphql');
export const getPetQuery = loader('./queries/getPet.graphql');
export const getSubscriptionTypes = loader('./queries/getSubscriptionTypes.graphql');
export const getPimsQuery = loader('./queries/getPims.graphql');
export const getPlansQuery = loader('./queries/getPlans.graphql');
export const isConversationReadOnlyQuery = loader('./queries/isConversationReadOnly.graphql');
export const getVetAdminsQuery = loader('./queries/getVetAdmins.graphql');
export const getVetsQuery = loader('./queries/getVets.graphql');
export const calculateSubscriptionPriceQuery = loader(
  './queries/calculateSubscriptionPrice.graphql'
);
export const getSubscriptionPetsQuery = loader('./queries/getSubscriptionPets.graphql');
export const getSubscriptionQuery = loader('./queries/getSubscription.graphql');
export const getAppointmentQuery = loader('./queries/getAppointment.graphql');
export const getVetByUidQuery = loader('./queries/getVetByUid.graphql');
export const getTimeSlotsByVetUidQuery = loader('./queries/getTimeSlotsByVetUid.graphql');
export const getAppointmentsQuery = loader('./queries/getAppointments.graphql');
export const getPaymentMethodsQuery = loader('./queries/getPaymentMethods.graphql');
export const getPetTypesQuery = loader('./queries/getPetTypes.graphql');
export const getPetsByUserQuery = loader('./queries/getPetsByUser.graphql');
export const getUserConcernsByUserIdQuery = loader('./queries/getUserConcernsByUserId.graphql');
export const signUpFlowMutation = loader('./mutations/signUpFlow.graphql');
export const getVetTimeSlotsByUserAddressQuery = loader(
  './queries/getVetTimeSlotsByUserAddress.graphql'
);
export const getFilteredTimeSlotsByVetUidQuery = loader(
  './queries/getFilteredTimeSlotsByVetUid.graphql'
);
export const checkIfUserExistsQuery = loader('./queries/checkIfUserExists.graphql');

export const getPaymentProviderByUserLocationQuery = loader(
  './queries/getProviderByUserLocation.graphql'
);

export const getPetSubscriptionQuery = loader('./queries/getPetSubscription.graphql');
export const getPetOwnersQuery = loader('./queries/getPetOwners.graphql');
export const getPetOwnersTotalsQuery = loader('./queries/getPetOwnersTotals.graphql');
export const getVetInventoriesQuery = loader('./queries/getVetInventories.graphql');
export const getTimezonesQuery = loader('./queries/getTimezones.graphql');
export const getCalendarEventsQuery = loader('./queries/getCalendarEvents.graphql');
export const getLanguagesQuery = loader('./queries/getLanguages.graphql');
export const getPaymentProvidersQuery = loader('./queries/getPaymentProviders.graphql');
export const getCountriesQuery = loader('./queries/getCountries.graphql');
export const getUniqueCountriesQuery = loader('./queries/getUniqueCountries.graphql');
export const getPetOwnerByUid = loader('./queries/getPetOwnerByUid.graphql');
// mutations
export const authAdminMutation = loader('./mutations/authAdmin.graphql');
export const requestCodeMutation = loader('./mutations/requestCode.graphql');
export const removePetMutation = loader('./mutations/removePet.graphql');
export const updatePlanForPetMutation = loader('./mutations/updatePlanForPet.graphql');
export const removeSubscriptionPetMutation = loader('./mutations/removeSubscriptionPet.graphql');
export const verifyCodeMutation = loader('./mutations/verifyCode.graphql');
export const createPetMutation = loader('./mutations/createPet.graphql');
export const updatePersonalDetailsMutation = loader('./mutations/updatePersonalDetails.graphql');
export const getUploadUrlMutation = loader('./mutations/getUploadUrl.graphql');
export const choosePetsForSubscriptionMutation = loader(
  './mutations/choosePetsForSubscription.graphql'
);
export const chooseVetForSubscriptionMutation = loader(
  './mutations/chooseVetForSubscription.graphql'
);
export const createSubscriptionMutation = loader('./mutations/createSubscription.graphql');
export const buySubscriptionMutation = loader('./mutations/buySubscription.graphql');
export const removePaymentCardMutation = loader('./mutations/removePaymentCard.graphql');

export const getPaymentFieldsTokenMutation = loader('./mutations/getPaymentFieldsToken.graphql');

export const buyBluesnapSubscriptionMutation = loader(
  './mutations/buyBluesnapSubscription.graphql'
);

export const createSubscriptionForPetMutation = loader(
  './mutations/createSubscriptionForPet.graphql'
);
export const bookAVisitForPetMutation = loader('./mutations/bookAVisitForPet.graphql');
export const createTimeSlotsMutation = loader('./mutations/createTimeSlots.graphql');
export const createVetMutation = loader('./mutations/createVet.graphql');
export const removeVetMutation = loader('./mutations/removeVet.graphql');
export const actAsUserMutation = loader('./mutations/actAsUser.graphql');
export const updateUserMutation = loader('./mutations/updateUser.graphql');
export const removeTimeSlotMutation = loader('./mutations/removeTimeSlot.graphql');
export const excludeTimeSlotMutation = loader('./mutations/excludeTimeSlot.graphql');
export const removeSubscriptionMutation = loader('./mutations/removeSubscription.graphql');
export const createUsersFromCsvMutation = loader('./mutations/createUsersFromCsv.graphql');
export const importAppointmentsMutation = loader('./mutations/importAppointments.graphql');

export const getTimeSlotsQuery = loader('./queries/getTimeSlots.graphql');
export const rescheduleVisitMutation = loader('./mutations/rescheduleVisit.graphql');
