import { useMemo, useState } from 'react';

import dayjs from 'dayjs';
import { useQuery } from '@apollo/client';

import { getPetOwnersQuery, getUniqueCountriesQuery, getPetOwnersTotalsQuery } from 'gql';
import useAuth from '../../common/service/auth/useAuth.js';
import { Roles } from '../../common/constants/enums.js';

const useCustomersData = () => {
  const auth = useAuth();
  const [sortBy, setSortBy] = useState({
    registrationDate: 'desc',
    fullName: null,
    country: null,
    cardExpiration: null,
    subscriptionDate: null,
    subscriptionDuration: null,
    spendingAmount: null,
    petAmount: null,
    status: null
  });
  const [name, setName] = useState('');
  const [search, setSearch] = useState('');
  const [vet, setVet] = useState([]);
  const [countries, setCountries] = useState([]);
  const [take, setTake] = useState(10);
  const [page, setPage] = useState(1);
  const [subscriptionType, setSubscriptionType] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [registrationDate, setRegistrationDate] = useState(null);

  const vetAdminUserId = useMemo(() => {
    return auth?.me?.role?.toLowerCase()?.replace('_', '') === Roles.VETS_ADMIN.toLowerCase()
      ? auth?.me?.uid
      : '';
  }, [auth?.me]);

  const { data: customerTotals } = useQuery(getPetOwnersTotalsQuery, {
    variables: {
      record: {
        vetAdminUserId
      }
    }
  });

  const { data, loading } = useQuery(getPetOwnersQuery, {
    variables: {
      record: {
        sortBy,
        search,
        take,
        name,
        vet,
        countries,
        page,
        subscriptionType,
        subscriptionStatus,
        registrationDate,
        vetAdminUserId
      }
    },
    skip: !auth?.me?.role,
    fetchPolicy: 'no-cache'
  });

  const uniqueCountries = useQuery(getUniqueCountriesQuery);

  const totalData = data?.getPetOwners?.total;

  const customersData = data?.getPetOwners?.petOwners?.map(
    ({ uid, user, pets, spendingAmount, subscriptionDuration, subscriptionDate, status }) => {
      return {
        userId: user?.uid,
        registrationDate: dayjs(user?.createdAt).format('DD/MM/YYYY'),
        fullName: `${user?.firstName} ${user.lastName}`,
        country: user?.address?.country?.name,
        contacts: '@',
        cardExpiration: 'dunno',
        subscriptionDate,
        subscriptionDuration: 'dunno',
        spendingAmount,
        // subscriptionDuration,
        petAmount: pets?.length,
        status,
        email: user?.email,
        phone: `${user?.phonePrefix} ${user.phoneNumber}`,
        city: user?.address?.city,
        street: user?.address?.street,
        pets,
        uid
      };
    }
  );
  return {
    customersData,
    customerTotal: totalData,
    loading,
    totalData,
    search,
    setSearch,
    setName,
    name,
    setSortBy,
    vet,
    setVet,
    countries,
    setCountries,
    setTake,
    setPage,
    subscriptionStatus,
    setSubscriptionStatus,
    registrationDate,
    setRegistrationDate,
    subscriptionType,
    customerTotals: customerTotals?.getPetOwnersTotals,
    countriesList: uniqueCountries?.data?.getUniqueCountries,
    setSubscriptionType,
    sortBy
  };
};

export default useCustomersData;
