import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';

import { authAdminMutation } from 'gql';
import useAuth from 'common/service/auth/useAuth';
import { CUSTOMERS, DASHBOARD, USERS, SCHEDULE } from 'constants/routeNames';
import RoleBasedCondition from 'components/role-based-ui/RoleBasedCondition.js';

const useLogin = () => {
  const auth = useAuth();

  const [authAdmin, { data }] = useMutation(authAdminMutation);

  useEffect(() => {
    if (data?.authAdmin && !auth?.me?.uid) {
      auth.setToken(data?.authAdmin?.accessToken);
      auth.setUser(data?.authAdmin?.user);
    }
  }, [data]);

  useEffect(() => {
    if (auth?.me?.uid) {
      window.location = DASHBOARD;
    }
  }, [auth.me]);

  const onSubmit = async (values) => {
    try {
      await authAdmin({
        variables: {
          record: {
            email: values.email,
            password: values.password
          }
        }
      });
    } catch (e) {
      setFieldError('serverError', 'Invalid credentials');
    }
  };

  const { values, handleChange, handleSubmit, setFieldError, resetForm } = useFormik({
    initialValues: { email: '', password: '' },
    onSubmit
  });

  return {
    handleSubmit,
    values,
    handleChange
  };
};

export default useLogin;
