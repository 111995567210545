import React from 'react';
import { Input, Select, Row, Col } from 'antd';

import translateLabel from '../../../utils/translateLabel';

const { Option } = Select;

const RepeatMonthlyOn = ({
  id,
  mode,
  on,
  hasMoreModes,
  handleChange,
  translations,
  disabled,
}) => {
  const isActive = mode === 'on';

  return (
    <Row align="middle">
      <Col span="2">
        {hasMoreModes && (
          <Input
            className="full-width"
            id={id}
            type="radio"
            name="repeat.monthly.mode"
            aria-label="Repeat monthly on"
            value="on"
            checked={isActive}
            onChange={handleChange}
            disabled={disabled}
          />
        )}
      </Col>
      <Col span="6">
        {translateLabel(translations, 'repeat.monthly.on_day')}
      </Col>

      <Col span="6">
        <Select
          className="full-width"
          id={`${id}-day`}
          aria-label="Repeat monthly on a day"
          value={on.day}
          disabled={disabled || !isActive}
          onChange={(value) =>
            handleChange({ target: { value, name: 'repeat.monthly.on.day' } })
          }
        >
          {[...new Array(31)].map((day, i) => (
            <Option key={i} value={i + 1}>
              {i + 1}
            </Option>
          ))}
        </Select>
      </Col>
    </Row>
  );
};

export default RepeatMonthlyOn;
