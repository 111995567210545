import React, {useEffect} from 'react';
import {GoogleMap, Marker, Polygon, useLoadScript} from '@react-google-maps/api';
import mapStyle from 'assets/static/map-features';

const center = {
  lat: 37.7749,
  lng: -122.4194,
};

const grayMarkerIcon = 'https://maps.gstatic.com/mapfiles/ridefinder-images/mm_20_gray.png';
const orangeMarkerIcon = 'https://maps.gstatic.com/mapfiles/ms2/micons/orange-dot.png';
const startPoint = 'https://maps.gstatic.com/mapfiles/ms2/micons/flag.png';
const endPoint = 'https://maps.gstatic.com/mapfiles/ms2/micons/flag.png';


const Map = ({
  currentAppointmentLocation,
  existingAppointments = [],
  workingArea = [],
  startEndPoints = null,
}) => {
  const {isLoaded, loadError} = useLoadScript({
    googleMapsApiKey: 'AIzaSyAY09ilRUEOWPhv21I5P7cOhKQw46ReEB0',
  });

  const [map, setMap] = React.useState(null);

  const onMapLoad = React.useCallback((map) => {
    setMap(map);
  }, []);

  useEffect(() => {
    if (map && currentAppointmentLocation) {
      const bounds = new window.google.maps.LatLngBounds();
      existingAppointments.forEach((appt) => bounds.extend(new window.google.maps.LatLng(appt?.address?.lat, appt?.address?.lng)));
      workingArea.forEach(({lat, lng}) => bounds.extend(new window.google.maps.LatLng(lat, lng)));
      bounds.extend(new window.google.maps.LatLng({lat: currentAppointmentLocation?.lat, lng: currentAppointmentLocation?.lng}));
      if (startEndPoints) {
        bounds.extend(new window.google.maps.LatLng({lat: startEndPoints?.startPoint?.lat, lng: startEndPoints?.startPoint?.lng}));
        bounds.extend(new window.google.maps.LatLng({lat: startEndPoints?.endPoint?.lat, lng: startEndPoints?.endPoint?.lng}));
      }
      map.panTo(bounds.getCenter());
      map.fitBounds(bounds, 50);
    }
  }, [map, existingAppointments, currentAppointmentLocation, startEndPoints]);

  if (loadError) return 'Error loading maps';
  if (!isLoaded) return 'Loading maps...';

  return (
    <GoogleMap
      mapContainerStyle={{
        height: '100%',
        width: '100%',
      }}
      center={center}
      zoom={13}
      onLoad={onMapLoad}
      version='weekly'
      options={{
        // fullscreenControl: false,
        // streetView: false,
        streetViewControl: false,
        mapTypeControl: false,
        gestureHandling: 'cooperative',
        styles: mapStyle,
      }}
    >
      {workingArea && <Polygon paths={[workingArea]} />}
      {existingAppointments?.map((appt, index) => (
        <Marker
          key={index}
          position={{lat: appt?.address?.lat, lng: appt?.address?.lng}}
          icon={grayMarkerIcon}
        />
      ))}

      <Marker
        key={currentAppointmentLocation?.description}
        position={{lat: currentAppointmentLocation?.lat, lng: currentAppointmentLocation?.lng}}
        icon={orangeMarkerIcon}
      />
      {startEndPoints && <>
        <Marker
          key={'startPoint'}
          position={{lat: startEndPoints?.startPoint?.lat, lng: startEndPoints?.startPoint?.lng}}
          icon={startPoint}
        />
        <Marker
          key={'endPoint'}
          position={{lat: startEndPoints?.endPoint?.lat, lng: startEndPoints?.endPoint?.lng}}
          icon={endPoint}
        />
      </>}
    </GoogleMap>
  );
};

export default Map;
