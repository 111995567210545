import { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, message } from 'antd';
import { createUsersFromCsvMutation } from 'gql';
import { useMutation } from '@apollo/client';
import Papa from 'papaparse';
import { serializeUser } from './serializers.js';
import get from 'lodash/get.js';
import validateUsers from './validateUsers.js';

const useUploadUsers = (vets, setLoading) => {
  const [form] = Form.useForm();
  const usersFormValues = Form.useWatch('users', form);
  const integrationId = Form.useWatch('integrationId', form);

  const [errors, setErrors] = useState();
  const [selectedVet, setSelectedVet] = useState(null);
  const [usersList, setUsersList] = useState([]);
  const [defaultCenter, setDefaultCenter] = useState(null);
  const [editingKey, setEditingKey] = useState('');
  const [, setOpenMenuKey] = useState('');
  const [createUsers] = useMutation(createUsersFromCsvMutation);

  useEffect(() => {
    const vet = vets.find((v) => v.uid === selectedVet);
    if (!get(vet, 'vetAdmins[0]')) {
      form.setFieldValue('integrationId', null);
    }
  }, [selectedVet, vets]);

  useEffect(() => {
    const errors = validateUsers(usersFormValues || [], { hasExternalIds: !!integrationId });
    setErrors(errors);
  }, [usersFormValues, integrationId]);

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const parseFile = async (file) => {
    setLoading(true);
    Papa.parse(file, {
      header: true,
      complete: async (results) => {
        if (results?.data?.length) await parseUploadedFileData(results?.data);
        setLoading(false);
      }
    });
  };
  const parseUploadedFileData = async (results) => {
    const newUsers = [];
    let i = 0;
    for (const data of results) {
      if (i === 50) {
        console.log('10 sec throttle (every 50)');
        await sleep(10000);
        i = 0;
      }

      const user = await serializeUser(
        data,
        vets?.find((vet) => vet?.uid === selectedVet)
      );

      if (!defaultCenter && user?.address?.lat && user?.address?.lng) {
        setDefaultCenter({
          lat: user?.address?.lat,
          lng: user?.address?.lng
        });
      }

      newUsers.push(user);
      i++;
    }
    if (!defaultCenter) {
      setDefaultCenter({
        lat: 32.5941911,
        lng: -80.7667111
      });
    }
    setUsersList(newUsers);
    form.setFieldsValue({ users: newUsers });
    return newUsers;
  };
  const handleErrors = (errors) => {
    if (errors?.length) {
      const { users } = form.getFieldsValue(['users']);
      const filteredUsers = users.filter((user) =>
        errors.find((error) => error?.email === user?.email)
      );

      form.setFieldsValue({ users: filteredUsers });
      message.error('Please fix invalid data and try again');
    } else {
      message.success('Users are saved successfully');
      form.setFieldsValue({ users: [] });
      setUsersList([]);
    }
  };
  const onSubmit = async (values) => {
    values = {
      ...values,
      users: values?.users?.map((user) => ({
        ...user,
        address: {
          ...user?.address,
          floor: parseInt(user?.address?.floor) || null,
          houseNumber: parseInt(user?.address?.houseNumber) || null
        }
      }))
    };

    try {
      const { data } = await createUsers({
        variables: {
          record: values
        }
      });
      handleErrors(data?.createUsersFromCsv?.errors);
    } catch (e) {
      message.error('serverError');
    }
  };
  const actions = useMemo(
    () => ({
      editRow: (record) => setEditingKey(record),
      deleteRow: (index) => {
        const { users } = form.getFieldsValue(['users']);
        const newValues = users.filter((u, i) => i !== index);
        form.setFieldsValue({
          users: newValues
        });
        setUsersList(newValues);
        setOpenMenuKey('');
        setEditingKey('');
      },
      cancelRow: () => {
        setEditingKey('');
        setOpenMenuKey('');
      },
      setFieldValue: form.setFieldValue
    }),
    []
  );
  const onAddressChange = useCallback((value, index) => {
    const { users } = form.getFieldsValue(['users']);
    const newValues = users.map((v, i) => {
      if (i === index) v.address.description = value;
      return v;
    });
    form.setFieldsValue({
      users: newValues
    });
  }, []);
  const onAddressSelect = useCallback((value, index) => {
    const { users } = form.getFieldsValue(['users']);
    const newValues = users.map((v, i) => {
      if (i === index) {
        v.address = {
          ...v.address,
          ...value
        };
      }
      return v;
    });
    form.setFieldsValue({
      users: newValues
    });
    setUsersList(newValues);
  }, []);

  return {
    integrationId,
    errors,
    editingKey,
    serializeUser,
    onAddressChange,
    onAddressSelect,
    onSubmit,
    parseFile,
    form,
    selectedVetId: selectedVet,
    setSelectedVet,
    usersList,
    defaultCenter,
    actions
  };
};

export default useUploadUsers;
