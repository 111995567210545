import React from 'react';
import { Input, Row, Col } from 'antd';
import numericalFieldHandler from '../../utils/numericalFieldHandler';
import translateLabel from '../../utils/translateLabel';

const EndAfter = ({ id, after, handleChange, translations }) => (
  <div className="mt-20">
    <Row align="middle">
      <Col span="6">
        <Input
          className="full-width"
          id={id}
          name="end.after"
          aria-label="End after"
          className="form-control"
          value={after}
          onChange={numericalFieldHandler(handleChange)}
        />
      </Col>
      <Col span="9">
        <span className="ml-20">
          {translateLabel(translations, 'end.executions')}
        </span>
      </Col>
    </Row>
  </div>
);

export default EndAfter;
