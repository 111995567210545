import React, {useState, useCallback} from 'react';
import css from './Wrapper.module.css';
import cls from 'classnames';
import Button from '../../../library/Button/Button';
import text from '../../../styles/text.module.css';
import {ReactComponent as CloseIcon} from '../../../assets/icons/close-gray.svg';
import {Reschedule} from './Reschedule';
import {Recommender} from './Recommender';
import {useFilters} from './useFilters';
import {useSelectedTimeslot} from './useSelectedTimeslot.js';

export const RescheduleWrapper = (props) => {
  const {
    filters: parentFilters,
    timeslotActions: parentTimeslotActions,
    theme,
    displayFooter = true,
    displayHeader = true,
    onChangeView, selectedTimeslot, defaultTimeslot, appointment, currentAddress, vetId, close} = props;
  const [isReschedule, _setIsReschedule] = useState(true);
  const setIsReschedule = useCallback((value) => {
    _setIsReschedule(value);
    onChangeView && onChangeView(value);
  }, []);

  const filters = useFilters({vetId});
  const timeslotActions = useSelectedTimeslot({
    defaultTimeslot,
  });

  return <div className={cls(css.modal, {[css.themeLight]: theme === 'light'})}>
    {displayHeader ? <div className={cls(css.header, text.s16w6l18)}>
      <span>Reschedule appointment</span>
      <CloseIcon onClick={() => close()} />
    </div> : null}
    <div className={css.row}>
      <Button
        className={css.btnLeft}
        type={isReschedule ? 'secondary' : 'default'}
        colorScheme="blue"
        onClick={() => setIsReschedule(true)}>Calendar view</Button>
      <Button
        className={css.btnRight}
        type={!isReschedule ? 'secondary' : 'default'}
        colorScheme="blue"
        onClick={() => setIsReschedule(false)}>Smart scheduling view</Button>
    </div>
    {isReschedule ? <Reschedule
      theme={theme}
      displayFooter={displayFooter}
      selectedTimeslot={selectedTimeslot}
      currentAddress={currentAddress}
      appointment={appointment}
      vetId={vetId}
      close={close}
      timeslotActions={parentTimeslotActions || timeslotActions}
      filters={parentFilters || filters}
    /> : <Recommender
      theme={theme}
      displayFooter={displayFooter}
      selectedTimeslot={selectedTimeslot}
      currentAddress={currentAddress}
      appointment={appointment}
      vetId={vetId}
      close={close}
      timeslotActions={parentTimeslotActions || timeslotActions}
      filters={parentFilters || filters}
    />}
  </div>;
};
