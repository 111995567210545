import {notification} from 'antd';
import {DASHBOARD} from 'constants/routeNames';
import {PAGE_OPTIONS, PAGE_SIZE} from 'constants/general';
import dayjs from 'dayjs';
import moment from 'moment';

export const messages = {
  t: {},
};
export const showInfo = (title, description = null) => {
  return notification['info']({
    message: title,
    description: description,
  });
};
export const showSuccess = (title, description = null) => {
  return notification['success']({
    message: title,
    description: description,
  });
};
export const showError = (title, description = null) => {
  return notification['error']({
    message: title,
    description: description,
  });
};
export const unexpectedErrorHandler = () => {
  return notification['error']({
    message: messages.t('general.try_later'),
  });
};
export const orderConverter = (orderType) => {
  if (orderType === 'ascend') {
    return 'asc';
  } else if (orderType === 'descend') {
    return 'desc';
  } else return null;
};
export const filterOption = (input, option) => {
  return option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};
export const authCheck = (store, loadUserData = false) => {
  // const storeState = store.getState();
  // const accessToken = localStorage.getItem('access_token');
  // const refreshToken = localStorage.getItem('refresh_token');
  // if (accessToken) {
  //   const decodedToken = jwtDecode(accessToken);
  //   if (decodedToken.exp * 1000 < Date.now() + 300000) {
  //     if (refreshToken) {
  //       if (!storeState.user.refreshing) {
  //         store.dispatch(refreshTokenAction(loadUserData));
  //       }
  //     } else {
  //       showInfo(
  //         messages.t('login.session_expired_title'),
  //         messages.t('login.session_expired_description')
  //       );
  //       store.dispatch(logoutUser());
  //     }
  //   } else {
  //     axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  //     if (loadUserData) {
  //       store.dispatch({ type: LOADING_UI });
  //       store.dispatch(getUserData());
  //     }
  //   }
  // }
};
export const dashboardLink = (key) => `${DASHBOARD}${key}`;
export const convertPagination = (data) => {
  data.page = data.pagination.current;
  data.per_page = data.pagination.pageSize;
  delete data.pagination;
  return data;
};
export const updateAntdTableFiltersUrl = (params, history) => {
  let search = '?';
  if (
    params.pagination &&
    params.pagination.current &&
    params.pagination.current !== 1
  ) {
    search += `page=${params.pagination.current}`;
  }
  for (const key in params) {
    if (key !== 'pagination') {
      // noinspection JSUnfilteredForInLoop
      if (params[key]) {
        if (search.length > 1) search += '&';
        // noinspection JSUnfilteredForInLoop
        search += `${key}=${params[key]}`;
      }
    }
  }
  if (!(!history.location.search && search === '?')) {
    history.push({
      search: search,
    });
  }
};
export const stripLeadingSlash = (s) => {
  return s.replace(/^\/+/g, '');
};
/**
 *
 * check if given user is of role "user"
 *
 * @param user
 * @return {boolean}
 */
export const isUser = (user) => {
  return user?.role === 'user';
};
/**
 *
 * check if given user is of role "manager"
 *
 * @param user
 * @return {boolean}
 */
export const isManager = (user) => {
  return user?.role === 'manager';
};
/**
 *
 * check if given user is of role "admin"
 *
 * @param user
 * @return {boolean}
 */
export const isAdmin = (user) => {
  return user?.role === 'admin';
};
/**
 *
 * check if given user is of role "admin" or "manager"
 *
 * @param user
 * @return {boolean}
 */
export const isManagerOrAdmin = (user) => {
  return user?.role === 'admin' || user?.role === 'manager';
};
/**
 *
 * Get initial pagination params for tables
 *
 * @return {{current: number, pageSizeOptions: number[], pageSize: number}}
 */
export const getInitialTablePagination = () => {
  return {current: 1, pageSize: PAGE_SIZE, pageSizeOptions: PAGE_OPTIONS};
};
/**
 *
 * Remove empty keys from object
 *
 * @param obj
 */
export const cleanObject = (obj) => {
  for (const propName in obj) {
    // noinspection JSUnfilteredForInLoop
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === ''
    ) {
      // noinspection JSUnfilteredForInLoop
      delete obj[propName];
    }
  }
};
/**
 *
 * Check if authenticated user can edit target user
 *
 * @param user
 * @param target
 * @return {boolean}
 */
export const checkValidUserEdit = (user, target) => {
  if (isAdmin(user)) {
    return isUser(target) || isManager(target);
  } else if (isManager(user)) {
    return isUser(target);
  }
  return false;
};
/**
 *
 * Check if password is strong enough. Password should contain at least 1 uppercase letter,
 * at least one lowercase letter and at least 1 number.
 *
 * @param password
 * @return {boolean}
 */
export const testStrongPassword = (password) => {
  return new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])').test(password);
};

export const stringToColour = function(str) {
  let hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (var i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xFF;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
};

export const parseTimeSlotHour = (hour) => {
  let res = '';
  try {
    if (hour != null) {
      res = dayjs()
          .set('hour', Number(hour?.split(':')[0]))
          .set('minute', Number(hour?.split(':')[1]))
          .format('h:mm a')
          .toUpperCase();
    }
  } catch {
    return res;
  }
  return res;
};

export const getAge = (birthDate) => {
  const now = moment();
  const years = now.diff(birthDate, 'years');
  const months = now.diff(birthDate, 'months') % 12;
  let res = '';

  if (years != 0) res = res + years + 'y ';
  if (months != 0) res = res + months + 'm';

  return res;
};
