import React from 'react';
import cls from 'classnames';
import css from './Providers.module.css';
import text from '../../../../styles/text.module.css';
import Checkbox from '../../../../library/Checkbox/Checkbox';
import {Vet} from '../Vet';

export const Providers = ({
  displayVets,
  isLoading,
  vets,
  selectedVets,
  setSelectedVets,
}) => <>
  <div className={cls(css.providers, {[css.disabledVet]: !displayVets || isLoading})}
    onClick={() => {
      if (!displayVets || isLoading) return;
      if (selectedVets.length === vets.length) {
        setSelectedVets([]);
      } else {
        setSelectedVets(vets.map((v) => v.uid));
      }
    }}>
    <span className={cls(text.s14w4l24)}>Selected providers</span>
    <Checkbox
      disabled={!displayVets || isLoading}
      indeterminate={selectedVets.length !== vets.length && selectedVets.length > 1}
      checked={selectedVets.length === vets.length}/>
  </div>
  {vets?.map((vet) => {
    const selected = selectedVets.some((u) => u === vet.uid);
    return <div key={vet.uid}
      className={cls('flex-row jc-sb', css.vetCheckbox, {[css.disabledVet]: !displayVets})}
      onClick={() => {
        if (!displayVets || isLoading) return;
        if (selected) {
          setSelectedVets((prev) => prev.filter((v) => v !== vet.uid));
        } else {
          setSelectedVets((prev) => [...prev, vet.uid]);
        }
      }
      }>
      <Vet vet={vet} />
      <Checkbox
        checked={selected}
        disabled={!displayVets || isLoading}
      />
    </div>;
  })}
</>;
