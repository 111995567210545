import { useQuery } from '@apollo/client';
import { getUserConcernsByUserIdQuery } from 'gql';

const useUserConcernsByUserId = (userId) => {
  const { data: concerns, loading } = useQuery(getUserConcernsByUserIdQuery, {
    variables: {
      id: userId
    }
  });

  return { concerns: concerns?.getUserConcernsByUserId || [], loading };
};

export default useUserConcernsByUserId;
