import React from 'react';

import { Select, Form } from 'antd';
import { useQuery } from '@apollo/client';

import { getTimezonesQuery } from 'gql';
import { filterOption, messages } from 'service/utils';

import './styles.css';

const TimezoneSelectInput = ({ value, setFieldValue, isError }) => {
  const { Option } = Select;
  const { data } = useQuery(getTimezonesQuery);
  const onChange = (value) => {
    setFieldValue('timeZoneId', value);
  };
  console.log({value})
  return (
    <Form.Item label="Timezone:" labelCol={{ span: 24 }}>
      <div className={isError && 'error_selector'}>
        <Select
          showSearch
          aria-required
          value={value}
          placeholder={messages.t('vet.select_timezone')}
          optionFilterProp="children"
          onChange={onChange}
          filterOption={(input, option) => filterOption(input, option)}
        >
          {data?.getTimezones.map(({ uid, name }) => {
            return (
              <Option key={uid} value={uid}>
                {name}
              </Option>
            );
          })}
        </Select>
      </div>
    </Form.Item>
  );
};

export default TimezoneSelectInput;
