import React from 'react';
import { Input, Row, Col } from 'antd';
import numericalFieldHandler from '../../../utils/numericalFieldHandler';
import translateLabel from '../../../utils/translateLabel';

const RepeatDaily = ({
  id,
  daily: { interval },
  handleChange,
  translations,
  disabled,
}) => (
  <Row align="middle" className="mt-20">
    <Col span="6">{translateLabel(translations, 'repeat.daily.every')}</Col>
    <Col span="12">
      <Input
        id={`${id}-interval`}
        name="repeat.daily.interval"
        aria-label="Repeat daily interval"
        className="form-control"
        value={interval}
        type="number"
        onChange={numericalFieldHandler(handleChange)}
        disabled={disabled}
      />
    </Col>
    <Col span="6">
      <span className="ml-20">
        {translateLabel(translations, 'repeat.daily.days')}
      </span>
    </Col>
  </Row>
);

export default RepeatDaily;
