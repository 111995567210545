import React from 'react';
import {
  USERS,
  CUSTOMERS,
  VETS,
  SETTINGS,
  IMPORT_CLIENTS,
  SCHEDULE,
  IMPORT_APPOINTMENTS
} from 'constants/routeNames';
import {
  FieldTimeOutlined,
  LogoutOutlined,
  UsergroupAddOutlined,
  DashboardOutlined,
  CalendarOutlined
} from '@ant-design/icons';
import { dashboardLink, messages } from 'service/utils';
import { Button, Menu, Popconfirm } from 'antd';
import { Permissions } from '../../../constants/permissions.js';
import RoleBasedUi from '../../role-based-ui/RoleBasedUi.js';
import RoleBasedCondition from '../../role-based-ui/RoleBasedCondition.js';
import styles from './DashboardMenu.module.css';

const { SubMenu } = Menu;

const DashboardMenu = ({ history, user, logoutUser, collapsed, location }) => {
  const navigate = (item) => {
    history.push(item.key);
  };

  const historyKeys = {
    '/dashboard/users/customers': 'users',
    '/dashboard/users/vets': 'users'
  };
  let defaultSelectedKeys = [historyKeys[history.location.pathname]];

  return (
    <>
      <Menu
        className="menu"
        theme="dark"
        mode="inline"
        defaultSelectedKeys={defaultSelectedKeys}
        selectedKeys={[location?.pathname]}
        defaultOpenKeys={defaultSelectedKeys}
        onClick={navigate}>
        {/*{RoleBasedCondition(Permissions.VIEW_DASHBOARD) && (*/}
        {/*  <Menu.Item key={dashboardLink('/')} icon={<DashboardOutlined/>}>*/}
        {/*    {messages.t('trips.dashboard')}*/}
        {/*  </Menu.Item>*/}
        {/*)}*/}
        {RoleBasedCondition(Permissions.VIEW_UPLOAD_USERS) && (
          <Menu.Item key={dashboardLink(IMPORT_CLIENTS)} icon={<UsergroupAddOutlined />}>
            {messages.t('menu.import_users')}
          </Menu.Item>
        )}
        {RoleBasedCondition(Permissions.VIEW_UPLOAD_APPOINTMENTS) && (
          <Menu.Item key={dashboardLink(IMPORT_APPOINTMENTS)} icon={<UsergroupAddOutlined />}>
            {messages.t('menu.import_appointments')}
          </Menu.Item>
        )}
        {RoleBasedCondition(Permissions.VIEW_MENU_ITEM_SCHEDULE) && (
          <Menu.Item key={dashboardLink(SCHEDULE)} icon={<CalendarOutlined />}>
            {messages.t('menu.schedule')}
          </Menu.Item>
        )}
        <SubMenu
          icon={<FieldTimeOutlined />}
          title={messages.t('users.management')}
          key="users"
          disabled={false}>
          <Menu.Item key={dashboardLink(USERS + CUSTOMERS)}>
            {messages.t('menu.user_managment.customers')}
          </Menu.Item>
          <Menu.Item key={dashboardLink(USERS + VETS)}>
            {messages.t('menu.user_managment.vets')}
          </Menu.Item>
        </SubMenu>
        {/*{RoleBasedCondition(Permissions.VIEW_REPORTS) && (*/}
        {/*  <Menu.Item key={'reports'} icon={<FileImageOutlined/>}>*/}
        {/*    {messages.t('users.reports')}*/}
        {/*  </Menu.Item>*/}
        {/*)}*/}

        {/*{RoleBasedCondition(Permissions.VIEW_SETTINGS) && (*/}
        {/*  <Menu.Item key={dashboardLink(SETTINGS)} icon={<SettingOutlined/>}>*/}
        {/*    {messages.t('users.settings')}*/}
        {/*  </Menu.Item>*/}
        {/*)}*/}
      </Menu>
      <div className={styles.logoutButton}>
        <Popconfirm
          title={messages.t('general.confirm')}
          onConfirm={() => logoutUser()}
          okText={messages.t('general.yes')}
          cancelText={messages.t('general.no')}>
          <Button danger icon={<LogoutOutlined />}>
            {!collapsed ? messages.t('menu.logout') : ''}
          </Button>
        </Popconfirm>
      </div>
    </>
  );
};

export default DashboardMenu;
