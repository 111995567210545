import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import { Input, Row, Form, Col, Button, Select, Table } from 'antd';
import get from 'lodash/get';
import AddressAutocomplete from 'components/address-autocomplete/AddressAutocomplete.jsx';
import locationIconForMap from 'assets/icons/location-filled.svg';
import locationErrorIconForMap from 'assets/icons/location-red.svg';
import { scrollParentToChild } from '../../common/utils/helpers.js';
import { messages } from '../../service/utils.js';
import './style.css';
import AddressChecklist from '../../components/address-autocomplete/AddressChecklist.jsx';
import columns from './columns.js';

const { google } = window;
const UploadUsers = (props) => {
  const {
    integrationId,
    errors,
    editingKey,
    form,
    onSubmit,
    parseFile,
    usersList,
    selectedVetId,
    setSelectedVet,
    defaultCenter,
    onAddressSelect,
    onAddressChange,
    actions,
    vetsData,
    vets,
    petTypes
  } = props;

  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);
  const [mapRef, setMapRef] = useState(null);

  const fitBounds = () => {
    const bounds = new window.google.maps.LatLngBounds();
    usersList.map((item) => {
      bounds.extend(item?.address);
      return item;
    });
    mapRef.fitBounds(bounds);
  };

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  useEffect(() => {
    if (mapRef && usersList.length) {
      fitBounds();
    }
  }, [mapRef, usersList]);

  const addressValidation = useCallback((address) => {
    const keys = [];
    if (!address?.street) keys.push('street');
    if (!address?.state) keys.push('state');
    if (address?.countryCode === 'US' && !address?.zipCode) keys.push(messages.t('vet.zipcode'));
    if (!address?.city) keys.push('city');
    return keys;
  }, []);

  const usersTableWrapper = document.getElementById('usersTableWrapper');

  const integrations = useMemo(() => {
    const vet = vets.find((v) => v.uid === selectedVetId);
    const vetAdmins = get(vet, 'vetAdmins', []);
    const integrationsAll = [];
    vetAdmins.forEach((vAdmin) => integrationsAll.push(...get(vAdmin, 'integrations', [])));
    return integrationsAll;
  }, [vets, selectedVetId]);

  return (
    <Form name="dynamic_form_item" onFinish={onSubmit} form={form}>
      <Row>
        {form.getFieldsValue(['vetId']) && (
          <Col xs={6}>
            <Input
              type="file"
              accept="csv"
              onChange={(e) => parseFile(e.target.files[0])}
              style={{ maxWidth: 300, marginBottom: 20 }}
            />
          </Col>
        )}
        <Col xs={3} style={{ marginLeft: 20 }}>
          <Form.Item name="vetId" rules={[{ required: true, message: 'Choose vet' }]}>
            <Select
              placeholder="Select Vet"
              onChange={(value) => {
                form.setFieldValue('vetId', value);
                setSelectedVet(value);
              }}
            >
              {vetsData?.map((vet) => (
                <Select.Option key={vet.id} value={vet.id}>
                  {vet.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={3} style={{ marginLeft: 20 }}>
          <Form.Item name="integrationId">
            <Select
              placeholder="Select Integration"
              onChange={(value) => {
                form.setFieldValue('integrationId', value);
              }}
            >
              {integrations
                .filter((int) => ['ENABLED', 'NEW'].includes(int.status))
                .map((int) => (
                  <Select.Option key={int.uid} value={int.uid}>
                    {int.pim.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Form.List name="users" rules={[{ required: true }]}>
        {(users, { add, remove }) => {
          return (
            <>
              {Boolean(usersList?.length) && (
                <GoogleMap
                  mapContainerClassName="App-map"
                  center={defaultCenter}
                  onLoad={(map) => setMapRef(map)}
                  onClick={() => setActiveMarker(null)}
                  mapContainerStyle={{
                    height: 300
                  }}
                  zoom={13}
                  version="weekly"
                  options={{
                    fullscreenControl: false,
                    streetView: false,
                    streetViewControl: false,
                    mapTypeControl: false,
                    gestureHandling: 'cooperative'
                    // scrollwheel: false,
                    // zoomControl: false
                  }}
                >
                  {usersList?.map((u, i) => {
                    return (
                      <Marker
                        key={i}
                        onClick={() => {
                          setSelectedRowIndex(i);
                          handleActiveMarker(i);
                          scrollParentToChild(
                            usersTableWrapper,
                            document.querySelector('.table-row-selected')
                          );
                        }}
                        position={{
                          lat: u?.address?.lat || 0.0,
                          lng: u?.address?.lng || 0.0
                        }}
                        icon={{
                          url: addressValidation(u?.address)?.length
                            ? locationErrorIconForMap
                            : locationIconForMap,
                          scaledSize: new google.maps.Size(37, 37)
                        }}
                      >
                        {activeMarker === i ? (
                          <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                            <>
                              <h4>
                                {u?.firstName} {u?.lastName}
                              </h4>
                              <h4>{u?.address?.description}</h4>
                              {addressValidation(u?.address)?.length && (
                                <>
                                  <h4>Missing fields : </h4>
                                  {addressValidation(u?.address)?.map((a) => (
                                    <p key={a}>{a}</p>
                                  ))}
                                </>
                              )}
                            </>
                          </InfoWindow>
                        ) : null}
                      </Marker>
                    );
                  })}
                </GoogleMap>
              )}

              <div id="usersTableWrapper" className="users-table-wrapper">
                <Table
                  pagination={false}
                  bordered
                  dataSource={users}
                  rowClassName={(record, index) =>
                    index === selectedRowIndex ? 'table-row-selected' : ''
                  }
                >
                  {columns.map((column, key) => {
                    return (
                      <Table.Column
                        key={column.key}
                        dataIndex={column.dataIndex}
                        title={column.title}
                        render={(value, row, index) => {
                          if (column?.render) {
                            const key = ['users', index, column.dataIndex];
                            return column.render(form.getFieldValue(key), index, actions, {
                              editingKey,
                              petTypes,
                              key,
                              errors,
                              integrationId
                            });
                          }

                          if (column.address) {
                            const address = form.getFieldValue(['users', index, column.dataIndex]);
                            return (
                              <div style={{ width: 300 }}>
                                <Form.Item
                                  name={[index, 'address', 'description']}
                                  rules={[
                                    {
                                      required: true,
                                      type: 'string',
                                      whitespace: true,
                                      message: false
                                    }
                                  ]}
                                >
                                  {editingKey === index ? (
                                    <AddressAutocomplete
                                      placeholder={messages.t('users.address')}
                                      onSelect={(value) => onAddressSelect(value, index)}
                                      onChange={(value) => onAddressChange(value, index)}
                                      address={address}
                                      formikAddress={address}
                                      setIsAddressValid={() => {}}
                                      isChecklistNeeded
                                      // isError={addressValidation(address)}
                                    />
                                  ) : (
                                    <Input
                                      className="input-like-text"
                                      readOnly={true}
                                      suffix={
                                        <AddressChecklist
                                          formikAddress={address}
                                          setIsAddressValid={() => {}}
                                        />
                                      }
                                    />
                                  )}
                                </Form.Item>
                              </div>
                            );
                          }

                          const rules =
                            column.dataIndex === 'externalId'
                              ? [{ ...column.rules[0], required: !!integrationId }]
                              : column.rules;
                          return (
                            <Form.Item name={[index, ...column.name]} rules={rules}>
                              {editingKey === index ? (
                                <Input type={column.type || 'text'} placeholder={column.title} />
                              ) : (
                                <Input className="input-like-text" readOnly={true} />
                              )}
                            </Form.Item>
                          );
                        }}
                      />
                    );
                  })}
                </Table>
              </div>
            </>
          );
        }}
      </Form.List>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default UploadUsers;
