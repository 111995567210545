import React from 'react';

import * as S from './components';

const LoaderWrapperOrangeLoader = ({}) => {
  return (
    <S.SpinnerWraper>
      <S.Spinner />
    </S.SpinnerWraper>
  ) 
};

export default LoaderWrapperOrangeLoader;
