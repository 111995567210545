import React, { useEffect, useState } from 'react';
import styles from './DashboardLayout.module.css';
import 'antd/dist/antd.css';
import { Layout, Spin } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
// import Logo from '../../components/ui/Logo/Logo';
import { ReactComponent as Logo } from 'common/assets/icons/logo-white.svg';
import { ReactComponent as LogoMini } from 'common/assets/icons/logo-mini-yellow.svg';
import DashboardMenu from '../../components/ui/DashboardMenu/DashboardMenu';
import { MENU_BREAK_POINT } from 'constants/general';
import { LOGIN } from 'constants/routeNames';

const { Header, Sider, Content } = Layout;

const DashboardLayout = ({ children, history, UI }) => {
  const [collapsed, setCollapsed] = useState(window.innerWidth < MENU_BREAK_POINT);
  // const [polygons, setPolygons] = useState([]);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const logoutUser = () => {
    localStorage.clear();
    history.replace(LOGIN);
  };

  useEffect(() => {
    window.addEventListener('resize', () => {
      setCollapsed(window.innerWidth < MENU_BREAK_POINT);
    });
  }, []);

  return (
    <Layout className={styles.mainContainer}>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        {!collapsed && (
          <Logo
            style={{ paddingLeft: '24px', paddingTop: '10px', paddingBottom: '20px', width: 150 }}
            align={'center'}
          />
        )}
        {collapsed && (
          <LogoMini
            style={{ paddingLeft: '18px', paddingTop: '20px', paddingBottom: '20px', width: 64 }}
            align={'center'}
          />
        )}
        <DashboardMenu history={history} collapsed={collapsed} logoutUser={logoutUser} />
      </Sider>
      <Layout className={styles.siteLayout}>
        <Header className={styles.siteLayoutBackground} style={{ padding: 0 }}>
          {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: styles.trigger,
            onClick: toggle
          })}
        </Header>
        <Content
          className={styles.siteLayoutBackground}
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280
          }}
        >
          {/* <Map
            googleMapURL="https://maps.googleapis.com/maps/api/js?v=weekly&libraries=places,geometry,drawing&key=AIzaSyCHAKyZpWti2bO4tWakrkndsZLmo7FBr9c"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `400px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            setPolygons={setPolygons}
          /> */}
          <Spin spinning={false}>{children}</Spin>
        </Content>
      </Layout>
    </Layout>
  );
};

export default DashboardLayout;
