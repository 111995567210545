import React from 'react';

import { useMutation } from '@apollo/react-hooks';
import { removeSubscriptionMutation } from 'gql';

const useRemoveSubscription = (refetchPetOwner) => {
  const [removeSubscriptionRequest] = useMutation(removeSubscriptionMutation);

  const removeSubscription = async (uid) => {
    try {
      await removeSubscriptionRequest({
        variables: {
          record: {
            subscriptionId: uid
          }
        }
      });
      refetchPetOwner();
    } catch (err) {}
  };

  return { removeSubscription };
};

export default useRemoveSubscription;
