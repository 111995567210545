import React, { useState, useMemo } from 'react';
import { Input, Row, Form, Col, Button, Select, Table, Checkbox } from 'antd';
import './style.css';
import columns from './columns.js';

const UploadAppointments = (props) => {
  const {
    errors,
    editingKey,
    form,
    onSubmit,
    parseFile,
    appointmentsList,
    actions,
    petTypes,
    vetAdmins,
    vetAdminsData,
    selectedVetAdminId,
    setSelectedVetAdminId,
    uploading,
    concerns,
    setSelectedVetAdminUserId
  } = props;

  const [selectedRowIndex, setSelectedRowIndex] = useState(null);

  const appointmentsTableWrapper = document.getElementById('appointmentsTableWrapper');

  const integrations = useMemo(() => {
    const vetAdmin = vetAdmins.find((v) => v.uid === selectedVetAdminId);
    return vetAdmin?.integrations;
  }, [vetAdmins, selectedVetAdminId]);

  return (
    <Form name="dynamic_form_item" onFinish={onSubmit} form={form}>
      <Row>
        <Col xs={3} style={{ marginLeft: 20 }}>
          <Form.Item name="vetAdminId">
            <Select
              placeholder="Select Clinic"
              onChange={(value) => {
                form.setFieldValue('vetAdminId', value);
                setSelectedVetAdminId(value);

                const vetAdmin = vetAdmins.find((v) => v.uid === value);

                setSelectedVetAdminUserId(vetAdmin?.user?.uid);
              }}>
              {vetAdminsData.map((vetAdmin) => (
                <Select.Option key={vetAdmin.id} value={vetAdmin.id}>
                  {vetAdmin.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={3} style={{ marginLeft: 20 }}>
          <Form.Item name="travelTimeIncluded" valuePropName="checked">
            <Checkbox>Travel Time Included</Checkbox>
          </Form.Item>
        </Col>
        {form.getFieldValue('vetAdminId') ? (
          <Col xs={3} style={{ marginLeft: 20 }}>
            <Form.Item name="supportedServices">
              <Select
                mode="multiple"
                placeholder="Select Supported Services"
                onChange={(value) => {
                  form.setFieldValue('supportedServices', value);
                }}>
                {(concerns ? concerns : []).map((concern) => (
                  <Select.Option key={concern.uid} value={concern.uid}>
                    {concern.itemValue ?? concern.concern.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        ) : null}
        {integrations ? (
          <Col xs={3} style={{ marginLeft: 20 }}>
            <Form.Item name="integrationId">
              <Select
                placeholder="Select PIM"
                onChange={(value) => {
                  form.setFieldValue('integrationId', value);
                }}>
                {integrations.map((integration) => (
                  <Select.Option key={integration.uid} value={integration.uid}>
                    {integration.pim.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        ) : null}
        {form.getFieldValue('integrationId') ? (
          <Col xs={6} style={{ marginLeft: 20 }}>
            <Input
              type="file"
              accept="csv"
              onChange={(e) => parseFile(e.target.files[0])}
              style={{ maxWidth: 300, marginBottom: 20 }}
            />
          </Col>
        ) : null}
      </Row>
      <Form.List name="appointments" rules={[{ required: true }]}>
        {(appointments, { add, remove }) => {
          return (
            <>
              <div id="appointmentsTableWrapper" className="appointments-table-wrapper">
                <Table
                  pagination={false}
                  bordered
                  dataSource={appointments}
                  rowClassName={(record, index) =>
                    index === selectedRowIndex ? 'table-row-selected' : ''
                  }>
                  {columns.map((column, key) => {
                    return (
                      <Table.Column
                        key={column.key}
                        dataIndex={column.dataIndex}
                        title={column.title}
                        render={(value, row, index) => {
                          if (column?.render) {
                            const key = ['appointments', index, column.dataIndex];
                            return column.render(form.getFieldValue(key), index, actions, {
                              editingKey,
                              petTypes,
                              key,
                              errors
                            });
                          }

                          return (
                            <Form.Item name={[index, ...column.name]} rules={column.rules}>
                              {editingKey === index ? (
                                <Input type={column.type || 'text'} placeholder={column.title} />
                              ) : (
                                <Input className="input-like-text" readOnly={true} />
                              )}
                            </Form.Item>
                          );
                        }}
                      />
                    );
                  })}
                </Table>
              </div>
            </>
          );
        }}
      </Form.List>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={uploading}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default UploadAppointments;
