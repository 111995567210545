import set from 'lodash/set';

const validateAppointments = (appointments, context = {}) => {
  const { hasExternalIds } = context;
  const errors = {};
  if (hasExternalIds) {
    appointments.forEach((appointment, appointmentIndex) => {
      if (!appointment.pet.externalId) {
        set(
          errors,
          ['appointments', appointmentIndex, 'pet', 'externalId'],
          'Pet without External Id will be ignored'
        );
      }
    });
  }
  return errors;
};

export default validateAppointments;
