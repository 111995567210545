import React from 'react';
import {messages} from 'service/utils';
import EmptyStateIcon from 'assets/icons/clients-empty-state.svg';

const ClientsEmptyState = () => {
  return (
    <div className="clients-empty-state">
      <h3>{messages.t('recommender.new_appointment.empty_state_title')}</h3>
      <p>{messages.t('recommender.new_appointment.empty_state_description')}</p>
      <img src={EmptyStateIcon} alt='' />
    </div>
  );
};

export default ClientsEmptyState;
