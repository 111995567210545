import React, { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { RRule } from 'rrule';
import * as moment from 'moment';

import { timeSlotsToDateObject } from 'common/utils/helpers';

import { Calendar, Select, Input, Table } from 'antd';

import './styles.css';

import useColumns from '../add-vet/useColumns';

const { Option } = Select;

const VetsCalendar = ({
  fullScreen,
  timeSlots,
  fullTimeSlots,
  vetId,
  handleSaveTimeSlot,
  handleCreateNewTimeSlot
}) => {
  let startDate = new Date();
  startDate.setUTCHours(0, 0, 0, 0);

  

  const { workingAreaColumns, workingAreas, setWorkingAreas } = useColumns();

  const [selectedDateTimeSlots, setSelectedDateTimeSlots] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState('New');
  const [workingHours, setWorkingHours] = useState({
    from: '',
    to: ''
  });
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [timeSlot, setTimeSlot] = useState(null);

  const dates = useMemo(() => {
    return timeSlotsToDateObject(
      timeSlots,
      null,
      fullTimeSlots,
      false,
      startDate,
    )
  }, []);
  
  const handleSelectDate = (date) => {
    const slots = dates[dayjs(date).format('YYYY-MM-DD')];
    setSelectedDateTimeSlots(slots || []);
    setSelectedDate(dayjs(date).format('YYYY-MM-DD'));

    if (slots) {
      const slot = slots[0];
      setTimeSlot(slot);
      setSelectedTimeSlot(slot.uid);
      setWorkingHours({
        from: slot.startTime,
        to: slot.endTime
      });
      if (slot.workingAreas && !!slot.workingAreas.length) {
        setWorkingAreas([
          { comment: '' },
          ...slot.workingAreas.map((area) => area?.address)
        ]);
      } else {
        setWorkingAreas([{ comment: '' }]);
      }
    } else {
      setSelectedTimeSlot('New');
      setWorkingHours({
        from: '',
        to: ''
      });
      setWorkingAreas([{ comment: '' }]);
      setTimeSlot(null);
    }
  };

  const handleChangeTimeSlot = (value) => {
    if (value === 'New') {
      setSelectedTimeSlot(value);
      setWorkingHours({
        from: '',
        to: ''
      });
      setWorkingAreas([{ comment: '' }]);
      setTimeSlot(null);
    } else if (selectedDateTimeSlots && selectedDateTimeSlots.length) {
      const slot = selectedDateTimeSlots.find((s) => s.uid === value);
      setTimeSlot(slot);
      setSelectedTimeSlot(slot.uid);
      setWorkingHours({
        from: slot.startTime,
        to: slot.endTime
      });
      if (slot.workingAreas && !!slot.workingAreas.length) {
        setWorkingAreas([
          { comment: '' },
          ...slot.workingAreas.map((area) => area?.address)
        ]);
      }
    }
  };

  const handleChangeWorkingHours = (value, to) => {
    setWorkingHours((prev) => ({
      from: to ? prev.from : value,
      to: to ? value : prev.to
    }));
  };

  const saveTimeSlot = () => {
    const startDate = new Date(selectedDate);
    startDate.setHours(16);
    startDate.setMinutes(0);
    startDate.setSeconds(0);

    const rule = new RRule({
      freq: RRule.DAILY,
      dtstart: startDate,
      count: 1
    });

    const newTimeSlot = {
      startTime: workingHours.from,
      endTime: workingHours.to,
      name: 'single visit',
      type: 'work_availability',
      rule: rule.toString(),
      workingAreas:
        workingAreas.length > 1
          ? workingAreas.slice(1, workingAreas.length).map((area) => {
              if (area?.description) {
                return {
                  city: area.city || '',
                  countryCode: area.countryCode || area?.country?.code || '',
                  description: area.description || '',
                  lat: area.lat || 0,
                  lng: area.lng || 0,
                  zipCode: area.zipCode || ''
                };
              }
            })
          : []
    };

    if (timeSlot)
      handleSaveTimeSlot(newTimeSlot, timeSlot.uid, selectedDate, vetId);
    else handleCreateNewTimeSlot(newTimeSlot, vetId);
  };

  

  return (
    <div className="vetsCalendarModal">
      <h4>Working Days</h4>
      <div className="calendar">
        <Calendar
          fullscreen={fullScreen}
          onSelect={(date) => handleSelectDate(date)}
          dateFullCellRender={(date) => {
            const included = dates[dayjs(date).format('YYYY-MM-DD')];
            return (
              <span
                className={`calendar-date ${
                  included && 'calendar-date--included'
                }`}
              >
                {dayjs(date).format('DD')}
              </span>
            );
          }}
          disabledDate={(current) => {
            return current && current < moment().subtract(1, 'day');
          }}
        />
        <div>
          <Select
            value={selectedTimeSlot}
            className="timeChooser-input"
            onChange={handleChangeTimeSlot}
          >
            <Option value="New">New</Option>
            {!!selectedDateTimeSlots.length &&
              selectedDateTimeSlots.map((slot) => {
                return <Option value={slot.uid}>{slot.name}</Option>;
              })}
          </Select>
          <div className="mt-20">
            <input
              type="date"
              className="timeChooser-input"
              defaultValue={new Date()}
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
            />
          </div>
          <div className="workingHours">
            <h4 className="workingHours-title">Working Hours:</h4>
            <div className="timeChooser">
              <Input
                type="time"
                className="timeChooser-input"
                value={workingHours.from}
                onChange={(e) =>
                  handleChangeWorkingHours(e.target.value, false)
                }
              />
              <Input
                type="time"
                className="timeChooser-input"
                value={workingHours.to}
                onChange={(e) => handleChangeWorkingHours(e.target.value, true)}
              />
            </div>
          </div>
          <div className="workingArea">
            <h4>Working Area ( City/Zip-Code):</h4>
            <Table
              columns={workingAreaColumns}
              dataSource={workingAreas}
              rowKey="workingAreaId"
            />
          </div>
          <div className="text-right">
            <button className="calendarDetails-save" onClick={saveTimeSlot}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VetsCalendar;
