import React from 'react';
import {
  Button,
  Checkbox,
  DatePicker,
  Input,
  Popconfirm,
  Popover,
  Select,
  Tag,
  Typography
} from 'antd';
import cls from 'classnames';
import style from './UploadUsers.module.css';
import { genderOptions, statusOptions } from './serializers.js';
import get from 'lodash/get';
import moment from 'moment';
const { Option } = Select;

const RULES = {
  str: {
    type: 'string',
    required: true,
    message: false
  },
  num: {
    type: 'number',
    required: true,
    message: false
  }
};

export default [
  {
    title: 'externalId',
    dataIndex: 'externalId',
    key: 'externalId',
    name: ['externalId'],
    rules: [RULES.str]
  },
  {
    title: 'firstName',
    dataIndex: 'firstName',
    key: 'firstName',
    name: ['firstName'],
    rules: [RULES.str]
  },
  {
    title: 'LastName',
    dataIndex: 'lastName',
    key: 'lastName',
    name: ['lastName'],
    rules: [RULES.str]
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    name: ['email'],
    rules: [RULES.str]
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
    name: ['address', 'description'],
    rules: [RULES.str],
    address: true
  },
  {
    title: 'Floor',
    dataIndex: 'address',
    key: 'address',
    name: ['address', 'floor'],
    rules: [{ ...RULES.str, required: false }]
  },
  {
    title: 'Apartment',
    dataIndex: 'appartment',
    key: 'appartment',
    name: ['address', 'apartment']
  },
  {
    title: 'Property Type',
    dataIndex: 'address',
    key: 'address',
    name: ['address', 'propertyType'],
    rules: [RULES.str]
  },
  {
    title: 'Comment',
    dataIndex: 'address',
    key: 'address',
    name: ['address', 'comment']
  },
  {
    title: 'Phone Prefix',
    dataIndex: 'phonePrefix',
    key: 'phonePrefix',
    name: ['phonePrefix'],
    rules: [RULES.str]
  },
  {
    title: 'Phone Number',
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
    name: ['phoneNumber'],
    rules: [RULES.str]
  },
  {
    title: 'Pets',
    dataIndex: 'pets',
    key: 'pets',
    name: ['pets'],
    render: (pets, record, actions, context) => {
      return pets?.map((pet, index) => {
        const hasErrors = get(context.errors, [...context.key, index]);
        const errorExternalId = get(context.errors, [...context.key, index, 'externalId']);
        return (
          <Popover
            key={index}
            content={
              <div className={cls('popover', style.popover)}>
                <div className={style.row}>
                  <span className={style.title}>Type:</span>
                  <div className={style.input}>
                    <Select
                      size="small"
                      className={style.select}
                      defaultValue={pet.type}
                      onSelect={(e) => {
                        const type = context.petTypes?.find((type) => type.uid === e);
                        actions.setFieldValue(['users', record, 'pets', index, 'type'], type?.name);
                      }}
                      onMouseDown={(e) => e.stopPropagation()}
                    >
                      {context.petTypes?.map((type) => (
                        <Option value={type.uid} key={type.uid}>
                          {type.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className={style.row}>
                  <span className={style.title}>Gender:</span>
                  <div className={style.input}>
                    <Select
                      size="small"
                      className={style.select}
                      defaultValue={pet.gender}
                      onSelect={(value) =>
                        actions.setFieldValue(['users', record, 'pets', index, 'gender'], value)
                      }
                      onMouseDown={(e) => e.stopPropagation()}
                    >
                      {genderOptions.map((opt) => (
                        <Option value={opt.value} key={opt.value}>
                          {opt.label}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className={style.row}>
                  <span className={style.title}>Age:</span>
                  <div className={style.input}>
                    <DatePicker
                      size="small"
                      defaultValue={pet.age}
                      maxDate={moment()}
                      onChange={(date) =>
                        actions.setFieldValue(['users', record, 'pets', index, 'age'], date)
                      }
                    />
                  </div>
                </div>
                <div className={style.row}>
                  <span className={style.title}>Spayed:</span>
                  <div className={style.input}>
                    <Checkbox
                      size="small"
                      defaultValue={pet.spayed}
                      onChange={(e) =>
                        actions.setFieldValue(
                          ['users', record, 'pets', index, 'spayed'],
                          e.target.checked
                        )
                      }
                    />
                  </div>
                </div>
                <div className={style.row}>
                  <span className={style.title}>External Id:</span>
                  <div className={style.input}>
                    <Input
                      size="small"
                      defaultValue={pet.externalId}
                      onChange={(e) =>
                        actions.setFieldValue([...context.key, index, 'externalId'], e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className={style.row}>
                  <span className={style.title}>Status:</span>
                  <div className={style.input}>
                    <Select
                      size="small"
                      className={style.select}
                      defaultValue={pet.status}
                      onSelect={(value) =>
                        actions.setFieldValue(['users', record, 'pets', index, 'status'], value)
                      }
                      onMouseDown={(e) => e.stopPropagation()}
                    >
                      {statusOptions.map((opt) => (
                        <Option value={opt.value} key={opt.value}>
                          {opt.label}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
                {errorExternalId && <span className={style.subtitle}>{errorExternalId}</span>}
              </div>
            }
          >
            <Tag
              key={pet}
              className={cls({ [style.error]: hasErrors })}
              style={{ cursor: 'pointer' }}
            >
              {pet?.name}
            </Tag>
          </Popover>
        );
      });
    }
  },
  {
    title: 'operation',
    dataIndex: 'operation',
    render: (_, record, actions, context) => {
      const overlay = (
        <>
          <Typography.Link onClick={() => actions.editRow(record)}>Edit</Typography.Link>
          <Popconfirm
            title="Are you sure to delete this row?"
            onConfirm={() => actions.deleteRow(record)}
            onCancel={actions.cancelRow}
            okText="Yes"
            cancelText="No"
          >
            <a href="#" style={{ marginLeft: 10 }}>
              Delete
            </a>
          </Popconfirm>
        </>
      );
      return (
        <>
          {context.editingKey === record ? (
            <span>
              <Typography.Link
                onClick={actions.cancelRow}
                style={{
                  marginRight: 8
                }}
              >
                Save
              </Typography.Link>
            </span>
          ) : (
            <Popover className="single-appointment-popover" content={overlay}>
              <Button>Actions</Button>
            </Popover>
          )}
        </>
      );
    }
  }
];
