export const serializeAppointment = async (
  data,
  vets,
  integrationId,
  travelTimeIncluded,
  supportedServices
) => {
  const appointment = {
    vetId: vets?.find((vet) =>
      vet.vetIntegrations.find(
        (vetIntegration) =>
          vetIntegration?.externalId === data['vetId'] &&
          vetIntegration?.integration?.uid === integrationId
      )
    )?.uid,
    travelTimeIncluded: !!travelTimeIncluded,
    householdNumber: data['householdNumber'],
    date: data['date'],
    pet: {
      name: data['petName'],
      externalId: data['petExternalId']
    },
    startOfWorkingHours: data['startOfWorkingHours'],
    endOfWorkingHours: data['endOfWorkingHours'],
    startTime: data['startTime'],
    endTime: data['endTime'],
    supportedServices
  };

  return appointment;
};
