import {useState} from 'react';
import {useMutation} from '@apollo/react-hooks';
import axios from 'axios';

import {getUploadUrlMutation} from 'gql';

const useUpload = () => {
  const [getUploadURL] = useMutation(getUploadUrlMutation);
  const [uploadPercent, setUploadPercent] = useState(0);

  const uploadFile = async (file) => {
    try {
      if (!file) return;
      const splittedFileName = file?.name?.split('.');
      const extension = splittedFileName.slice(-1)[0];
      const name = splittedFileName
          .slice(0, -1)
          .join('.')
          .replace(/(\s+)/g, '_')
          .replace(/([^\w]+)/g, '');

      const res = await getUploadURL({
        variables: {
          record: {
            name,
            type: file?.type,
            extension: extension,
          },
        },
      });

      if (res?.data?.getUploadURL) {
        const uploadRes = await axios({
          method: 'put',
          url: res.data.getUploadURL,
          headers: {
            'Content-Type': file.type,
          },
          data: file,
          onUploadProgress: function(progressEvent) {
            const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total,
            );
            setUploadPercent(percentCompleted);
          },
        });

        return uploadRes?.config?.url?.split('?')?.[0];
      }
    } catch (err) {
      console.log('upload file error', err);
    }
  };
  return {
    uploadFile,
    uploadPercent,
    setUploadPercent,
  };
};

export default useUpload;
