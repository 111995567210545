import { useQuery } from '@apollo/client';
import { getVetsQuery } from 'gql';

const useVets = () => {
  const { data, loading } = useQuery(getVetsQuery, {
    fetchPolicy: 'no-cache',
    variables: {
      search: ''
    }
  });

  const vetsData = data?.getVets.map((vet) => {
    return { id: vet.uid, name: `${vet.user.firstName} ${vet.user.lastName}` };
  });

  return { vetsData, vets: data?.getVets || [], loading };
};

export default useVets;
