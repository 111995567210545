import React, { useState, useEffect } from 'react';
import { Alert, Space } from 'antd';
import { cloneDeep, set } from 'lodash';

import Start from './Start/index';
import Repeat from './Repeat/index';
import End from './End/index';

import computeRRuleToString from '../utils/computeRRule/toString/computeRRule';
import computeRRuleFromString from '../utils/computeRRule/fromString/computeRRule';
import configureInitialState from '../utils/configureInitialState';
import translations from '../translations';

import '../styles/index.css';

const ReactRRuleGenerator = (props) => {
  const [state, setState] = useState(
    configureInitialState(props.config, props.calendarComponent, props.id),
  );

  useEffect(() => {
    if (props.value) {
      // if value is provided to RRuleGenerator, it's used to compute state of component's forms
      const data = computeRRuleFromString(state.data, props.value);
      setState((prev) => ({
        ...prev,
        data,
      }));
      setState((prev) => ({
        ...prev,
        data,
      }));
    }
  }, [props.value]);

  const handleChange = ({ target }) => {
    const newData = cloneDeep(state.data);
    set(newData, target.name, target.value);
    const rrule = computeRRuleToString(newData);

    setState((prev) => ({
      ...prev,
      data: newData,
    }));
    props.onChange(rrule);
  };

  const {
    id,
    data: { start, repeat, end, options, error },
  } = state;

  return (
    <Space size="large" direction="vertical" className="full-width">
      {!options.hideError && error && (
        <Alert message={error.value} type="error" />
      )}

      {!options.hideStart && (
        <Start
          id={`${id}-start`}
          start={start}
          handleChange={handleChange}
          translations={props.translations}
          disabled={props.disabled}
        />
      )}
      {!options.hideEnd && (
        <End
          id={`${id}-end`}
          end={end}
          handleChange={handleChange}
          disabled={props.disabled}
          translations={props.translations}
        />
      )}
      <Repeat
        id={`${id}-repeat`}
        repeat={repeat}
        handleChange={handleChange}
        disabled={props.disabled}
        translations={props.translations}
      />
    </Space>
  );
};

ReactRRuleGenerator.defaultProps = {
  id: null,
  value: '',
  config: {},
  onChange() {},
  calendarComponent: null,
  translations: translations.english,
};

export default ReactRRuleGenerator;
