import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

import Assistant from '../fonts/Assistant-VariableFont_wght.ttf';

const GlobalStyles = createGlobalStyle`
  ${normalize}
  a {
    text-decoration: none;
    color: unset;
  }
  h1, h2, h3, h4, h5 {
    font-size: unset;
    margin: 0rem;
    font-weight: normal;
  }
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  body {
    ${({ isDashboard, theme }) =>
      isDashboard && theme.mobile`overflow: hidden;`}
  }
  html {
    font-size: 62.5%;
  }
  ul {
    list-style-type: none;
  }
  @font-face {
    font-family: assistant;
    src:  url(${Assistant});
  }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active
{
  box-shadow: 0 0 0 3rem white inset !important;
 -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.ReactModal__Content::-webkit-scrollbar {
    display: none;
  }
.ReactModal__Body--open{
  overflow: hidden;
}
  
`;
export default GlobalStyles;
