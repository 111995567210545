import React from 'react';

import { useAuthContext } from 'common/index.js';

import { Rules } from 'constants/permissions';

const RoleBasedUi = ({ permission, children }) => {
  const auth = useAuthContext();
  const role = auth?.me?.role?.toLowerCase();

  return Rules[role]?.includes(permission) ? children : '';
};

export default RoleBasedUi;
