import React from 'react';
import { Button, Input, Popconfirm, Popover, Select, Tag, Typography } from 'antd';
import cls from 'classnames';
import style from './UploadAppointments.module.css';
import get from 'lodash/get';

const RULES = {
  str: {
    type: 'string',
    required: true,
    message: false
  },
  num: {
    type: 'number',
    required: true,
    message: false
  },
  bool: {
    type: 'boolean',
    required: true,
    message: false
  }
};

export default [
  {
    title: 'Vet ID',
    dataIndex: 'vetId',
    key: 'vetId',
    name: ['vetId'],
    rules: [RULES.str]
  },
  {
    title: 'Travel Time Included',
    dataIndex: 'travelTimeIncluded',
    key: 'travelTimeIncluded',
    name: ['travelTimeIncluded'],
    rules: [RULES.bool]
  },
  {
    title: 'Household Number',
    dataIndex: 'householdNumber',
    key: 'householdNumber',
    name: ['householdNumber'],
    rules: [RULES.str]
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    name: ['date'],
    rules: [RULES.str]
  },
  {
    title: 'Pet',
    dataIndex: 'pet',
    key: 'pet',
    name: ['pet'],
    render: (pet, record, actions, context) => {
      const hasErrors = get(context.errors, [...context.key]);
      const errorExternalId = get(context.errors, [...context.key, 'externalId']);
      return (
        <Popover
          content={
            <div className={cls('popover', style.popover)}>
              <div className={style.row}>
                <span className={style.title}>External Id:</span>
                <div className={style.input}>
                  <Input
                    size="small"
                    defaultValue={pet.externalId}
                    onChange={(e) =>
                      actions.setFieldValue([...context.key, 'externalId'], e.target.value)
                    }
                  />
                </div>
              </div>
              {errorExternalId && <span className={style.subtitle}>{errorExternalId}</span>}
            </div>
          }>
          <Tag
            key={pet}
            className={cls({ [style.error]: hasErrors })}
            style={{ cursor: 'pointer' }}>
            {pet?.name}
          </Tag>
        </Popover>
      );
    }
  },
  {
    title: 'Start Of Working Hours',
    dataIndex: 'startOfWorkingHours',
    key: 'startOfWorkingHours',
    name: ['startOfWorkingHours'],
    rules: [RULES.str]
  },
  {
    title: 'End Of Working Hours',
    dataIndex: 'endOfWorkingHours',
    key: 'endOfWorkingHours',
    name: ['endOfWorkingHours'],
    rules: [RULES.str]
  },
  {
    title: 'Start Time',
    dataIndex: 'startTime',
    key: 'startTime',
    name: ['startTime'],
    rules: [RULES.str]
  },
  {
    title: 'End Time',
    dataIndex: 'endTime',
    key: 'endTime',
    name: ['endTime'],
    rules: [RULES.str]
  },
  {
    title: 'Operation',
    dataIndex: 'operation',
    render: (_, record, actions, context) => {
      const overlay = (
        <>
          <Typography.Link onClick={() => actions.editRow(record)}>Edit</Typography.Link>
          <Popconfirm
            title="Are you sure to delete this row?"
            onConfirm={() => actions.deleteRow(record)}
            onCancel={actions.cancelRow}
            okText="Yes"
            cancelText="No">
            <a href="#" style={{ marginLeft: 10 }}>
              Delete
            </a>
          </Popconfirm>
        </>
      );
      return (
        <>
          {context.editingKey === record ? (
            <span>
              <Typography.Link
                onClick={actions.cancelRow}
                style={{
                  marginRight: 8
                }}>
                Save
              </Typography.Link>
            </span>
          ) : (
            <Popover className="single-appointment-popover" content={overlay}>
              <Button>Actions</Button>
            </Popover>
          )}
        </>
      );
    }
  },
  {
    title: 'Error',
    dataIndex: 'error',
    key: 'error',
    name: ['error'],
    rules: [{ ...RULES.str, required: false }]
  }
];
