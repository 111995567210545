import React from 'react';
import MainLayout from './templates/MainLayout/MainLayout';
import {useTranslation} from 'react-multi-lang/lib';
import {messages} from './service/utils';
import {AuthProvider} from 'common/service/auth/authContext';
import useAuth from 'common/service/auth/useAuth';
import MainRouter from './components/utils/Routers/MainRouter';
import LoaderWrapper from 'components/ui/LoaderWrapper/LoaderWrapper';
import {StyleSheetManager, ThemeProvider} from 'styled-components';
import theme from './styles/theme';
import './App.css';
import 'antd/dist/antd.css';

function App() {
  const auth = useAuth();
  messages.t = useTranslation();

  return (
    <ThemeProvider theme={theme}>
      <StyleSheetManager stylisPlugins={[]}>
        <AuthProvider value={auth}>
          <LoaderWrapper isLoading={auth?.meLoading}>
            <MainLayout>
              <MainRouter/>
            </MainLayout>
          </LoaderWrapper>
        </AuthProvider>
      </StyleSheetManager>
    </ThemeProvider>
  );
}

export default App;
