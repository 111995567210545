import React from 'react';

import { useQuery } from '@apollo/client';
import { getLanguagesQuery } from 'gql';
import { Select, Form } from 'antd';
import { filterOption, messages } from 'service/utils';

const { Option } = Select;

const LanguageSelectInput = ({ setFieldValue, values, isError }) => {
  const { data } = useQuery(getLanguagesQuery);

  const onChange = (value) => {
    setFieldValue('languageIds', value);
  };
  return (
    <Form.Item label="Language:" labelCol={{ span: 24 }}>
      <Select
        showSearch
        placeholder="Language"
        mode="multiple"
        value={values.languageIds}
        allowClear
        size="middle"
        optionFilterProp="children"
        onChange={onChange}
        filterOption={(input, option) => filterOption(input, option)}
        className={isError && 'error-input'}
      >
        {data?.getLanguages.map(({ uid, name }) => {
          return (
            <Option key={uid} value={uid}>
              {name}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default LanguageSelectInput;
