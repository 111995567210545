import React from 'react';
import { Table } from 'antd';

const CustomerTable = ({ columns, dataSource, heading }) => {
  return (
    <div className="table">
      <h3>{heading}</h3>
      <Table columns={columns} dataSource={dataSource} />
    </div>
  );
};

export default CustomerTable;
