import React, {useEffect, useState} from 'react';
import cls from 'classnames';
import text from 'styles/text.module.css';
import css from './AppointmentDetails.module.css';
import PetEdit, {petYup} from '../PetEdit/PetEdit';
import Button, {ButtonColors} from '../../../library/Button/Button';
import {CloseOutlined, FilePdfOutlined, FileTextOutlined} from '@ant-design/icons';
import {Input, message, Select, Upload} from 'antd';
import {messages} from 'service/utils';
import {useFormik} from 'formik';
import {
  birthDayDateToNumbers,
  getOptions,
  prepareAppPetFromForm,
} from '../../../utils/helpers';
import * as Yup from 'yup';
import Checkbox from '../../../library/Checkbox/Checkbox';
import Label from '../../../library/Label/Label';
import FormItem from '../../../library/FormItem/FormItem';
import PetItem from '../../../library/PetItem/PetItem';
import get from 'lodash/get';
import uniqueId from 'lodash/uniqueId';
import useUpload from '../../../service/useUpload';
import last from 'lodash/last';

const appointmentSchema = (isVet) => Yup.object({
  pet: petYup(isVet),
  concerns: Yup.array().min(1, 'appointments.forms.appointment.concern').of(Yup.object({value: Yup.string().required(), label: Yup.string()}))
      .required('appointments.forms.appointment.concern'),
  comment: Yup.string(),
  firstTime: Yup.boolean(),
  files: Yup.array(),
});

const getSterilizedStatus = (appPet) => {
  if (appPet?.pet?.isSterilized !== undefined) {
    if (typeof appPet?.pet?.isSterilized === 'string') {
      return appPet?.pet?.isSterilized;
    }
    return appPet?.pet?.isSterilized === null
      ? 'unknown'
      : appPet?.pet?.isSterilized ? 'neutered' : 'no_neutered';
  }
  return undefined;
};

const getInitialValues = (appPet) => {
  return {
    _id: appPet._id || uniqueId(),
    pet: {
      _id: appPet?.pet?._id || uniqueId(),
      uid: appPet?.pet?.uid || undefined,
      name: appPet?.pet?.name || '',
      type: appPet?.pet?.type ? getOptions([appPet.pet.type], {label: 'name', value: 'uid'})[0] : undefined,
      gender: appPet?.pet?.gender ? {value: appPet?.pet?.gender, label: appPet?.pet?.gender} : undefined,
      isSterilized: getSterilizedStatus(appPet),
      birthDate: appPet?.pet?.birthDate || undefined,
      ...birthDayDateToNumbers(appPet?.pet?.birthDate),
    },
    concerns: appPet?.concerns ? getOptions(appPet.concerns) : undefined,
    comment: appPet?.comment || '',
    firstTime: appPet?.pet?.uid ? !!appPet?.firstTime : true,
    files: appPet?.files || [],
  };
};

const AppointmentDetails = ({appPet, onCancel, onComplete, isNew, concerns, isVet}) => {
  const {uploadFile} = useUpload();
  const [uploading, setUpload] = useState(false);
  const t = messages.t;
  const [edit, setEdit] = useState(false);
  const initialValues = React.useMemo(() => getInitialValues(appPet), [appPet]);
  const formik = useFormik({
    initialValues,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: appointmentSchema(isVet),
    onSubmit: async (values, helpers) => {
      helpers.setSubmitting(true);
      onComplete(prepareAppPetFromForm(values));
      helpers.setSubmitting(false);
      onCancel();
    },
  });
  useEffect(() => {
    if (Object.keys(formik.touched).length === 0 && formik.errors.pet) {
      setEdit(true);
    }
  }, [initialValues, formik.errors]);
  const errors = {
    concerns: get(formik.touched, 'concerns') && t(get(formik.errors, 'concerns', '')),
  };
  const uploadProps = {
    onRemove: (file) => {
      const index = formik.values.files.indexOf(file);
      const newFileList = formik.values.files.slice();
      newFileList.splice(index, 1);
      formik.setFieldValue('files', newFileList);
    },
    beforeUpload: async (file) => {
      setUpload(true);
      const isLt15M = file.size / 1024 / 1024 < 15;
      if (!isLt15M) {
        message.error('File must be smaller than 15MB!', 4);
        return false;
      }
      const url = await uploadFile(file);
      const newFileList = formik.values.files.slice();
      newFileList.push(url);
      formik.setFieldValue('files', newFileList);
      setUpload(false);
      return false;
    },
    itemRender: (on, f, fl, {remove}) => {
      const fileName = last(f.split('/'));
      const v4 = new RegExp(/(_[0-9(a-f|A-F)]{8}-[0-9(a-f|A-F)]{4}-4[0-9(a-f|A-F)]{3}-[89ab][0-9(a-f|A-F)]{3}-[0-9(a-f|A-F)]{12})/);
      const name = fileName.replace(v4, '');
      return <a href={f} className={css.file} target="_blank" rel="noreferrer">
        <FilePdfOutlined />
        <Label className={css.fileName}>{name}</Label>
        <Button type="text"
          icon={<CloseOutlined/>}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            remove();
          }}/>
      </a>;
    },
    fileList: formik.values.files,
  };

  return (<form onSubmit={formik.handleSubmit}>
    <div className={css.container}>
      <div className={cls(css.row)}>
        <span className={cls(text.h5, css.title)}>
          Appointment details
        </span>
        <Button icon={<CloseOutlined />} onClick={onCancel} type="text" shape="circle"/>
      </div>
      {isNew || edit
        ? <PetEdit formik={formik} />
        : <PetItem pet={appPet.pet} className={css.pet} onClick={() => setEdit(true)} />
      }
      <FormItem label={'What\'s the concern?'} error={errors.concerns}>
        <Select
          mode="multiple"
          allowClear
          style={{width: '100%'}}
          placeholder="Choose concern"
          value={formik.values.concerns}
          onChange={(v, options) => {
            formik.setFieldValue('concerns', options);
          }}
          status={errors.concerns ? 'error' : undefined}
          onBlur={formik.handleBlurr}
          options={concerns}
        />
      </FormItem>
      <FormItem label={'Comments'} error={errors.comment}>
        <Input.TextArea
          className={css.textArea}
          onBlur={formik.handleBlur}
          type="text"
          name="comment"
          placeholder={'Additional details about your pet\'s condition that will help your vet come prepared'}
          onChange={formik.handleChange}
          value={formik.values.comment}
          status={formik.touched.comment && formik.errors.comment ? 'error' : ''}
        />
      </FormItem>
      <Checkbox
        checked={formik.values.firstTime}
        name="firstTime"
        onChange={formik.handleChange}
      >
        <Label>First time appointment with this vet</Label>
      </Checkbox>
      <Upload.Dragger {...uploadProps}>
        <Button
          loading={uploading}
          icon={<FileTextOutlined />}
          type="text"
          disabled={uploading}
        >Upload medical history</Button>
      </Upload.Dragger>
      <div className={css.buttons}>
        <Button type="secondary" onClick={onCancel}>Cancel</Button>
        <Button colorScheme={ButtonColors.ORANGE} type="primary" htmlType="submit">Save</Button>
      </div>
    </div>
  </form>);
};

export default AppointmentDetails;
