import { ApolloClient, InMemoryCache, ApolloLink, split } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { getToken } from 'common/utils/helpers';

const uri = process.env.REACT_APP_GRAPHQL_URI;
const wsUrl = process.env.REACT_APP_API_SUBSCRIPTION_URI;

const uploadLink = createUploadLink({
  uri,
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
});

const wsLink = new WebSocketLink({
  uri: wsUrl,
  options: {
    reconnect: true,
    connectionParams: async () => {
      const token = getToken();
      return {
        authorization: token ? `Bearer ${token}` : '',
      };
    },
    connectionCallback: (err) => console.log('connection', err),
  },
});

const authLink = setContext((_, { headers, ...rest }) => {
  const token = getToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
    ...rest,
  };
});

const uploadLinkWithAuth = authLink.concat(uploadLink);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log('TCL: errorLink -> message', message);

      console.log(
        `[GraphQL error]: Message: ${JSON.stringify(
          message,
        )}, Location: ${JSON.stringify(locations)}, Path: ${path}`,
      );
    });

  if (networkError) {
    console.log('[Network error]:', networkError);
  }
});

const cache = new InMemoryCache({
  dataIdFromObject: (o) => o.uid,
  addTypename : false
});

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  uploadLinkWithAuth,
);

export default new ApolloClient({
  cache,
  link: ApolloLink.from([errorLink, link]),
  connectToDevTools: true,
});
