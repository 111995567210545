import styled from 'styled-components';
import theme from 'styles/theme';

export const styles = (
  error,
  isFocused,
  hideLabel,
  isMobile,
  isForTimeSlot,
  isLaptop,
  animateLabel,
  customHeight,
  isFilter,
  isRtl
) => ({
  control: (_, state) => ({
    backgroundColor: theme.colors.white,
    display: 'flex',
    height: customHeight
      ? customHeight
      : isMobile
        ? '5.3rem'
        : hideLabel
          ? '4rem'
          : isLaptop
            ? '5.1rem'
            : '5.8rem',

    width: '100%',
    border: `0.1rem solid ${error ? theme.colors.red : theme.colors.gray}`,
    boxShadow: 'none',
    borderRadius: state.selectProps.menuIsOpen ? '0.8rem 0.8rem 0 0' : '0.8rem',
    transition: '0.1s ease-in-out'
  }),
  singleValue: (base) => ({
    ...base,
    fontSize: isLaptop ? '1.4rem' : '1.6rem',
    fontFamily: 'inter, assistant',
    fontWeight: isFilter ? 500 : 400,
    color: isFilter ? '#2D3363CC' : theme.colors.black,
    overflow: 'visible',
    marginLeft: 0
  }),
  input: (base) => ({
    ...base,
    fontFamily: 'inter, assistant',
    fontWeight: 400,
    fontSize: '1.6rem',
    color: theme.colors.black
  }),
  dropdownIndicator: (base, { isFocused, selectProps }) => ({
    ...base,
    color: error ? theme.colors.red : '#b0b7c3',
    ':hover': {
      color: error ? theme.colors.red : '#b0b7c3'
    },
    transition: 'all .3s',
    transform: selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)'
  }),
  option: (base) => ({
    ...base,
    fontSize: isLaptop && isFilter ? '1.4rem' : '1.6rem',
    width: '100%',
    color: '#8A94A6',
    height: hideLabel ? '4rem' : '5.8rem',
    fontFamily: 'inter, assistant',
    fontWeight: 500,
    boxSizing: 'border-box',
    backgroundColor: theme.colors.white,
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    paddingLeft: isForTimeSlot ? '1.2rem' : isFilter ? '1.6rem' : '2.4rem',
    paddingRight: isRtl ? (isFilter ? (isLaptop ? '2.5rem' : '1.6rem') : '1.2rem') : 'unset',
    border: isFilter ? 'none' : '0.1rem solid #dbe2ee',
    ':hover': {
      borderColor: isFilter ? 'none' : theme.colors.orange,
      color: theme.colors.black,
      backgroundColor: isFilter && 'rgba(219, 226, 238, 0.2)',
      cursor: 'pointer'
    },
    ':last-child': {
      borderRadius: '0 0 .8rem .8rem'
    }
  }),
  menu: (base) => ({
    ...base,
    margin: 0,
    boxShadow: 'none',
    zIndex: 4,
    borderRadius: '0 0 .8rem .8rem',
    padding: isFilter && '0.6rem 0.1rem 0.3rem 0.6rem',
    border: isFilter && '0.05rem solid #dbe2ee'
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
    maxHeight: isFilter ? '16.4rem' : '20rem',
    marginTop: isFilter && '0.2rem',
    borderRight: 'none',
    overflowX: isLaptop && 'hidden',
    borderRadius: '0 0 .8rem .8rem',
    paddingRight: isRtl ? (isForTimeSlot ? '1.2rem' : 'unset') : 'unset',
    '::-webkit-scrollbar': {
      width: '.4rem'
    },
    '::-webkit-scrollbar-track': {
      background: isFilter ? theme.colors.white : '#cacaca'
    },
    '::-webkit-scrollbar-thumb': {
      background: isFilter ? '#8A94A640' : '#888',
      borderRadius: isFilter && '1.2rem'
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
      background: isFilter && '#ccc'
    },
    '::-webkit-scrollbar-corner': {
      borderRadius: '1.2rem'
    }
  }),
  placeholder: (_, state) => ({
    visibility: hideLabel ? (state.hasValue ? 'hidden' : 'visible') : 'visible',
    padding: isFilter && isMobile ? '0 1.6rem' : '0 0.2rem',
    position: 'absolute',
    top: animateLabel
      ? state.selectProps.menuIsOpen || state.hasValue || state.selectProps.inputValue
        ? '-1rem'
        : 'calc(35%)'
      : 'unset',

    // 1.9rem",
    left: isRtl
      ? 'unset'
      : animateLabel
        ? state.selectProps.menuIsOpen || state.hasValue || state.selectProps.inputValue
          ? '1.5rem'
          : '2.6rem'
        : 'unset',
    transition: animateLabel ? '0.3s ease-in-out' : 'unset',
    fontSize: animateLabel
      ? state.selectProps.menuIsOpen || state.hasValue || state.selectProps.inputValue
        ? '1.4rem'
        : '1.6rem'
      : isLaptop
        ? '1.4rem'
        : '1.6rem',
    fontFamily: 'inter, assistant',
    fontWeight: isFilter ? 500 : 400,
    color: error
      ? theme.colors.red
      : isFilter
        ? '#2D3363CC'
        : state.selectProps.menuIsOpen
          ? theme.colors.orange
          : '#b0b7c3',
    zIndex: 1,
    ':before': {
      content: '""',
      width: '100%',
      height: '50%',
      position: 'absolute',
      bottom: 0,
      left: 0,
      backgroundColor: '#ffffff',
      zIndex: -1
    }
  }),
  valueContainer: (base) => ({
    ...base,
    overflow: 'visible',
    fontWeight: isFilter ? 500 : 400,
    paddingLeft: isForTimeSlot ? '1.2rem' : isRtl ? 'unset' : '2.4rem',
    paddingRight: isForTimeSlot ? '1.2rem' : '2rem'
  })
});

export const SelectorWrapper = styled.div`
  width: 100%;
`;

export const Dots = styled.span`
  font-family: inter, assistant;
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.9rem;
  display: flex;
  align-items: center;
  width: 1.4rem;
  color: rgba(45, 51, 99, 0.8);
`;
