import { useHistory } from 'react-router-dom';
import { message } from 'antd';

import { DASHBOARD, USERS, VETS } from 'constants/routeNames';
import { useMutation } from '@apollo/client';
import { createVetMutation } from 'gql';

const useCreateVet = () => {
  const history = useHistory();
  const [createVet] = useMutation(createVetMutation, {
    refetchQueries: ['getVets'],
    awaitRefetchQueries: true
  });

  const addNewVet = async (vetData) => {
    try {
      // TODO: temporary
      delete vetData.timeSlots
      await createVet({
        variables: {
          record: {
            ...vetData
          }
        }
      });
      history.push(DASHBOARD + USERS + VETS);
    } catch (e) {
      const error = e?.graphQLErrors?.[0]?.message;
      message.error(error || 'Failed to create vet', 10);
    }
  };

  return { addNewVet };
};

export default useCreateVet;
