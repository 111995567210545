import styled, { css } from 'styled-components';

export const Btn = styled.button`
  width: ${({width}) => (width || '100%')};
  height: ${({ height , fitted, isLaptop, isForModal }) =>
          height ? height : isForModal ? '4rem' : fitted ? '100%' : isLaptop ? '4.7rem' : '5.8rem'};
  border-radius: ${({ isForModal }) => isForModal ? '0.2rem' : '0.8rem'};
  font-size: 1.6rem;
  // font-family: inter, assistant;
  font-weight: 600;
  outline: none;
  color: ${({ fontColor }) => (fontColor ? fontColor : '#ffffff')};
  cursor: pointer;
  border: ${({border}) => (border || 'none')};
  cursor: pointer;
  background-color: ${({ color, theme }) => {
    switch (color) {
      case 'primary':
        return theme.colors.orange;
      case 'secondary':
        return '#8A94A6';
      case 'darkGreen':
        return theme.colors.darkGreen;
      case 'disabled':
        return '#DBE2EE';
      default:
        return color;
    }
  }};
  ${({ disabled }) =>
          disabled &&
          css`
            opacity: 0.75;
            cursor: default;
            ${({ isForModal }) =>
                    isForModal &&
                    css`
                      background: #F5F5F5;
                      color: rgba(0, 0, 0, 0.25);
                      opacity: 1;
                    `}
          `}
  ${({ discount }) =>
          discount &&
          css`
            width: 17.3rem;
            height: 4.4rem;
            background: ${({ white }) =>
                    white ? 'rgba(0, 0, 0, 0)' : 'rgba(255, 154, 5, 1)'};
            border: 0;
            box-shadow: 0rem -0.3rem 5.5rem rgba(0, 0, 0, 0.04);
            border-radius: 0.8rem;
            // font-family: inter, assistant;
            font-style: normal;
            font-weight: 500;
            font-size: 1.4rem;
            line-height: 1.6rem;
            text-align: center;
            cursor: pointer;
            color: ${({ white }) =>
                    white ? 'rgba(54, 71, 95, 1)' : 'rgba(255, 255, 255, 1)'};
            ${({ cyan }) =>
                    cyan &&
                    css`
                      background-color: rgba(36, 172, 185, 1);
                    `}
            ${({ theme }) => theme.laptop`
        height: 4.9rem;
      `}
          `}
`;
