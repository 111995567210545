import React, { memo } from 'react';
import cls from 'classnames';
import css from './PetItem.module.css';
import { petImages } from '../../constants/pet';
import { CheckCircleOutlined, EditFilled } from '@ant-design/icons';
import capitalize from 'lodash/capitalize';
import { birthDayDateToNumbers } from '../../utils/helpers';
import Button from '../Button/Button';

const getAge = (date) => {
  if (date) {
    const { year, month, day } = birthDayDateToNumbers(date);
    if (year) return `${year}y `;
    if (month) return `${month}m `;
    if (day) return `${day}m `;
  }
  return null;
};

// const getFirstLetter = (string) => get(string, '[0]', '').toUpperCase();

const SelectedMark = () => (
  <div className={css.selectedMark}>
    <CheckCircleOutlined />
  </div>
);

const PetItem = ({ onClick, selected, className, pet, forList }) => {
  const age = getAge(pet.birthDate);
  const gender = capitalize(pet.gender);
  const type = capitalize(pet.type?.name);
  const description = [type, gender, age].filter((s) => s).join(' | ');
  return (
    <div
      onClick={forList ? onClick : undefined}
      className={cls(css.container, { [css.selected]: selected }, className)}
    >
      {selected ? <SelectedMark /> : null}
      <div className={css.avatarWrapper}>
        <img
          className={css.avatar}
          src={pet?.avatarUrl || petImages[pet?.type?.name || 'other']}
          alt="avatar"
        />
      </div>
      <div className={css.pet}>
        <span className={css.name}>{pet.name}</span>
        <span className={css.description}>{description}</span>
      </div>
      {!forList ? (
        <div className={css.buttonContainer}>
          <Button
            className={css.button}
            type="text"
            shape="round"
            icon={<EditFilled />}
            onClick={onClick}
          />
        </div>
      ) : null}
    </div>
  );
};

const Memoized = memo(PetItem);
export default Memoized;
