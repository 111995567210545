import React from 'react';
import cls from 'classnames';
import { Button as Btn } from 'antd';
import css from './Button.module.css';

export const ButtonType = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  DEFAULT: 'default',
  GHOST: 'ghost',
  LINK: 'link',
  TEXT: 'text'
};

export const ButtonColors = {
  ORANGE: 'orange',
  BLUE: 'blue',
  GRAY: 'gray',
  BLUE_LIGHT: 'blue_light'
};

const Button = ({ className, children, colorScheme, ...props }) => {
  return (
    <Btn
      {...props}
      className={cls(
        css.button,
        {
          [css.colorSchemeOrange]: colorScheme === ButtonColors.ORANGE,
          [css.colorSchemeBlue]: colorScheme === ButtonColors.BLUE,
          [css.colorSchemeGray]: colorScheme === ButtonColors.GRAY,
          [css.colorSchemeBlueLight]: colorScheme === ButtonColors.BLUE_LIGHT
        },
        className
      )}
    >
      {children}
    </Btn>
  );
};

export default Button;
