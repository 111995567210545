import moment from 'moment';

export const useDateFormat = () => {
  const isHeb = localStorage.getItem('lang') === 'heb';
  const dateFormat = (date) => {
    if (isHeb) {
      return moment(date).format('DD/MM/YYYY');
    } else {
      return moment(date).format('MM/DD/YYYY');
    }
  };
  const reverseDateFormat = (date) => {
    if (isHeb) {
      return moment(date).format('YYYY/MM/DD');
    } else {
      return moment(date).format('YYYY/DD/MM');
    }
  };
  const monthFormat = (date) => {
    if (isHeb) {
      return moment(date).format('DD / MM');
    } else {
      return moment(date).format('MM / DD');
    }
  };
  return {dateFormat, monthFormat, reverseDateFormat};
};

export const range = (min, max, direction = 'asc') => {
  return Array.from(new Array(max + 1 - min), (x, i) =>
    direction === 'desc' ? max - i : i + min,
  ).map((i) => ({
    value: i,
    label: i,
  }));
};

export const months = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];
