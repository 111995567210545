import React, {memo} from 'react';
import {Steps} from 'antd';
import {messages} from 'service/utils';

import {ReactComponent as CloseIcon} from 'assets/icons/close-gray.svg';

const Stepper = ({current, changeStep, me, isForVet, onRequestClose}) => {
  const {Step} = Steps;

  return (
    <div className="new-user-stepper-wrapper">
      <Steps current={current}>
        <Step
          title={messages.t('recommender.scheduling_stepper.vet_first_step_title')}
          description=''
          onClick={() => changeStep(0)}
        />
        <Step
          title={messages.t('recommender.scheduling_stepper.vet_second_step_title')}
          subTitle=''
          description=''
          onClick={() => changeStep(1)}
        />
        {(!me?.email || isForVet) && (
          <Step
            title={messages.t('recommender.scheduling_stepper.vet_third_step_title')}
            description=''
            onClick={() => changeStep(2)}
          />
        )}
      </Steps>
      <CloseIcon onClick={onRequestClose}/>
    </div>
  );
};

export default memo(Stepper);
