import React from 'react';

import { Form, Button } from 'antd';
import { dashboardLink, messages } from 'service/utils';
import { useHistory } from 'react-router-dom';
import { CUSTOMERS, USERS } from 'constants/routeNames';
import RoleBasedUi from "../../role-based-ui/RoleBasedUi.js";
import {Permissions} from "../../../constants/permissions.js";

const NavigationButtons = () => {
  const history = useHistory();

  const onCancel = () => {
    history.push(dashboardLink(USERS + CUSTOMERS));
  };

  return (
    <div className="buttons-wrapper">
      <Form.Item className="mr-10" wrapperCol={{ offset: 8, span: 16 }}>
        <Button onClick={onCancel}> {messages.t('general.cancel')}</Button>
      </Form.Item>

      <RoleBasedUi permission={Permissions.UPDATE_CUSTOMER}>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            {messages.t('general.submit')}
          </Button>
        </Form.Item>
      </RoleBasedUi>
    </div>
  );
};

export default NavigationButtons;
