import css from './Checkbox.module.css';
import React from 'react';
import cls from 'classnames';
import { Checkbox as CheckboxAntd } from 'antd';

const Checkbox = ({ className, children, ...props }) => (
  <CheckboxAntd className={cls(css.container, className)} {...props}>
    {children}
  </CheckboxAntd>
);

export default Checkbox;
