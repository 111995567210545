import React from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';

import { DATE_TIME_FORMAT } from '../../constants/index';

const StartOnDate = ({
  id,
  disabled,
  onDate: { date, options },
  handleChange,
}) => {
  return (
    <div className="col-6 col-sm-3">
      <DatePicker
        className="full-width"
        format={DATE_TIME_FORMAT}
        disabled={disabled}
        defaultValue={moment(new Date(), DATE_TIME_FORMAT)}
        onChange={(inputDate) => {
          const editedEvent = {
            target: {
              value: moment(inputDate).format(DATE_TIME_FORMAT),
              name: 'start.onDate.date',
            },
          };

          handleChange(editedEvent);
        }}
      />
    </div>
  );
};

export default StartOnDate;
