import {PropertyType} from 'utils/enums';

const serializeAppointment = ({concern, condition}, date, vet, selectedTimeslot) => {
  return {
    vetComment: condition,
    date,
    vetId: vet?.uid,
    timeSlotId: selectedTimeslot.uid,
  };
};

const serializeAddress = (address) => {
  return {
    apartment: address?.apartment,
    city: address?.city,
    comment: address?.comment,
    countryCode: address?.countryCode || address?.country?.code,
    description: address?.description,
    floor: Number(address?.floor) || 0,
    houseNumber: Number(address?.houseNumber) || 0,
    propertyType: PropertyType[address?.propertyType],
    street: address?.street,
    uid: address?.uid,
    zipCode: address?.zipCode,
    lat: address?.lat,
    lng: address?.lng,
  };
};

const serializeCustomer = ({uid, firstName, lastName, email, phoneNumber, phonePrefix}, address) => {
  return {
    uid,
    firstName,
    lastName,
    email,
    phonePrefix,
    phoneNumber,
    address: serializeAddress(address),
  };
};

const statusMap = {
  unknown: null,
  neutered: true,
  no_neutered: false,
};
export const prepareAppPets = (appPets, isForVet) => {
  return appPets.map((appPet) => ({
    pet: {
      uid: appPet.pet.uid,
      name: appPet.pet.name,
      type: appPet.pet.type?.uid || null,
      birthDate: appPet.pet.birthDate,
      isSterilized: statusMap[appPet.pet.isSterilized],
      gender: appPet.pet.gender,
      update: appPet.pet.update,
    },
    isFirstTime: appPet.firstTime,
    comment: isForVet ? '' : appPet.comment,
    privateComment: isForVet ? appPet.comment : '',
    concerns: appPet.concerns.map((c) => c.uid),
    files: appPet.files,
  }));
};

export const serializePetList = (petList) => {
  return petList?.map((pet, i) => ({
    uid: pet.uid,
    name: pet.name,
    type: pet.type?.uid || null,
    birthDate: pet.birthDay,
    isSterilized: statusMap[pet.status] || null,
    gender: pet.gender,
  }));
};

export const serializeSignUpFlow = (appointment, customer, address, pets, date, vet, selectedTimeslot, informClient, petsToCreate) => {
  return {
    ...serializeAppointment(appointment, date, vet, selectedTimeslot),
    user: serializeCustomer(customer, address),
    pets: serializePetList(petsToCreate, []),
    appointmentPets: prepareAppPets(pets, true),
    isFirstTime: appointment?.isFirstTime || false,
    firstTimePetsCount: appointment?.firstTimePetsCount || 0,
    sendEmail: informClient,
  };
};

export const processAddress = (address) => {
  if (!address) return {};
  return ({
    'countryCode': address.country.code,
    'zipCode': address.zipCode,
    'city': address.city,
    'houseNumber': address.houseNumber,
    'floor': address.floor || 0,
    'apartment': address.appartment || null,
    'lat': address.lat,
    'lng': address.lng,
    'description': address.description,
  });
};
