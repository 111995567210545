import { useAuthContext } from 'common/index.js';

import { Rules } from 'constants/permissions';

const RoleBasedCondition = (permission) => {
  const auth = useAuthContext();
  const role = auth?.me?.role?.toLowerCase();

  return Rules[role]?.includes(permission);
};

export default RoleBasedCondition;
