import React from 'react';
import cls from 'classnames';
import css from './Label.module.css';
import text from 'styles/text.module.css';

const Label = ({ label, children, type = 'label', className, ...props }) => {
  return (
    <span {...props} className={cls(css.container, text[type], className)}>
      {children}
    </span>
  );
};

export default Label;
