import React from 'react';
import {useLocalizationContext} from 'common';
import Modal from 'react-modal';

import {customStyles} from './components';

const CustomModal = ({
  isOpen,
  onRequestClose,
  contentLabel,
  children,
  scrollable = false,
  isMobile = false,
  isLaptop,
  advancedInfoOpen,
  isSmallHeight,
  isMediumHeight,
  autoHeight,
  missed,
  withOverflow,
  custom,
  width,
}) => {
  const {rtl: isRtl} = useLocalizationContext();
  return (
    <Modal
      isOpen={isOpen}
      contentLabel={contentLabel}
      style={customStyles(
          scrollable,
          isMobile,
          isLaptop,
          advancedInfoOpen,
          isRtl,
          isSmallHeight,
          isMediumHeight,
          missed,
          autoHeight,
          withOverflow,
          width,
          custom,
      )}
      onRequestClose={onRequestClose}
      bodyOpenClassName="ReactModal__Body--open"
    >
      {children}
    </Modal>
  );
};

export default CustomModal;
