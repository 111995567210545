import React, {useMemo, useState} from 'react';
import css from './Recommender.module.css';
import {message} from 'antd';
import cls from 'classnames';
import {useMutation, useQuery} from '@apollo/client';
import moment from 'moment-timezone';
import startCase from 'lodash/startCase.js';
import orderBy from 'lodash/orderBy.js';
import {useMediaQuery} from 'react-responsive';
import {getFilteredTimeSlotsByVetUidQuery, rescheduleVisitMutation} from '../../../gql';
import {getStartEndPoints} from './recomender.functions.js';
import useVetList from '../../../pages/dashboard/vets/useVetList';
import text from '../../../styles/text.module.css';
import {ReactComponent as LeftArrow} from '../../../assets/icons/arrow-left-blue.svg';
import {months} from '../../../utils/libraryHelpers';
import MapView from '../../common/Maps/MapView';
import Button from '../../../library/Button/Button';
import Checkbox from '../../../library/Checkbox/Checkbox';
import Selector from '../../../library/selector/Selector';
import {TimeslotsFilterSort} from '../../../constants/enums';
import {TimeslotRecomender as Timeslot} from './Timeslot';
import {Providers} from './Providers';
import petSvg from '../../../assets/icons/pet.svg';
import Label from '../../../library/Label/Label.jsx';

export const Recommender = (props) => {
  const {theme, timeslotActions, filters, displayFooter, appointment, vetId, currentAddress, close} = props;

  const [displayFilters, setDisplayFilters] = useState(false);
  const isColumnView = useMediaQuery({
    maxWidth: 1000,
  });

  const [isLoading, setLoading] = useState(false);
  const [selectedDate, _setSelectedDate] = useState(moment().startOf('day'));
  const [selectedSort, setSelectedSort] = useState(options[0]);
  const setSelectedDate = (value) => {
    _setSelectedDate(value);
    timeslotActions.setActiveTimeslot();
  };

  // vets
  const {vets = []} = useVetList();

  // timeslots
  const {data, loading: loadingTimeslots} = useQuery(
      getFilteredTimeSlotsByVetUidQuery,
      {
        variables: {
          data: {
            uid: vetId,
            userAddress: currentAddress,
            startDate: moment(selectedDate.format())
                .parseZone()
                .tz('UTC', true)
                .toDate(),
            endDate: moment(moment(selectedDate.format()).endOf('month').format())
                .parseZone()
                .tz('UTC', true)
                .toDate(),
            isFull: true,
            isLocked: true,
            filterByAddress: !filters.displayTSNotInArea,
          },
        },
      },
  );

  const filteredTimeslots = useMemo(() => {
    if (!loadingTimeslots) {
      const timeslots = data?.getFilteredTimeSlotsByVetUid || [];
      const filtered = timeslots.filter((t) => {
        if (!filters.displayFullTS && t.timeSlot.isFull) return false;
        if (!filters.selectedVets.includes(t.timeSlot.vet.uid)) return false;
        return true;
      });
      if (!filtered.some((t) => t.timeSlot.uid === timeslotActions.activeTimeslot?.uid)) {
        timeslotActions.setActiveTimeslot();
      }
      let sorted = filtered;
      switch (selectedSort.value) {
        case TimeslotsFilterSort.SUGGESTED:
        case TimeslotsFilterSort.DISTANCE_NEAREST:
          sorted = orderBy(
              sorted,
              [
                'additionalDistanceInMi',
                (item) =>
                  moment(
                      item.timeSlot.date,
                      'YYYY-MM-DD',
                  )
                      .toDate()
                      .getTime(),
              ],
              ['asc', 'asc']);
          break;
        case TimeslotsFilterSort.DATE_EARLIEST:
          sorted = orderBy(
              sorted,
              [
                (item) =>
                  moment(
                      item.timeSlot.date,
                      'YYYY-MM-DD',
                  )
                      .toDate()
                      .getTime(),
                'additionalDistanceInMi',
              ],
              ['asc', 'asc'],
          );
      }
      return sorted.map((ts) => {
        const additionalDistanceInMi = ts?.route?.route
        ? (ts?.route?.distance) ?? (ts.defaultDistance)
        : (ts.defaultDistance);
        const additionalTime = ts?.route?.route
        ? (ts?.route?.additionalTime) ?? (ts.defaultAdditionalTime)
        : (ts.defaultAdditionalTime);
        return {
          ...ts,
          additionalTime,
          additionalDistanceInMi,
        };
      });
    }
    return [];
  },
  [loadingTimeslots, timeslotActions.activeTimeslot?.uid, data?.getFilteredTimeSlotsByVetUid, filters.displayFullTS, filters.selectedVets, selectedSort],
  );
  const [points, activeTs] = useMemo(() => {
    const activeTimeslot = filteredTimeslots?.find((ts) => ts.timeSlot.uid === timeslotActions.activeTimeslot?.uid);
    return [getStartEndPoints(activeTimeslot), activeTimeslot];
  }, [timeslotActions.activeTimeslot?.uid, filteredTimeslots]);
  // reschedule
  const [reschedule] = useMutation(rescheduleVisitMutation);
  const rescheduleVisit = () => {
    setLoading(true);
    reschedule({
      variables: {
        record: {
          date: moment(activeTs.timeSlot.date).format('YYYY-MM-DD'),
          appointmentId: appointment.uid,
          timeSlotId: activeTs.timeSlot.uid,
        },
      },
      refetchQueries: ['getCalendarEvents', 'getTimeSlots'],
    }).then(() => {
      setLoading(false);
      message.success('Appointment rescheduled successfully');
      close();
    }).catch((e) => {
      message.error(e.message);
      setLoading(false);
    });
  };

  const decreaseMonth = () => {
    const prevMonth = moment(selectedDate.format())
        .subtract(1, 'months')
        .startOf('month')
        .format();
    if (prevMonth < moment().format()) {
      setSelectedDate(moment().startOf('day'));
    } else {
      setSelectedDate(moment(prevMonth));
    }
  };
  const increaseMonth = () => {
    const nextMonth = moment(selectedDate.format())
        .add(1, 'months')
        .startOf('month')
        .format();
    setSelectedDate(moment(nextMonth));
  };

  const {nextMonthButtonDisabled, prevMonthButtonDisabled} = useMemo(() => {
    const today = moment();
    const left = selectedDate.year() < today.year() || (selectedDate.year() === today.year() && today.month() >= selectedDate.month());
    const right = selectedDate.format() > today.add(2, 'months').format();
    return {
      nextMonthButtonDisabled: right,
      prevMonthButtonDisabled: left,
    };
  }, [selectedDate]);

  return <>
    <div className={cls(css.container, {[css.noPadding]: theme === 'light'})}>
      <div className={css.filtersColumn}>
        <span
          className={cls(css.filters, text.s16w6l18)}
          onClick={() => isColumnView && setDisplayFilters(!displayFilters)}
        >
          Filters
          {isColumnView && <LeftArrow className={cls(css.arrowFilter, {[css.arrowDown]: displayFilters})} />}
        </span>
        <div className={cls(css.filtersContent,
            {[css.filtersContentHide]: isColumnView && !displayFilters})}>
          <Checkbox
            disabled={isLoading}
            className={css.checkBox}
            checked={filters.displayFullTS}
            onChange={(e) => filters.setFullTS(e.target.checked)}><span className={text.s14w4l18fI}>Show full Timeslots</span></Checkbox>
          <Checkbox
            disabled={isLoading}
            className={css.checkBox}
            checked={filters.displayTSNotInArea}
            onChange={(e) => filters.setDisplayTSNotInArea(e.target.checked)}><span className={text.s14w4l18fI}>Show timeslots not in the area</span></Checkbox>
          <Checkbox
            disabled={isLoading}
            className={css.checkBox}
            checked={filters.displayVets}
            onChange={(e) => filters.setDisplayVets(e.target.checked)}><span className={text.s14w4l18fI}>Show other caregivers</span></Checkbox>
          <Providers
            displayVets={filters.displayVets}
            isLoading={isLoading}
            vets={vets}
            selectedVets={filters.selectedVets}
            setSelectedVets={filters.setSelectedVets}
          />
        </div>
      </div>
      <div className={css.timeslotsWrapper}>
        <div className={css.row}>
          <div className={css.monthSelectorWrapper}>
            <button
              className={cls(css.arrow, {[css.arrowDisabled]: prevMonthButtonDisabled})}
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
            >
              <LeftArrow />
            </button>
            <div className={css.month}>{startCase(months[selectedDate.month()])}</div>
            <button
              className={cls(css.arrow, css.arrowRight, {[css.arrowDisabled]: nextMonthButtonDisabled})}
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
            >
              <LeftArrow />
            </button>
          </div>
          <div className={css.selectorWrapper}>
            <span className={cls(css.sort, text.s12w400l24)}>Sort by: </span>
            <Selector
              value={selectedSort}
              options={options}
              onChange={(value) => {
                setSelectedSort(value);
              }}
              hideLabel
              noError
              isForTimeSlot
            />
          </div>
        </div>
        <div className={css.timeslots}>
          {filteredTimeslots.map((ts) => <Timeslot
            active={ts.timeSlot.uid === timeslotActions.activeTimeslot?.uid}
            disabled={ts.timeSlot.uid === timeslotActions.defaultTimeslot?.uid}
            onClick={() => timeslotActions.setActiveTimeslot(ts.timeSlot)}
            key={ts.timeSlot.uid}
            timeslot={ts}/>)}
          {!loadingTimeslots && !filteredTimeslots.length ? <div className={css.petIcon}>
            <img src={petSvg} alt="bg"/>
            <Label>There are no timeslots available for the selected date and filters</Label>
          </div> : null}
        </div>
      </div>
      <div className={css.map}>
        <MapView
          currentAppointmentLocation={currentAddress}
          existingAppointments={activeTs?.route?.route?.appointmentRoutes}
          workingArea={activeTs?.timeSlot?.workingAreas?.[0]?.polygon?.area}
          startEndPoints={points}
        />
      </div>
    </div>
    {displayFooter ? <div className={css.footer}>
      {!timeslotActions.activeTimeslot?.uid && <div className={cls(css.error, text.s14w4l22)}>* Please select a time-slot</div>}
      <Button
        type="primary"
        colorScheme="blue"
        disabled={!timeslotActions.activeTimeslot?.uid || timeslotActions.activeTimeslot?.uid === timeslotActions.defaultTimeslot?.uid || isLoading}
        onClick={rescheduleVisit}>
        Reschedule
      </Button>
    </div>
      : null}
  </>;
};

const options = [
  {label: 'Suggested', value: 'suggested'},
  {label: 'Date', value: 'date_earliest'},
  {label: 'Distance', value: 'distance_nearest'},
];
