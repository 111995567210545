import React from 'react';
import { Input, Row, Col } from 'antd';
import { toPairs } from 'lodash';
import numericalFieldHandler from '../../../utils/numericalFieldHandler';
import translateLabel from '../../../utils/translateLabel';

const RepeatWeekly = ({
  id,
  weekly: { interval, days, options },
  handleChange,
  translations,
  disabled,
}) => {
  let daysArray = toPairs(days);
  if (options.weekStartsOnSunday) {
    daysArray = daysArray.slice(-1).concat(daysArray.slice(0, -1));
  }

  return (
    <div className="mt-20">
      <Row align="middle">
        <Col span="6">
          {translateLabel(translations, 'repeat.weekly.every')}
        </Col>
        <Col span="9">
          <Input
            id={`${id}-interval`}
            name="repeat.weekly.interval"
            aria-label="Repeat weekly interval"
            className="form-control"
            value={interval}
            disabled={disabled}
            onChange={numericalFieldHandler(handleChange)}
          />
        </Col>
        <Col span="6">
          <span className="ml-20">
            {translateLabel(translations, 'repeat.weekly.weeks')}
          </span>
        </Col>
      </Row>

      <Row className="mt-20" align="middle">
        {daysArray.map(([dayName, isDayActive]) => (
          <label
            htmlFor={`${id}-${dayName}`}
            key={dayName}
            className={`btn btn-primary ml-20 ${isDayActive ? 'active' : ''}`}
          >
            <Input
              type="checkbox"
              id={`${id}-${dayName}`}
              name={`repeat.weekly.days[${dayName}]`}
              className="form-control"
              checked={isDayActive}
              onChange={(event) => {
                const editedEvent = {
                  ...event,
                  target: {
                    ...event.target,
                    value: !isDayActive,
                    name: event.target.name,
                  },
                };

                handleChange(editedEvent);
              }}
            />
            {translateLabel(
              translations,
              `days_short.${dayName.toLowerCase()}`,
            )}
          </label>
        ))}
      </Row>
    </div>
  );
};

export default RepeatWeekly;
