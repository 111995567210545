import React, {useState} from 'react';
import cls from 'classnames';
import css from './AppointmentPetDetails.module.css';
import {petImages} from '../../../constants/pet';
import {
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
  MessageOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import Tag from '../../../library/Tag/Tag';
import {Typography} from 'antd';
import Button from '../../../library/Button/Button';
import Dropdown from '../../../library/Dropdown/Dropdown';

const AppointmentPetDetails = ({onEdit, onRemove, className, appPet}) => {
  const [ellipsis, setEllipsis] = useState(true);
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const concerns = [...(appPet.concerns || []), ...(appPet.userConcerns || [])];

  return <div className={css.container}>
    <div className={cls(css.row)}>
      <div className={cls(css.avatarWrapper)}>
        <img className={css.avatar} src={appPet?.pet?.avatarUrl || petImages[appPet?.pet?.type?.name || 'other']} alt="avatar"/>
      </div>
      <span className={css.name}>{appPet.pet.name}</span>
      <div className={css.kebab}>
        <Dropdown
          trigger={['click']}
          open={openSubMenu}
          onOpenChange={setOpenSubMenu}
          placement="bottomRight"
          dropdownRender={() => <section className={css.dropdown}>
            <Button
              className={css.editButton}
              type="text"
              onClick={() => {
                setOpenSubMenu(false);
                onEdit(appPet);
              }}
              icon={<EditOutlined />}
            >
              Edit Appointment
            </Button>
            <Button
              className={css.removeButton}
              type="text"
              icon={<DeleteOutlined />}
              onClick={() => onRemove(appPet)}
            >
              Remove Appointment
            </Button>
          </section>}
        >
          <Button icon={<MoreOutlined />} shape={'circle'} type={'text'}/>
        </Dropdown>
      </div>
    </div>
    <div className={cls(css.row, css.aiFlexStart, css.mt14)}>
      <div className={css.firstColumn}>
        <div className={css.iconWrapper}>
          <InfoCircleOutlined />
        </div>
      </div>
      <div className={css.stack}>
        {concerns.map((concern) => <Tag key={concern.uid}>{concern.value}</Tag>)}
      </div>
    </div>
    {appPet.comment && <div className={cls(css.row, css.aiFlexStart, css.mt14)}>
      <div className={css.firstColumn}>
        <div className={cls(css.iconWrapper, css.iconMessage)}>
          <MessageOutlined />
        </div>
      </div>
      <div className={css.stack}>
        <Typography.Paragraph
          className={css.comment}
          onClick={() => setEllipsis(!ellipsis)}
          ellipsis={ellipsis ? {rows: 3} : false}
        >
          {appPet.comment}
        </Typography.Paragraph>
      </div>
    </div>}
  </div>;
};

export default AppointmentPetDetails;
