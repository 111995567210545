import {useState, useCallback, useMemo, useEffect} from 'react';
import {useLazyQuery} from '@apollo/react-hooks';
import {serializeAddressDescription} from 'serializers/signUpFlow.serializer';
import {getVetTimeSlotsByUserAddressQuery, getFilteredTimeSlotsByVetUidQuery} from 'gql';
import {VetStatus, PropertyType} from 'constants/enums';
import moment from 'moment-timezone';


// FIXME: vet is just a workaround. we can jsut check the user type in abckend
const useCalendarSteps = (vet, client, isForVet, placeId, me, form, disableFetchOnSelect) => {
  const [address, setAddress] = useState('');
  const defaultRangeStart = moment().startOf('day');

  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTimeslot, setSelectedTimeslot] = useState(null);
  const [propertyType, setPropertyType] = useState(PropertyType.PrivateHome);

  const [getCalendarTimeSlots, {data: calendarTimeSlotsData}] = useLazyQuery(
      getVetTimeSlotsByUserAddressQuery,
      {
        fetchPolicy: 'cache-and-network',
      },
  );
  const [_getFilteredRecommendedTimeslots, {data: recommendedTimeslotsData, loading: loadingTimeSlots}] = useLazyQuery(
      getFilteredTimeSlotsByVetUidQuery,
      {
        fetchPolicy: 'cache-and-network',
      },
  );

  const fetchRecommenderTimeSlots = useCallback((vet, value, description, range, isFull, isLocked) => {
    _getFilteredRecommendedTimeslots({
      variables: {
        data: {
          uid: vet?.uid,
          userAddress: {
            ...value,
            houseNumber: Number(value.houseNumber),
            description,
          },
          startDate: range && range[0] && range[0] > defaultRangeStart.toDate()
            ? moment(range?.[0])
                .parseZone()
                .tz('UTC', true)
                .toDate()
            : moment(defaultRangeStart)
                .parseZone()
                .tz('UTC', true)
                .toDate(),
          endDate: range && range[1]
            ? moment(range[1])
                .parseZone()
                .tz('UTC', true)
                .toDate()
            : moment(moment().endOf('month'))
                .parseZone()
                .tz('UTC', true)
                .toDate(),
          isFull,
          isLocked,
        },
      },
    });
  }, []);

  const fetchCalendarTimeSlots = useCallback((vet, value, description) => {
    getCalendarTimeSlots({
      variables: {
        record: {
          uid: vet.uid,
          address: {
            ...value,
            houseNumber: Number(value.houseNumber),
            description,
          },
          isForCustomers: false,
        },
      },
    });
  }, []);

  const onSelect = useCallback((value) => {
    const description = serializeAddressDescription(value);
    let addr = {
      ...value,
      houseNumber: Number(value.houseNumber),
      description,
    };

    if (!isForVet && !placeId) {
      addr = {
        ...me?.address,
        ...addr,
      };
    }

    setAddress(addr);
    form.setFieldsValue({address: description});
    // if (!disableFetchOnSelect) {
    //   fetchRecommenderTimeSlots(vet, value, description, []);
    // }
    // fetchCalendarTimeSlots(vet, value, description);
  }, []);

  const onChange = useCallback((value) => {
    if (value.length <= 150) {
      setAddress({
        description: value,
      });
    }
  }, []);

  const addressError = useMemo(() => {
    if (isForVet) return !address?.lat || !address?.lng;

    return (
      !address?.houseNumber ||
          !address?.countryCode ||
          !address?.state ||
          !address.description ||
          address.description.length > 150 ||
          (address?.countryCode === 'US' && !address?.zipCode)
    );
  }, [address]);

  const vetIsOccupied = useMemo(() => {
    return vet?.status === VetStatus.OCCUPIED.toUpperCase();
  }, [vet]);

  useEffect(() => {
    if (address) {
      const serilizedUserAddress = {
        uid: address.uid,
        countryCode: address.country?.countryCode,
        city: address.city,
        street: address.street,
        state: address.state,
        houseNumber: address.houseNumber,
        zipCode: address.zipCode,
        floor: address.floor,
        apartment: address.apartment,
        description: address.description,
        comment: address.comment,
        lat: address.lat,
        lng: address.lng,
        propertyType: address.propertyType,
      };

      onSelect(serilizedUserAddress);
    }
  }, [client]);

  return {
    onSelect,
    onChange,
    addressError,
    address,
    setAddress,
    selectedDate,
    setSelectedDate,
    getCalendarTimeSlots,
    calendarTimeSlotsData,
    recommendedTimeslotsData,
    selectedTimeslot,
    setSelectedTimeslot,
    fetchCalendarTimeSlots,
    fetchRecommenderTimeSlots,
    vetIsOccupied,
    propertyType,
    setPropertyType,
    loadingTimeSlots,
  };
};

export default useCalendarSteps;
