import React, { useEffect, useState } from 'react';
import { Form, Button, Table, message, Popconfirm, Modal } from 'antd';
import { AddVetValidationScheme } from 'validations/validations';
import { useFormik } from 'formik';
import { useHistory, useParams } from 'react-router';

import VetPersonalData from 'components/vets-profile-components/vet-personal-data/VetPersonalData';
import { messages } from 'service/utils';

import useCreateVet from './useCreateVet';
import useGetVet from './useGetVet';
import './styles.css';

import { DASHBOARD, USERS, VETS } from 'common/constants/routeNames';
import { VetStatus } from 'common/constants/enums';
import { Permissions } from '../../../constants/permissions.js';
import RoleBasedUi from 'components/role-based-ui/RoleBasedUi.js';

const AddVet = () => {
  const { addNewVet } = useCreateVet();
  const history = useHistory();
  const { userId } = useParams();
  const { getVetByUidData, timeSlots, loading } = useGetVet(userId);
  const [isStatusChangeModalOpen, setIsStatusChangeModalOpen] = useState(false);

  const formik = useFormik({
    initialValues: {
      phonePrefix: getVetByUidData?.user?.phonePrefix || '',
      phoneNumber: getVetByUidData?.user?.phoneNumber || '',
      firstName: getVetByUidData?.user?.firstName || '',
      lastName: getVetByUidData?.user?.lastName || '',
      licenseNumber: getVetByUidData?.licenseNumber || '',
      socialNumber: getVetByUidData?.socialNumber || '',
      email: getVetByUidData?.user?.email || '',
      languageIds: getVetByUidData?.languages.map(({ uid }) => uid) || [],
      avatarUrl: getVetByUidData?.user?.avatarUrl || '',
      bio: getVetByUidData?.user?.bio || '',
      experience: getVetByUidData?.experience || null,
      mainCommuteMethod: getVetByUidData?.commuteMethod?.toLowerCase() || null,
      timeZone: getVetByUidData?.timezone || null,

      status: loading
        ? getVetByUidData?.status.toLowerCase()
        : getVetByUidData?.status.toLowerCase() || VetStatus.ACTIVE,

      gender: getVetByUidData?.gender || null,
      birthDate: getVetByUidData?.birthDate || null
    },
    enableReinitialize: true,
    validationSchema: AddVetValidationScheme,

    onSubmit: (values) => {
      const newValues = values;
      delete newValues.timeZone;

      addNewVet({ ...newValues, uid: userId });
    }
  });

  const onFinishFailed = (errorInfo) => {
    message.error('Check all fields!');
  };

  const onCancel = () => {
    history.push(DASHBOARD + USERS + VETS);
  };

  const checkStatusValidation = () => {
    if (formik.initialValues.status !== formik.values.status && userId) {
      setIsStatusChangeModalOpen(true);
    } else {
      formik.handleSubmit();
    }
  };

  const handleSubmit = () => {
    formik.handleSubmit();
  };

  return (
    <Form
      className="wrapper"
      autoComplete="off"
      onFinish={handleSubmit}
      onFinishFailed={onFinishFailed}>
      <div className="heading">{messages.t('vet.add_edit_new_vet')}</div>
      <VetPersonalData formik={formik} getVetByUidData={getVetByUidData} />

      <div className="buttons-wrapper">
        <Form.Item className="mr-10" wrapperCol={{ offset: 8, span: 16 }}>
          <Button onClick={onCancel}> {messages.t('general.cancel')}</Button>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          {userId ? (
            <Popconfirm
              title={messages.t('general.confirm_changes')}
              onConfirm={checkStatusValidation}>
              <Button type="primary">{messages.t('general.submit')}</Button>
            </Popconfirm>
          ) : (
            <RoleBasedUi permission={Permissions.UPDATE_VET}>
              <Button type="primary" htmlType="submit">
                {messages.t('general.submit')}
              </Button>
            </RoleBasedUi>
          )}
        </Form.Item>
      </div>
      <Modal
        open={isStatusChangeModalOpen}
        onOk={handleSubmit}
        onCancel={() => {
          setIsStatusChangeModalOpen(false);
        }}>
        <p>{messages.t('general.confirm_status_change')} </p>
      </Modal>
    </Form>
  );
};

export default AddVet;
