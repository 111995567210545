import React from 'react';
import cls from 'classnames';
import moment from 'moment-timezone';
import css from './Timeslot.module.css';
import text from '../../../../styles/text.module.css';
import colors from '../../../../styles/colors.module.css';
import {formatAppointmentDuration} from '../../../../common/utils/helpers';
import {Vet} from '../Vet';

export const TimeslotRecomender = ({timeslot, active, onClick, disabled}) => {
  const {
    additionalDistanceInMi,
    additionalTime,
    timeSlot: {uid, date, name, isFull, isLocked, startTime, endTime, vet},
  } = timeslot;
  return <div
    className={cls(css.timeslotItem, {
      [css.activeTimeslotItem]: active,
      [css.disabledTimeslotItem]: disabled,
    })}
    onClick={() => !disabled && onClick()}
  >
    {(isFull || isLocked) &&
      <div className={css.marks}>
        {isFull && <div className={cls(css.markFull, text.s12w7l20)}>Full</div>}
        {isLocked && <div className={cls(css.markLocked, text.s12w7l20)}>Locked</div>}
      </div>}
    <div className={css.distanceContainer}>
      <span className={text.s18w7l24}>{`+${Math.round(additionalDistanceInMi * 10) / 10} mi`}</span>
      <span className={cls(colors.cGray8c, text.s12w5l14)}>{`(~${formatAppointmentDuration(Math.round(additionalTime)).toLocaleLowerCase()})`}</span>
    </div>
    <div className={css.dateContainer}>
      <span className={text.s14w5l16}>{`${moment(startTime, 'h:mm A').format('h:mm A')} - ${moment(endTime, 'h:mm A').format('h:mm A')}`}</span>
      <span className={text.s12w4l20}>{moment(date).format('ddd, MMMM Do YYYY')}</span>
      <span className={cls(css.tsName, colors.cBlue, text.s12w5l18)}>{name}</span>
    </div>
    <div className={css.vetContainer}>
      <span className={cls(css.vetLabel, colors.cGray8c, text.s12w4l20)}>Caregiver</span>
      <Vet vet={vet} />
    </div>
  </div>;
};
