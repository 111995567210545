import moment from 'moment';
import uniqueId from 'lodash/uniqueId';
import {rrulestr} from 'rrule';
import dayjs from 'dayjs';

export const getOptions = (arr, map = {label: 'value', value: 'uid'}) =>
  arr.map(((v) => ({
    value: v[map.value],
    label: typeof map.label === 'function' ? map.label(v) : v[map.label],
  })));

export const birthDayDateToNumbers = (date) => {
  if (!date || !moment(date).isValid()) return {year: undefined, month: undefined, day: undefined};
  const current = moment();
  const target = moment(date);
  const year = current.diff(target, 'year');
  const month = current.subtract(year, 'year').diff(target, 'month');
  const day = current.subtract(month, 'month').diff(target, 'day');
  return {year, month, day};
};

export const birthDayNumbersToDate = ({year = 0, month = 0, day = 0}) => {
  if (!year && !month && !day) return null;
  const current = moment();
  return current.subtract(year, 'year').subtract(month, 'month').subtract(day, 'day');
};

export const prepareAppPetFromForm = (values) => {
  return {
    _id: values._id || uniqueId(),
    uid: values.uid || undefined,
    pet: {
      _id: values.pet._id || uniqueId(),
      uid: values.pet.uid,
      name: values.pet.name,
      type: values.pet.type.value ? {
        uid: values.pet.type.value,
        name: values.pet.type.label,
      } : null,
      gender: values.pet.gender.value || null,
      isSterilized: values.pet.isSterilized,
      birthDate: birthDayNumbersToDate(values.pet) || values.pet.birthDate || null,
    },
    concerns: values.concerns.map((c) => ({uid: c.value, value: c.label})),
    firstTime: values.firstTime,
    files: values.files,
    comment: values.comment,
  };
};

export const timeSlotsToDateObject = (
    timeSlots,
) => {
  const datesObj = {};
  // eslint-disable-next-line no-unused-expressions
  timeSlots.forEach((slot) => {
    if (slot.rule) {
      const oneYearFromNow = new Date();
      oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);

      let twoWeeksAfterToday = new Date(Date.now() + 12096e5);

      const startFrom = new Date();

      // startFrom.setDate(startFrom.getDate() + 1);

      twoWeeksAfterToday = new Date();
      twoWeeksAfterToday.setDate(twoWeeksAfterToday.getDate() + 60); // 8 weeks


      // rrulestr(`DTSTART;TZID=Etc/GMT-12:20231122T120000;\n RRULE:FREQ=DAILY;COUNT=1;INTERVAL=1;WKST=MO`).between(
      rrulestr(slot.rule).between(
          startFrom,
          twoWeeksAfterToday,
          false,
          (date) => {
          // set timeslot's hour and minute to date
            date.setHours(
                Number(slot.startTime.slice(0, slot.startTime.length - 6)),
            );
            date.setMinutes(
                Number(slot.startTime.slice(3, slot.startTime.length - 3)),
            );
            setDateToObject(datesObj, date, slot);
            return date;
          },
      );
    }
  });
  return datesObj;
};

const getDateFromRrule = (rule) => {
  const year = rule.substring(rule.indexOf(':') + 1, rule.indexOf(':') + 5);

  const month = rule.substring(rule.indexOf(':') + 5, rule.indexOf(':') + 7);

  const day = rule.substring(rule.indexOf(':') + 7, rule.indexOf(':') + 9);

  return `${year}-${month}-${day}`;
};
// create object { date : [timeSlots]}
const setDateToObject = (obj, date, slot) => {
  const formatedDate = dayjs(date).format('YYYY-MM-DD');

  // if key already exists just push timeslot
  if (formatedDate in obj) {
    obj[formatedDate] = [...obj[formatedDate], {...slot, date}];
  } else obj[formatedDate] = [{...slot, date}];
};

export const filterOption = (input, option) => {
  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

export const timeSlotIsFull = (
    timeSlot,
    appointmentRoutes,
) => {
  const lastAppointmentTime = 40;
  const workingMins = moment(timeSlot.endTime, 'h:mm:ss').diff(
      moment(timeSlot.startTime, 'h:mm:ss'),
      'minutes',
  );

  const occupiedTime = appointmentRoutes.reduce(
      (num, b) => num + b.duration + 20,
      0,
  );

  const isFullTimeslot =
    lastAppointmentTime + occupiedTime >= workingMins ||
    !!(
      appointmentRoutes.length &&
      timeSlot.maxAppointments &&
      timeSlot.maxAppointments <= appointmentRoutes.length
    );
  return isFullTimeslot;
};

export const timeSlotsFilterHelper = (tsArray) => {
  return tsArray.filter((ts) => !ts.routes.length || !timeSlotIsFull(ts, ts.routes[0].appointmentRoutes));
};

export const isValidAddress = (address = {}) => address.lat && address.lng;
