import React from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import 'moment/min/locales';

import { DATE_TIME_FORMAT } from '../../constants/index';

const EndOnDate = ({ id, handleChange }) => {
  return (
    <div className="mt-20">
      <DatePicker
        className="full-width"
        format={DATE_TIME_FORMAT}
        defaultValue={moment(new Date(), DATE_TIME_FORMAT)}
        inputProps={{
          id: `${id}-datetime`,
          name: 'end.onDate.date',
          readOnly: true,
        }}
        onChange={(inputDate) => {
          const editedEvent = {
            target: {
              value: moment(inputDate).format(DATE_TIME_FORMAT),
              name: 'start.onDate.date',
            },
          };

          handleChange(editedEvent);
        }}
      />
    </div>
  );
};

export default EndOnDate;
