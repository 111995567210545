import { PropertyType } from '../../constants/enums.js';
import { geocodeByPlaceId, getLatLng } from 'react-places-autocomplete';
import moment from 'moment';

const { google } = window;

export const genderOptions = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
  { label: 'Empty', value: null }
];

export const Genders = {
  M: 'male',
  F: 'female'
  // Other: 'other',
};

export const Statuses = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  DECEASED: 'deceased'
};

export const statusOptions = [
  { label: 'Active', value: Statuses.ACTIVE },
  { label: 'Inactive', value: Statuses.INACTIVE },
  { label: 'Deceased', value: Statuses.DECEASED }
];

const startPoint = (vet) => {
  if (vet?.uid) {
    const address = vet?.user?.userSettings?.find(
      (s) => s?.setting?.description === 'DEFAULT_START_POINT'
    )?.itemValue;

    if (!address) return {};

    return JSON.parse(address);
  }
};

const maxRecursionCount = 25;
let recursionCount = 0;
const geocodeAddress = async (address, vet) => {
  const vetAddress = startPoint(vet);

  const location = {
    lat: 32.4458719,
    lng: -80.865776
  };

  if (vetAddress) {
    location.lat = vetAddress?.lat;
    location.lng = vetAddress?.lng;
  }

  try {
    if (address) {
      const service = new google.maps.places.AutocompleteService();
      return new Promise((resolve, reject) => {
        service.getQueryPredictions(
          {
            input: address,
            location: new google.maps.LatLng(location.lat, location.lng),
            radius: 50000
          },
          async (suggestions) => {
            const addr = {
              countryCode: '',
              city: '',
              street: '',
              houseNumber: '',
              zipCode: '',
              description: suggestions?.[0]?.description || '',
              lat: 0,
              lng: 0
            };

            if (suggestions?.[0]?.description) {
              await geocodeByPlaceId(suggestions?.[0]?.place_id)
                .then((res) => {
                  getLatLng(res[0]).then(({ lat, lng }) => {
                    addr.lat = lat;
                    addr.lng = lng;
                    for (const component of res[0].address_components) {
                      const componentType = component.types[0];

                      switch (componentType) {
                        case 'street_number': {
                          addr.houseNumber = parseInt(component.short_name);
                          break;
                        }

                        case 'route': {
                          addr.street = component.long_name;
                          break;
                        }

                        case 'postal_code': {
                          addr.zipCode = component.long_name;
                          break;
                        }

                        case 'political': {
                          addr.city = component.long_name;
                          break;
                        }

                        case 'administrative_area_level_1':
                          addr.state = component.long_name;
                          break;

                        case 'locality':
                          addr.city = component.long_name;
                          break;

                        case 'country':
                          addr.countryCode = component.short_name;
                          break;
                        default: {
                          break;
                        }
                      }
                    }
                  });
                })
                .catch((e) => {
                  reject(e);
                });
              resolve({
                ...addr,
                placeId: suggestions?.[0]?.place_id
              });
              recursionCount = 0;
            } else {
              if (recursionCount < maxRecursionCount) {
                recursionCount++;
                const shortenedAddress = address
                  .split(' ')
                  .slice(0, -recursionCount)
                  .join(' ')
                  .trim();
                resolve(geocodeAddress(shortenedAddress.length > 0 ? shortenedAddress : ' ', vet));
              } else {
                resolve(addr);
              }
            }
          }
        );
      });
    }
  } catch (e) {
    console.log('error', e?.message);
    throw e;
  }
  return {};
};

const serializePhonePrefix = (phonePrefix) => {
  if (phonePrefix && phonePrefix?.trim()[0] === '+') return phonePrefix;
  return '+' + phonePrefix;
};

const serializePropertyType = (type) => {
  if (type === 'TRUE') return PropertyType.PrivateHome;
  return PropertyType.Apartment;
};

export const serializeUser = async (data, vet) => {
  let retryCount = 1;
  const RETRY_LIMIT = 5;
  let isError = false;
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const user = {
    firstName: data['firstName'],
    lastName: data['lastName'],
    phonePrefix: serializePhonePrefix(data['phonePrefix']),
    phoneNumber: data['phoneNumber'],
    email: data['email'],
    pets: []
  };
  if (data['externalId']) {
    user.externalId = data['externalId'];
  }

  do {
    try {
      user.address = {
        ...(await geocodeAddress(data['address'], vet)),
        apartment: data['appartment'],
        floor: data['floor'],
        comment: data['address_comments'],
        propertyType: serializePropertyType(data['isPrivateHouse'])
      };
      isError = false;
    } catch (e) {
      // error from google API - retry 5 time, and save empty address to show on map
      isError = true;
      user.address = {
        countryCode: '',
        city: '',
        street: '',
        houseNumber: '',
        zipCode: '',
        description: '',
        lat: 0,
        lng: 0,
        apartment: data['appartment'],
        floor: data['floor'],
        comment: data['address_comments'],
        propertyType: serializePropertyType(data['isPrivateHouse'])
      };
      console.log(e);
      console.log(
        'retry #' +
          retryCount +
          '(' +
          retryCount * 2 +
          ' secs throttle). issue uploading: ' +
          user.firstName +
          ' ' +
          user.lastName
      );
      await sleep(retryCount * 2000); // wait 2,4,6,8,10 seconds and rerun
      retryCount++;
      continue;
    }
  } while (isError && retryCount <= RETRY_LIMIT);

  const petNames = data['petName']?.split(',');
  const petTypes = data['petType']?.split(',');
  const petGenders = data['petGender']?.split(',');
  const petAges = data['petAge']?.split(',');
  const petSpayeds = data['spayed']?.split(',');
  const petIds = data['petExternalIds']?.split(',');
  const petStatuses = data['petStatuses']?.split(',');

  if (petNames?.length) {
    for (let i = 0; i < petNames?.length; i++) {
      const pet = {
        name: petNames[i] || petNames[0],
        type: petTypes[i] || petTypes[0],
        gender: Genders[petGenders[i]] || null,
        age: moment.utc(petAges[i], false)?.isValid() ? moment.utc(petAges[i], false) : undefined,
        spayed: petSpayeds[i] === 'TRUE',
        status: Object.values(Statuses).includes(petStatuses[i]) ? petStatuses[i] : Statuses.ACTIVE
      };
      if (petIds && petIds[i]) {
        pet.externalId = petIds[i];
      }
      user.pets.push(pet);
    }
  }

  return user;
};
