import React from 'react';
import { Input, Select, Row, Col } from 'antd';

import { DAYS } from '../../../constants/index';
import translateLabel from '../../../utils/translateLabel';

const { Option } = Select;

const RepeatMonthlyOnThe = ({
  id,
  mode,
  onThe,
  hasMoreModes,
  handleChange,
  translations,
  disabled,
}) => {
  const isActive = mode === 'on the';

  return (
    <Row>
      <Col span="2">
        {hasMoreModes && (
          <Input
            className="full-width"
            id={id}
            type="radio"
            name="repeat.monthly.mode"
            aria-label="Repeat monthly on the"
            value="on the"
            checked={isActive}
            disabled={disabled}
            onChange={() =>
              handleChange({
                target: { value: 'on the', name: 'repeat.monthly.mode' },
              })
            }
          />
        )}
      </Col>
      <Col span="6">
        {translateLabel(translations, 'repeat.monthly.on_the')}
      </Col>

      <Col span="6">
        <Select
          id={`${id}-which`}
          name="repeat.monthly.onThe.which"
          aria-label="Repeat monthly on the which"
          className="full-width"
          value={onThe.which}
          disabled={disabled || !isActive}
          onChange={(value) =>
            handleChange({
              target: { value, name: 'repeat.monthly.onThe.which' },
            })
          }
        >
          <Option value="First">
            {translateLabel(translations, 'numerals.first')}
          </Option>
          <Option value="Second">
            {translateLabel(translations, 'numerals.second')}
          </Option>
          <Option value="Third">
            {translateLabel(translations, 'numerals.third')}
          </Option>
          <Option value="Fourth">
            {translateLabel(translations, 'numerals.fourth')}
          </Option>
          <Option value="Last">
            {translateLabel(translations, 'numerals.last')}
          </Option>
        </Select>
      </Col>

      <Col span="6">
        <Select
          id={`${id}-day`}
          name="repeat.monthly.onThe.day"
          aria-label="Repeat monthly on the day"
          className="full-width"
          value={onThe.day}
          disabled={disabled || !isActive}
          onChange={(value) =>
            handleChange({
              target: { value, name: 'repeat.monthly.onThe.day' },
            })
          }
        >
          {DAYS.map((day) => (
            <Option key={day} value={day}>
              {translateLabel(translations, `days.${day.toLowerCase()}`)}
            </Option>
          ))}
        </Select>
      </Col>
    </Row>
  );
};

export default RepeatMonthlyOnThe;
