import React from 'react';
import { Select } from 'antd';

import RepeatMonthly from './Monthly/index';
import RepeatWeekly from './Weekly/index';
import RepeatDaily from './Daily/index';
import translateLabel from '../../utils/translateLabel';

const { Option } = Select;

const Repeat = ({
  id,
  repeat: { frequency, monthly, weekly, daily },
  handleChange,
  translations,
  disabled,
}) => {
  const isOptionSelected = (option) => frequency === option;
  return (
    <div>
      <div>
        <label htmlFor={`${id}-frequency`} className="col-form-label">
          <strong>{translateLabel(translations, 'repeat.label')}</strong>
        </label>
      </div>
      <Select
        className="full-width"
        value={frequency}
        disabled={disabled}
        onChange={(value) =>
          handleChange({ target: { value, name: 'repeat.frequency' } })
        }
      >
        <Option value="Monthly">
          {translateLabel(translations, 'repeat.monthly.label')}
        </Option>
        <Option value="Weekly">
          {translateLabel(translations, 'repeat.weekly.label')}
        </Option>
        <Option value="Daily">
          {translateLabel(translations, 'repeat.daily.label')}
        </Option>
      </Select>
      {isOptionSelected('Monthly') && (
        <RepeatMonthly
          id={`${id}-monthly`}
          monthly={monthly}
          handleChange={handleChange}
          translations={translations}
          disabled={disabled}
        />
      )}
      {isOptionSelected('Weekly') && (
        <RepeatWeekly
          id={`${id}-weekly`}
          weekly={weekly}
          handleChange={handleChange}
          translations={translations}
          disabled={disabled}
        />
      )}
      {isOptionSelected('Daily') && (
        <RepeatDaily
          id={`${id}-daily`}
          daily={daily}
          handleChange={handleChange}
          translations={translations}
          disabled={disabled}
        />
      )}
    </div>
  );
};

export default Repeat;
