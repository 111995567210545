import React from 'react';
import { Select } from 'antd';
import EndAfter from './After';
import EndOnDate from './OnDate';

import translateLabel from '../../utils/translateLabel';

const { Option } = Select;
const End = ({
  id,
  end: { mode, after, onDate, options },
  disabled,
  handleChange,
  translations,
}) => {
  const isOptionAvailable = (option) =>
    !options.modes || options.modes.indexOf(option) !== -1;
  const isOptionSelected = (option) => mode === option;

  return (
    <div>
      <div>
        <div>
          <label htmlFor={id}>
            <strong>{translateLabel(translations, 'end.label')}</strong>
          </label>
        </div>
        <div>
          <Select
            className="full-width"
            name="end.mode"
            id={id}
            disabled={disabled}
            value={mode}
            onChange={(value) =>
              handleChange({ target: { value, name: 'end.mode' } })
            }
          >
            {isOptionAvailable('Never') && (
              <Option value="Never">
                {translateLabel(translations, 'end.never')}
              </Option>
            )}
            {isOptionAvailable('After') && (
              <Option value="After">
                {translateLabel(translations, 'end.after')}
              </Option>
            )}
            {isOptionAvailable('On date') && (
              <Option value="On date">
                {translateLabel(translations, 'end.on_date')}
              </Option>
            )}
          </Select>
        </div>

        {isOptionSelected('After') && (
          <EndAfter
            id={`${id}-after`}
            after={after}
            handleChange={handleChange}
            translations={translations}
          />
        )}
        {isOptionSelected('On date') && (
          <EndOnDate
            id={`${id}-onDate`}
            onDate={onDate}
            handleChange={handleChange}
            translations={translations}
          />
        )}
      </div>
    </div>
  );
};

export default End;
