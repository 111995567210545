import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { removePaymentCardMutation } from 'gql';

import { DeleteTwoTone, PlusSquareTwoTone } from '@ant-design/icons';
import { Popconfirm } from 'antd';

import useRemoveSubscription from './useRemoveSubscription';
import { messages } from 'service/utils';

const useColumns = (refetchPetOwner) => {
  const { removeSubscription } = useRemoveSubscription(refetchPetOwner);
  const [removeCard] = useMutation(removePaymentCardMutation);

  const removeSubscriptionCard = async (cardId) => {
    try {
      await removeCard({
        variables: {
          record: {
            uid: cardId
          }
        }
      });
      refetchPetOwner();
    } catch (err) {
      console.error(err);
    }
  };

  const subscriptionsColumn = [
    {
      title: 'Pet Name',
      dataIndex: 'petName',
      key: 'petName',
      align: 'center',
      render: (text, record) =>
        !!record?.subscriptionPets.length && (
          <div>
            {record?.subscriptionPets[0].pet?.name}
            {record?.subscriptionPets.length > 1 && ' ...'}
          </div>
        )
    },
    {
      title: 'Type',
      dataIndex: 'subscriptionType',
      key: 'subscriptionType',
      align: 'center',
      render: (text, record) => <div>{record?.subscriptionType?.title}</div>
    },
    {
      title: 'Vet Name',
      dataIndex: 'vet',
      key: 'vet',
      align: 'center',
      render: (text, record) => (
        <div>
          {record?.vet?.user?.firstName} {record?.vet?.user?.lastName}
        </div>
      )
    },
    {
      title: '',
      key: 'action',
      dataIndex: 'action',
      align: 'center',
      render: (text, record) => (
        <div>
          <Popconfirm
            title={messages.t('users.delete_subscription_confirm')}
            onConfirm={() => removeSubscription(record.uid)}
            okText={messages.t('general.yes')}
            cancelText={messages.t('general.no')}
            placement="topRight"
          >
            <DeleteTwoTone className="fontSize-17" />
          </Popconfirm>
        </div>
      )
    }
  ];

  const cardColumn = [
    {
      title: 'Card Number',
      dataIndex: 'cardMask',
      key: 'cardMask',
      align: 'center'
    },
    {
      title: 'Card Type',
      dataIndex: 'cardType',
      key: 'cardType',
      align: 'center'
    },

    {
      title: '',
      key: 'action',
      dataIndex: 'action',
      align: 'center',
      render: (text, record) => (
        <div>
          <Popconfirm
            title={messages.t('users.delete_card_confirm')}
            onConfirm={() => {
              removeSubscriptionCard(record.uid);
            }}
            okText={messages.t('general.yes')}
            cancelText={messages.t('general.no')}
            placement="topRight"
          >
            <DeleteTwoTone className="fontSize-17" />
          </Popconfirm>
        </div>
      )
    }
  ];
  return { subscriptionsColumn, cardColumn };
};

export default useColumns;
