import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { DASHBOARD } from 'constants/routeNames';

const AuthRoute = ({ component: Component, authenticated, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      authenticated === true ? (
        <Redirect to={DASHBOARD} />
      ) : (
        <Component {...props} />
      )
    }
  />
);

export default AuthRoute;
