export const customStyles = (
    scrollable,
    isMobile,
    isLaptop,
    advancedInfoOpen,
    isRtl,
    isSmallHeight,
    isMediumHeight,
    missed,
    autoHeight,
    withOverflow,
    width = null,
    custom = {},
) => {
  return ({
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.25)',
      overflowY: 'auto',
      zIndex: 999,
    },
    content: {
      padding: 0,
      right: 'auto',
      bottom: 'auto',
      left: '50%',
      top: scrollable ? 'auto' : '50%',
      transform: scrollable ? 'translate(-50%)' : 'translate(-50%, -50%)',
      marginLeft: 'auto',
      marginTop: isSmallHeight
        ? '2rem'
        : isMediumHeight
          ? '3.5rem'
          : scrollable
            ? '5rem'
            : 0,
      marginRight: 'auto',
      borderRadius: isMobile ? '1.6rem' : isLaptop ? '0.8rem' : '1.6rem',
      border: missed ? '1px solid #E44E61' : '1px solid rgb(204, 204, 204)',
      marginBottom: isMobile ? 0 : '4rem',
      overflow: 'initial',
      boxShadow: '0 3px 10px 0 rgba(0 0 0, 0.16)',
      backgroundColor: '#ffffff',
      width: width || '90%',
      maxWidth: '1500px',
      height: autoHeight ? 'auto' : isMobile ? '100%' : 'auto',
      overflowX: isMobile ? withOverflow ? 'initial' : 'hidden' : 'initial',
      maxHeight: isSmallHeight
        ? 'calc(100% - 4rem)'
        : isMediumHeight
          ? 'calc(100% - 7rem)'
          : 'calc(100% - 9rem)',
      overflowY: isMobile ? withOverflow ? 'initial' : 'scroll' : advancedInfoOpen ? 'auto' : 'unset',
      direction: isRtl ? 'rtl' : 'ltr',
      ...custom,
    },
  });
}
