import React, {memo} from 'react';
import {messages} from 'service/utils';
import {Col, Form, Row, Input} from 'antd';
import ButtonWithIcon from 'components/common/button-with-icon/ButtonWithIcon';
import {PropertyType} from 'constants/enums';
import {ReactComponent as HomeIcon} from 'assets/icons/home-icon.svg';
import {ReactComponent as HomeDarkIcon} from 'assets/icons/home-dark.svg';
import {ReactComponent as BuildingIcon} from 'assets/icons/building-white.svg';
import {ReactComponent as BuildingDarkIcon} from 'assets/icons/building-dark.svg';
import './style.css';

const AdditionalInfo = ({propertyType, setPropertyType, isForVet}) => {
  const {TextArea} = Input;
  return (
    <div className='additional-info-wrapper'>
      <p className='additional-info'>{messages.t('recommender.address_details.property_type')}</p>
      <div className='property-type-wrapper'>
        <ButtonWithIcon
          size='big'
          text={messages.t('recommender.address_details.private_house')}
          active={propertyType === PropertyType.PrivateHome}
          onClick={() => setPropertyType(PropertyType.PrivateHome)}
          icon={propertyType === PropertyType.PrivateHome ? <HomeIcon /> : <HomeDarkIcon />}
        />
        <ButtonWithIcon
          size='medium'
          text={messages.t('recommender.address_details.apartment')}
          active={propertyType === PropertyType.Apartment}
          onClick={() => setPropertyType(PropertyType.Apartment)}
          icon={propertyType === PropertyType.Apartment ? <BuildingIcon /> : <BuildingDarkIcon />}
        />
      </div>
      {propertyType === PropertyType.Apartment && (
        <Row gutter={12} className="property-details">
          <Col sm={12} md={12}>
            <Form.Item
              label={messages.t('floor')}
              name='floor'
              rules={[{required: propertyType === PropertyType.Apartment}, {whitespace: true}]}
              stringMode={true}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col sm={12} md={12}>
            <Form.Item label={messages.t('recommender.address_details.flat')}
              name='apartment'
              rules={[{required: propertyType === PropertyType.Apartment}, {
                max: 5,
                message: messages.t('too.much.symbols'),
              }, {whitespace: true}]}>
              <Input maxLength={5} />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Form.Item labelCol={{span: 24}} wrapperCol={{span: 12}}label={isForVet ? messages.t('recommender.address_details.address_comments') : messages.t('comment')} name='userComment' rules={[{max: 350, message: messages.t('too.much.symbols')}]}>
        <TextArea rows={4} placeholder={isForVet ? messages.t('recommender.scheduling_stepper.vet_special_instructions_text') : messages.t('comment.text')} maxLength={350} />
      </Form.Item>
    </div>
  );
};

export default memo(AdditionalInfo);
