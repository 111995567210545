import React from 'react';
import styles from './MainLayout.module.css';
import { Spin } from 'antd';

const MainLayout = ({ children }) => {
  return (
    <Spin size="large" spinning={false}>
      <div className={styles.mainContainer}>{children}</div>
    </Spin>
  );
};

export default MainLayout;
