import React from 'react';
import css from './AddressInput.module.css';
import AddressAutocomplete from '../../address-autocomplete/AddressAutocomplete.jsx';

export const AddressInput = (props) => {
  const {onChange, onSelect, address, addressError, isForVet} = props;
  return <AddressAutocomplete
    onChange={onChange}
    onSelect={onSelect}
    address={address}
    isForZipCode
    isForCalendar
    error={address && !!Object.keys(address).length && addressError}
    isForCompleteProfile={isForVet}
  />;
};
