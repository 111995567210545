import React, { useState } from 'react';
import useVets from '../../components/customers/useVets.js';
import { useQuery } from '@apollo/react-hooks';
import { getPetTypesQuery } from '../../gql/index.js';
import useUploadUsers from './useUploadUsers.js';
import LoaderWrapper from '../../components/ui/LoaderWrapper/LoaderWrapper.js';
import UploadUsers from './UploadUsers.jsx';

const UploadUsersContainer = () => {
  const [loading, setLoading] = useState(false);
  const { vetsData, vets, loading: loadingVets } = useVets();
  const { data: petTypes, loading: loadingPetTypes } = useQuery(getPetTypesQuery);
  const isLoading = loading || loadingVets || loadingPetTypes;
  const {
    errors,
    integrationId,
    editingKey,
    form,
    onSubmit,
    parseFile,
    usersList,
    selectedVetId,
    setSelectedVet,
    defaultCenter,
    onAddressSelect,
    onAddressChange,
    actions
  } = useUploadUsers(vets, setLoading);

  return (
    <LoaderWrapper isLoading={isLoading}>
      <UploadUsers
        integrationId={integrationId}
        errors={errors}
        vetsData={vetsData}
        vets={vets}
        petTypes={petTypes?.petTypes || []}
        setLoading={setLoading}
        editingKey={editingKey}
        form={form}
        onSubmit={onSubmit}
        parseFile={parseFile}
        usersList={usersList}
        selectedVetId={selectedVetId}
        setSelectedVet={setSelectedVet}
        defaultCenter={defaultCenter}
        onAddressSelect={onAddressSelect}
        onAddressChange={onAddressChange}
        actions={actions}
      />
    </LoaderWrapper>
  );
};

export default UploadUsersContainer;
