import React from 'react';
import {Spin} from 'antd';
import LoaderWrapperCustomSchedulePage from './CustomWrappers/LoaderWrapperCustomSchedulePage.js';
import LoaderWrapperOrangeLoader from './CustomWrappers/LoaderWrapperOrangeLoader/LoaderWrapperOrangeLoader';

const LoaderWrapper = ({isLoading, children, component = undefined}) => {
  if (!isLoading) {
    return children;
  }

  switch (component) {
    case 'create-appointment':
      return <LoaderWrapperOrangeLoader/>;
    case 'schedule-page': // exmaple. not in use
      return <LoaderWrapperCustomSchedulePage />;
    default:
      return (
        <div>
          <Spin/>
        </div>
      );
  }
};

export default LoaderWrapper;
