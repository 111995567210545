import {useState, useCallback, useEffect, useMemo} from 'react';
import {useMutation, useLazyQuery} from '@apollo/client';
import {getPetOwnersQuery, signUpFlowMutation} from 'gql';
import {message} from 'antd';

import useAuth from 'common/service/auth/useAuth.js';
import {messages} from 'service/utils';

const useNewAppointmentModal = (
    current,
    setCurrent,
    stepsCount,
    onRequestClose,
    setAddress,
    addressError,
    selectedTimeslot,
    selectedClient,
    setSelectedClient,
    ignoreFloorAppartment,
    form,
) => {
  const auth = useAuth();
  const [petsToCreate, setPetsToCreate] = useState([]);
  const [clientToEdit, setClientToEdit] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const [petList, setPetList] = useState([]);
  const [clients, setClients] = useState([]);
  const [search, setSearch] = useState('');
  const [total, setTotal] = useState(50);
  const [page, setPage] = useState(1);
  const [getPetOwners] = useLazyQuery(getPetOwnersQuery);
  const vetAdminUserId = useMemo(() => {
    return auth?.me?.uid;
  }, [auth?.me]);

  const fetchPetOwners = (getNewData) => {
    try {
      return getPetOwners({
        variables: {
          record: {
            page: getNewData ? 1 : page,
            search,
            take: 10,
            countries: [],
            skipAppointmentPets: true,
            vetAdminUserId: vetAdminUserId,
          },
        },
        skip: !auth?.me?.role,
        fetchPolicy: 'no-cache',
      }).then((body) => {
        setPage(body.data.getPetOwners?.page + 1);
        if (search.length || getNewData) {
          setClients(body?.data?.getPetOwners?.petOwners);
        } else {
          setClients([...clients, ...body.data.getPetOwners.petOwners]);
        }
        setTotal(body.data.getPetOwners.total);
      }).catch((e) => e = e);
    } catch (e) {
      console.log(e);
      setClients([]);
    }
  };

  const [signUp] = useMutation(signUpFlowMutation);

  // Reset selected client if clients list changes, and It's not available in the new list
  useEffect(() => {
    if (selectedClient?.uid && clients.length) {
      const client = clients.find((client) => client.user?.uid === selectedClient?.user?.uid);
      if (!client) setSelectedClient(null);
    }
  }, [selectedClient, clients]);

  useEffect(() => {
    if (vetAdminUserId) {
      fetchPetOwners().then(() => setLoading(false));
    }
  }, [vetAdminUserId]);

  useEffect(() => {
    if (vetAdminUserId) {
      fetchPetOwners(true).then(() => setLoading(false));
    }
  }, [search, vetAdminUserId]);

  const isNextButtonDisabled = useMemo(() => {
    if (current == 1) {
      const firstStepValues = form.getFieldsValue(['floor', 'apartment']);
      const firstStepIsFilled = Object.keys(firstStepValues).filter((value) => firstStepValues[value]).length === 2;
      const errorOnFirstStep = form.getFieldsError(['floor', 'apartment']).filter(({errors}) => errors.length).length > 0;

      if (ignoreFloorAppartment) {
        return ( !selectedTimeslot?.uid || addressError );
      } else {
        return (
          !firstStepIsFilled ||
                errorOnFirstStep ||
                !selectedTimeslot?.uid ||
                addressError
        );
      }
    }


    return !clients?.length || loading || !selectedClient;
  }, [current, clients, loading, selectedClient, selectedTimeslot, addressError]);

  const onClientSelect = (client) => {
    setSelectedClient(client);
    setAddress(client?.user?.address);
    form.resetFields(['pets', 'concern', 'condition']);
    setPetList([]);
    setPetsToCreate([]);
  };

  const handleSignUp = useCallback(async (values) => {
    setSubmitting(true);
    try {
      const res = await signUp({
        variables: {
          record: values,
        },
      });

      if (res?.data?.signUpFlow) {
        message.success(messages.t('recommender.new_appointment.success_message'));
        onRequestClose();
        setCurrent(0);
      }
    } catch (err) {
      message.success(messages.t('recommender.new_appointment.error_message'));
    }
    setSubmitting(false);
  }, []);

  const onNextClick = useCallback(() => {
    if (!isNextButtonDisabled) {
      if (current !== (stepsCount - 1)) {
        setCurrent(++current);
      } else {
        form.submit();
      }
    }
  }, [isNextButtonDisabled, current]);

  const onClientEdit = useCallback((e, client) => {
    e.stopPropagation();
    setClientToEdit(client);
  }, []);

  return {
    petsToCreate, setPetsToCreate,
    search,
    setSearch,
    clients,
    refetchClients: fetchPetOwners,
    loading,
    selectedClient,
    setSelectedClient,
    clientToEdit,
    setClientToEdit,
    petList,
    setPetList,
    onClientSelect,
    onClientEdit,
    isNextButtonDisabled,
    onNextClick,
    form,
    total,
    handleSignUp,
    isSubmitting,
    fetchPetOwners,
  };
};

export default useNewAppointmentModal;
