import React from 'react';
import StartOnDate from './OnDate';

import translateLabel from '../../utils/translateLabel';

const Start = ({
  id,
  disabled,
  start: { onDate },
  handleChange,
  translations,
}) => {
  return (
    <div>
      <div className="form-group row">
        <div className="col-sm-2 text-sm-right">
          <label htmlFor={id} className="col-form-label">
            <strong>{translateLabel(translations, 'start.label')}</strong>
          </label>
        </div>
        <StartOnDate
          id={id}
          onDate={onDate}
          handleChange={handleChange}
          translations={translations}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default Start;
