import React from 'react';
import ClientCard from './ClientCard';
import ClientsEmptyState from './ClientsEmptyState';
import LoaderWrapper from 'components/ui/LoaderWrapper/LoaderWrapper.js';
import InfiniteScroll from 'react-infinite-scroll-component';
import {List, Skeleton} from 'antd';

const ModalContent = ({total, clients, onClientSelect, selectedClient, loading, onClientEdit, fetchPetOwners}) => {
  if (!clients.length && !loading) return <ClientsEmptyState/>;

  const loadMoreData = () => {
    if (loading) {
      return;
    }
    fetchPetOwners();
  };

  return (
    <div className={`appointments-content-wrapper ${loading ? 'd-flex' : ''}`}>
      <LoaderWrapper isLoading={loading} component="create-appointment">
        <div
          id="scrollableDiv"
          style={{
            height: 400,
            overflow: 'auto',
          }}
        >
          <InfiniteScroll
            dataLength={clients.length}
            next={loadMoreData}
            hasMore={clients.length < total}
            loader={
              <Skeleton
                avatar
                paragraph={{
                  rows: 1,
                }}
                active
              />
            }
            scrollableTarget="scrollableDiv"
          >
            <List
              dataSource={clients}
              renderItem={(item) =>
                <ClientCard
                  key={item?.uid}
                  item={item}
                  onClientSelect={onClientSelect}
                  selectedClient={selectedClient}
                  onClientEdit={onClientEdit}
                />}
            />
          </InfiniteScroll>
        </div>
      </LoaderWrapper>
    </div>
  );
};

export default ModalContent;
