import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { EyeTwoTone, EditTwoTone, CopyTwoTone, CopyFilled } from '@ant-design/icons';
import { Popover } from 'antd';

import { SubscriptionStatus } from './subscriptionStatus';
import { EDIT_PET_OWNER_EXACT, USERS } from 'constants/routeNames';
import { dashboardLink } from 'service/utils';

import './customers-styles.css';
import useActAsUser from './useActAsUser';
import RoleBasedCondition from '../role-based-ui/RoleBasedCondition.js';
import { Permissions } from '../../constants/permissions.js';
import RoleBasedUi from 'components/role-based-ui/RoleBasedUi';

export const useColumns = (sortBy) => {
  const history = useHistory();
  const [copiedId, setCopiedId] = useState(null);
  const { handleActAsUser } = useActAsUser();

  const handlePetStatus = (subscription) => {
    if (subscription?.subscriptionType?.name) {
      // if subscription is monthly return subscription status
      if (subscription?.subscriptionType?.name === 'monthly') {
        return subscription?.status;
      } else {
        // if pet has active one-time return ACTIVE status
        // if not return empty
        return subscription?.status === SubscriptionStatus.ACTIVE ? subscription?.status : '';
      }
    }
  };

  const editPetOwnerHandler = (uid) => {
    history.push(dashboardLink(USERS + EDIT_PET_OWNER_EXACT + uid));
  };

  const actAsHandler = (userId) => {
    handleActAsUser(userId);
  };

  const defaultSorter =
    sortBy['registrationDate'] === 'desc'
      ? 'descend'
      : sortBy['registrationDate'] === 'asc'
        ? 'ascend'
        : null;

  return [
    {
      title: 'User ID',
      dataIndex: 'userId',
      key: 'userId',
      visible: RoleBasedCondition(Permissions.VIEW_USER_ID),
      render: (text, record) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            navigator.clipboard.writeText(record.userId);
            setCopiedId(record.userId);
          }}
        >
          {copiedId === record.userId ? <CopyTwoTone /> : <CopyFilled />}
        </div>
      )
    },
    {
      title: 'Registration Date',
      dataIndex: 'registrationDate',
      key: 'registrationDate',
      sorter: {
        compare: (a, b) => a.country - b.country,
        multiple: 1
      },
      defaultSortOrder: defaultSorter
    },
    {
      title: 'Full Name',
      dataIndex: 'fullName',
      key: 'fullName',
      sorter: {
        compare: (a, b) => a.fullName - b.fullName,
        multiple: 2
      }
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      sorter: {
        compare: (a, b) => a.country - b.country,
        multiple: 1
      }
    },
    {
      title: 'Contacts',
      dataIndex: 'contacts',
      align: 'center',
      key: 'contacts',
      render: (text, record) => (
        <Popover
          content={
            <div className="popover">
              <div className="single-popover">
                <div className="popover-address">Phone:</div>
                {record.phone}
              </div>
              <div className="single-popover">
                <div className="popover-address">Email:</div>
                {record.email}
              </div>
              <div className="single-popover">
                <div className="popover-address">City:</div> {record.city}
              </div>
              <div className="single-popover">
                <div className="popover-address">Street:</div>
                {record.street}
              </div>
            </div>
          }
        >
          <div style={{ cursor: 'pointer' }}>@</div>
        </Popover>
      )
    },
    // {
    //   title: 'Card Expiration',
    //   dataIndex: 'cardExpiration',
    //   key: 'cardExpiration',
    //   sorter: {
    //     compare: (a, b) => a.cardExpiration - b.cardExpiration,
    //     multiple: 1
    //   }
    // },
    // {
    //   title: 'Subscription Date',
    //   dataIndex: 'subscriptionDate',
    //   key: 'subscriptionDate',
    //   sorter: {
    //     compare: (a, b) => a.subscriptionDate - b.subscriptionDate,
    //     multiple: 1
    //   }
    // },
    // {
    //   title: 'Month of subscription',
    //   dataIndex: 'subscriptionDuration',
    //   key: 'subscriptionDuration'
    // },
    // {
    //   title: 'Spending Amount',
    //   dataIndex: 'spendingAmount',
    //   align: 'center',
    //   key: 'spendingAmount'
    // },
    {
      title: 'Number of Pets',
      dataIndex: 'petAmount',
      align: 'center',
      key: 'petAmount',
      render: (text, record) => (
        <Popover
          content={
            <div className="popover">
              {!!record?.pets?.length &&
                record?.pets.map((pet) => {
                  return (
                    <div className="single-popover">
                      <div className="popover-address">Name:</div>
                      {pet.name}
                      {pet?.activeSubscription && (
                        <>
                          &nbsp;&nbsp;&nbsp;
                          <div className="popover-address">Status:</div>
                          {handlePetStatus(pet?.activeSubscription)}
                        </>
                      )}
                    </div>
                  );
                })}
            </div>
          }
        >
          <div style={{ cursor: 'pointer' }}>{record.petAmount}</div>
        </Popover>
      )
    },
    // {
    //   title: 'Status',
    //   dataIndex: 'status',
    //   key: 'status',
    //   align: 'center'
    // },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      render: (text, record) => (
        <div className="action-buttons-wrapper">
          <div className="action-button">
            <EyeTwoTone
              onClick={() => {
                actAsHandler(record.userId);
              }}
            />
          </div>
          <RoleBasedUi permission={Permissions.EDIT_CUSTOMER}>
            <div>
              <EditTwoTone
                className="action-button"
                onClick={() => {
                  editPetOwnerHandler(record.uid);
                }}
              />
            </div>
          </RoleBasedUi>
          {/* <div style={{ cursor: 'pointer' }}>
            <DeleteTwoTone />
          </div> */}
        </div>
      )
    }
  ].filter((item) => item?.visible !== false);
};

export default useColumns;
