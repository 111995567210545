import {useState, useEffect} from 'react';
import {useQuery} from '@apollo/client';
import {getVetsQuery, getCalendarEventsQuery} from 'gql';
import moment from 'moment';

const useSchedule = () => {
  const [search, setSearch] = useState('');
  const {data: vets, loading: loadingVets} = useQuery(getVetsQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      search: '',
    },
  });

  const [eventsStartDate, setEventsStartDate] = useState(() => {
    // begging of current month
    const now = new Date();
    return moment(new Date(now.getFullYear(), now.getMonth() - 1, 1));
  });
  const [eventsEndDate, setEventsEndDate] = useState(() => {
    // end of current month
    const now = new Date();
    return moment(new Date(now.getFullYear(), now.getMonth() + 2, 0));
  });
  const [eventCache, setEventCache] = useState({});
  const [events, setEvents] = useState({});

  // FIXME: getCalendar is sent on every render (and when selecting user for new appointment)
  const {loading: loadingEvents, refetch: regetchEvents} = useQuery(getCalendarEventsQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      record: {
        startDate: eventsStartDate,
        endDate: eventsEndDate,
      },
    },
    onSuccess: (data) => {
      setEvents(data?.data);
    },
  });

  useEffect(() => {
    const range = `${eventsStartDate?.format('YYYY-MM-DD')},${eventsEndDate?.format('YYYY-MM-DD')}`;

    // Check if events for this date range are already available in the cache
    if (eventCache[range]) {
      setEvents(eventCache[range]);
      return;
    }

    // Call getCalendarEventsQuery with updated startDate and endDate
    regetchEvents().then( (data) => {
      setEventCache({
        ...eventCache,
        [range]: data?.data, // Cache the fetched events for future use
      });
      setEvents(data?.data);
    });
  }, [eventsEndDate]);

  return {
    events: events?.getCalendarEvents,
    vets: vets?.getVets,
    loading: (loadingVets || loadingEvents),
    setEventsStartDate,
    setEventsEndDate,
    eventsStartDate,
    eventsEndDate,
    setEventCache,
  };
};

export default useSchedule;
